const TrailerDalaBodyCategories = [
  { value: "22MT", label: "22MT" },
  { value: "25MT", label: "25MT" },
  { value: "26MT", label: "26MT" },
  { value: "27MT", label: "27MT" },
  { value: "28MT", label: "28MT" },
  { value: "29MT", label: "29MT" },
  { value: "30MT", label: "30MT" },
  { value: "31MT", label: "31MT" },
  { value: "32MT", label: "32MT" },
  { value: "33MT", label: "33MT" },
  { value: "34MT", label: "34MT" },
  { value: "35MT", label: "35MT" },
  { value: "36MT", label: "36MT" },
  { value: "37MT", label: "37MT" },
  { value: "38MT", label: "38MT" },
  { value: "39MT", label: "39MT" },
  { value: "40MT", label: "40MT" },
  { value: "41MT", label: "41MT" },
  { value: "42MT", label: "42MT" },
  { value: "43MT", label: "43MT" },
];

const TrailerFlatBedCategories = [
  { value: "16MT 19Ft", label: "16MT 19Ft" },
  { value: "16MT 20Ft", label: "16MT 20Ft" },
  { value: "17MT 19Ft", label: "17MT 19Ft" },
  { value: "17MT 20Ft", label: "17MT 20Ft" },
  { value: "18MT 19Ft", label: "18MT 19Ft" },
  { value: "18MT 20Ft", label: "18MT 20Ft" },
  { value: "19Mt 19Ft", label: "19Mt 19Ft" },
  { value: "19Mt 20Ft", label: "19Mt 20Ft" },
  { value: "21Mt 19Ft", label: "21Mt 19Ft" },
  { value: "21Mt 20Ft", label: "21Mt 20Ft" },
  { value: "22Mt 20Ft", label: "22Mt 20Ft" },
  { value: "23Mt 20Ft", label: "23Mt 20Ft" },
  { value: "24Mt 20Ft", label: "24Mt 20Ft" },
  { value: "24Mt 40Ft", label: "24Mt 40Ft" },
  { value: "25Mt 20Ft", label: "25Mt 20Ft" },
  { value: "25Mt 34Ft", label: "25Mt 34Ft" },
  { value: "25Mt 40Ft", label: "25Mt 40Ft" },
  { value: "26Mt 40Ft", label: "26Mt 40Ft" },
  { value: "27Mt 21Ft", label: "27Mt 21Ft" },
  { value: "27Mt 40Ft", label: "27Mt 40Ft" },
  { value: "28Mt 35Ft", label: "28Mt 35Ft" },
  { value: "28Mt 40Ft", label: "28Mt 40Ft" },
  { value: "29MT 20Ft", label: "29MT 20Ft" },
  { value: "29MT 40Ft", label: "29MT 40Ft" },
  { value: "30MT 30Ft", label: "30MT 30Ft" },
  { value: "30MT 35FT", label: "30MT 35FT" },
  { value: "30MT 38Ft", label: "30MT 38Ft" },
  { value: "30MT 40Ft", label: "30MT 40Ft" },
  { value: "31MT 40Ft", label: "31MT 40Ft" },
  { value: "31MT 42Ft", label: "31MT 42Ft" },
  { value: "32MT 40Ft", label: "32MT 40Ft" },
  { value: "33MT 35FT", label: "33MT 35FT" },
  { value: "33MT 38Ft", label: "33MT 38Ft" },
  { value: "33MT 40Ft", label: "33MT 40Ft" },
  { value: "33MT 41Ft", label: "33MT 41Ft" },
  { value: "34MT 20Ft", label: "34MT 20Ft" },
  { value: "34MT 35Ft", label: "34MT 35Ft" },
  { value: "34MT 38Ft", label: "34MT 38Ft" },
  { value: "34MT 40Ft", label: "34MT 40Ft" },
  { value: "34MT 42Ft", label: "34MT 42Ft" },
  { value: "35MT 35Ft", label: "35MT 35Ft" },
  { value: "35MT 36Ft", label: "35MT 36Ft" },
  { value: "35MT 38Ft", label: "35MT 38Ft" },
  { value: "35MT 39Ft", label: "35MT 39Ft" },
  { value: "35MT 40Ft", label: "35MT 40Ft" },
  { value: "35MT 41Ft", label: "35MT 41Ft" },
  { value: "35MT 44Ft", label: "35MT 44Ft" },
  { value: "40MT 40Ft", label: "40MT 40Ft" },
  { value: "40MT 41Ft", label: "40MT 41Ft" },
  { value: "40MT 42Ft", label: "40MT 42Ft" },
  { value: "40MT 43Ft", label: "40MT 43Ft" },
  { value: "41MT 40Ft", label: "41MT 40Ft" },
  { value: "42MT 38Ft", label: "42MT 38Ft" },
  { value: "42MT 40Ft", label: "42MT 40Ft" },
  { value: "42MT 42Ft", label: "42MT 42Ft" },
  { value: "42MT 42Ft", label: "42MT 42Ft" },
  { value: "43MT 32Ft", label: "43MT 32Ft" },
  { value: "43MT 38Ft", label: "43MT 38Ft" },
  { value: "43MT 40Ft", label: "43MT 40Ft" },
  { value: "43MT 42Ft", label: "43MT 42Ft" },
  { value: "43MT 43Ft", label: "43MT 43Ft" },
];

const OpenBodyTruckCategories = [
  { value: "7.5MT 6 Tyers", label: "7.5MT 6 Tyers" },
  { value: "8Mt 6 Tyres", label: "8Mt 6 Tyres" },
  { value: "9MT 6 Tyres", label: "9MT 6 Tyres" },
  { value: "10Mt 6 Tyres", label: "10Mt 6 Tyres" },
  { value: "15MT 10 Tyres", label: "15MT 10 Tyres" },
  { value: "16Mt 10tyres", label: "16Mt 10tyres" },
  { value: "18Mt 10 Tyres", label: "18Mt 10 Tyres" },
  { value: "18.5Mt 10 Tyres", label: "18.5Mt 10 Tyres" },
  { value: "19Mt 6 Tyres", label: "19Mt 6 Tyres" },
  { value: "19Mt 10 Tyres", label: "19Mt 10 Tyres" },
  { value: "20MT 6 Tyres", label: "20MT 6 Tyres" },
  { value: "20MT 10 Tyre", label: "20MT 10 Tyre" },
  { value: "21MT 10 Tyre ", label: "21MT 10 Tyre " },
  { value: "21MT 12 Tyre", label: "21MT 12 Tyre" },
  { value: "22MT 10 Tyre", label: "22MT 10 Tyre" },
  { value: "22MT 12 Tyre", label: "22MT 12 Tyre" },
  { value: "24Mt 12 Tyre", label: "24Mt 12 Tyre" },
  { value: "25MT 10 Tyre", label: "25MT 10 Tyre" },
  { value: "25Mt 12 Tyre", label: "25Mt 12 Tyre" },
  { value: "26Mt 12Tyre", label: "26Mt 12Tyre" },
  { value: "29Mt 14Tyre", label: "29Mt 14Tyre" },
  { value: "30MT 14 Tyre", label: "30MT 14 Tyre" },
  { value: "33MT 12 Tyre", label: "33MT 12 Tyre" },
  { value: "35MT 14 Tyre", label: "35MT 14 Tyre" },
  { value: "35MT 16 Tyre", label: "35MT 16 Tyre" },
  { value: "35MT 18 Tyre", label: "35MT 18 Tyre" },
  { value: "36MT 16 Tyre", label: "36MT 16 Tyre" },
  { value: "38MT 18 Tyre", label: "38MT 18 Tyre" },
  { value: "43MT 14 Tyre", label: "43MT 14 Tyre" },
];

const ContainerCategories = [
  { value: "7.5MT 14Ft", label: "7.5MT 14Ft" },
  { value: "7.5MT 17Ft", label: "7.5MT 17Ft" },
  { value: "7.5MT 19Ft", label: "7.5MT 19Ft" },
  { value: "7.5MT 20Ft", label: "7.5MT 20Ft" },
  { value: "7.5MT 22Ft", label: "7.5MT 22Ft" },
  { value: "7.5MT 24Ft", label: "7.5MT 24Ft" },
  { value: "7.5MT 32Ft", label: "7.5MT 32Ft" },
  { value: "9MT 14Ft", label: "9MT 14Ft" },
  { value: "9MT 17Ft", label: "9MT 17Ft" },
  { value: "9MT 19Ft", label: "9MT 19Ft" },
  { value: "9MT 20Ft", label: "9MT 20Ft" },
  { value: "9MT 22Ft", label: "9MT 22Ft" },
  { value: "9MT 24FT", label: "9MT 24FT" },
  { value: "9MT 30Ft", label: "9MT 30Ft" },
  { value: "9MT 32FT", label: "9MT 32FT" },
  { value: "9MT 34Ft", label: "9MT 34Ft" },
  { value: "14.5MT 32Ft", label: "14.5MT 32Ft" },
  { value: "15MT 30Ft", label: "15MT 30Ft" },
  { value: "15MT 32Ft", label: "15MT 32Ft" },
  { value: "18MT 30Ft", label: "18MT 30Ft" },
  { value: "18MT 32Ft", label: "18MT 32Ft" },
  { value: "19MT 30Ft", label: "19MT 30Ft" },
  { value: "19MT 32Ft", label: "19MT 32Ft" },
  { value: "20MT 30Ft", label: "20MT 30Ft" },
  { value: "20MT 32Ft", label: "20MT 32Ft" },
  { value: "20MT 40Ft", label: "20MT 40Ft" },
  { value: "24MT 30Ft", label: "24MT 30Ft" },
  { value: "24MT 32FT", label: "24MT 32FT" },
  { value: "25MT 25Ft", label: "25MT 25Ft" },
  { value: "25MT 30FT", label: "25MT 30FT" },
  { value: "25MT 31FT", label: "25MT 31FT" },
  { value: "25MT 32FT", label: "25MT 32FT" },
  { value: "25MT 40FT", label: "25MT 40FT" },
  { value: "30MT 30FT", label: "30MT 30FT" },
  { value: "30MT 32FT", label: "30MT 32FT" },
  { value: "30MT 40FT", label: "30MT 40FT" },
];

const TankerCategories = [
  { value: "8MT 6 Tyre", label: "8MT 6 Tyre" },
  { value: "10MT 6 Tyre", label: "10MT 6 Tyre" },
  { value: "16Mt 10 Tyre", label: "16Mt 10 Tyre" },
  { value: "18Mt 10 Tyre", label: "18Mt 10 Tyre" },
  { value: "19Mt 10 Tyre", label: "19Mt 10 Tyre" },
  { value: "21MT 12 Tyre", label: "21MT 12 Tyre" },
  { value: "24Mt 12 Tyre", label: "24Mt 12 Tyre" },
  { value: "25MT 12 Tyre", label: "25MT 12 Tyre" },
  { value: "26Mt 14 Tyre", label: "26Mt 14 Tyre" },
  { value: "29Mt 14 Tyre", label: "29Mt 14 Tyre" },
  { value: "30Mt 14 Tyre", label: "30Mt 14 Tyre" },
  { value: "35Mt 16 Tyre", label: "35Mt 16 Tyre" },
  { value: "36MT 16 Tyre", label: "36MT 16 Tyre" },
];

const TipperCategories = [
  { value: "9Mt 6 Tyre", label: "9Mt 6 Tyre" },
  { value: "15Mt 10 Tyre", label: "15Mt 10 Tyre" },
  { value: "16Mt 10 Tyre", label: "16Mt 10 Tyre" },
  { value: "18 Mt 10 Tyre", label: "18 Mt 10 Tyre" },
  { value: "19Mt 10 Tyre", label: "19Mt 10 Tyre" },
  { value: "25Mt 12 tyre", label: "25Mt 12 tyre" },
  { value: "29Mt 14Tyre", label: "29Mt 14Tyre" },
  { value: "30MT 14 Tyre", label: "30MT 14 Tyre" },
];

const DumperCategories = [
  { value: "9MT - 11MT", label: "9MT - 11MT" },
  { value: "12MT - 15MT", label: "12MT - 15MT" },
  { value: "16MT - 19MT", label: "16MT - 19MT" },
  { value: "20MT - 22MT", label: "20MT - 22MT" },
  { value: "23MT - 25 MT", label: "23MT - 25 MT" },
  { value: "26MT - 28MT", label: "26MT - 28MT" },
  { value: "29MT - 30MT", label: "29MT - 30MT" },
  { value: "31MT +", label: "31MT +" },
];

const BulkerCategories = [
  { value: "21MT 12 Tyre", label: "21MT 12 Tyre" },
  { value: "24Mt 12 Tyre", label: "24Mt 12 Tyre" },
  { value: "25MT 12 Tyre", label: "25MT 12 Tyre" },
  { value: "29MT 14 Tyre", label: "29MT 14 Tyre" },
  { value: "30 Mt 14 Tyre", label: "30 Mt 14 Tyre" },
];

const LVCOpenCategories = [
  { value: "2.5MT 4 Tyre", label: "2.5MT 4 Tyre" },
  { value: "3Mt 4 Tyre", label: "3Mt 4 Tyre" },
  { value: "3.5Mt 4 tyre", label: "3.5Mt 4 tyre" },
  { value: "4MT 4 Tyre", label: "4MT 4 Tyre" },
  { value: "4.5MT 4 Tyre", label: "4.5MT 4 Tyre" },
  { value: "5Mt 4 Tyre", label: "5Mt 4 Tyre" },
  { value: "5.5Mt 4 Tyre", label: "5.5Mt 4 Tyre" },
  { value: "6MT 4 Tyre", label: "6MT 4 Tyre" },
  { value: "6MT 6 Tyre", label: "6MT 6 Tyre" },
  { value: "7MT 4 Tyre", label: "7MT 4 Tyre" },
  { value: "7MT 6 Tyre", label: "7MT 6 Tyre" },
];

const LVCContainerCategories = [
  { value: "6MT 14Ft", label: "6MT 14Ft" },
  { value: "6MT 15Ft", label: "6MT 15Ft" },
  { value: "6MT 16Ft", label: "6MT 16Ft" },
  { value: "6MT 17Ft", label: "6MT 17Ft" },
  { value: "6MT 19Ft", label: "6MT 19Ft" },
  { value: "6MT 20Ft", label: "6MT 20Ft" },
  { value: "6MT 21Ft", label: "6MT 21Ft" },
  { value: "6MT 22Ft", label: "6MT 22Ft" },
  { value: "6MT 23Ft", label: "6MT 23Ft" },
  { value: "6MT 24FT", label: "6MT 24FT" },
  { value: "6MT 25Ft", label: "6MT 25Ft" },
  { value: "6MT 26Ft", label: "6MT 26Ft" },
  { value: "6MT 27Ft", label: "6MT 27Ft" },
  { value: "6MT 28Ft", label: "6MT 28Ft" },
  { value: "6MT 30Ft", label: "6MT 30Ft" },
  { value: "6MT 31Ft", label: "6MT 31Ft" },
  { value: "6MT 32Ft", label: "6MT 32Ft" },
  { value: "6MT 34Ft", label: "6MT 34Ft" },
  { value: "7MT 14Ft", label: "7MT 14Ft" },
  { value: "7MT 15Ft", label: "7MT 15Ft" },
  { value: "7MT 16Ft", label: "7MT 16Ft" },
  { value: "7MT 17Ft", label: "7MT 17Ft" },
  { value: "7MT 20Ft", label: "7MT 20Ft" },
  { value: "7MT 21Ft", label: "7MT 21Ft" },
  { value: "7MT 22Ft", label: "7MT 22Ft" },
  { value: "7MT 23Ft", label: "7MT 23Ft" },
  { value: "7MT 24FT", label: "7MT 24FT" },
  { value: "7MT 25Ft", label: "7MT 25Ft" },
  { value: "7MT 26Ft", label: "7MT 26Ft" },
  { value: "7MT 27Ft", label: "7MT 27Ft" },
  { value: "7MT 28Ft", label: "7MT 28Ft" },
  { value: "7MT 30Ft", label: "7MT 30Ft" },
  { value: "7MT 32Ft", label: "7MT 32Ft" },
  { value: "7MT 34Ft", label: "7MT 34Ft" },
];

const MiniPickupCategories = [
  { value: "Tata Ace (0.75 MT)", label: "Tata Ace (0.75 MT)" },
  { value: "Tata Ace (1 MT)", label: "Tata Ace (1 MT)" },
  { value: "Pickup Truck Dost (1.5 MT)", label: "Pickup Truck Dost (1.5 MT)" },
  { value: "Pickup Truck Dost (2 MT)", label: "Pickup Truck Dost (2 MT)" },
];

export {
  TrailerDalaBodyCategories,
  TrailerFlatBedCategories,
  OpenBodyTruckCategories,
  ContainerCategories,
  TankerCategories,
  TipperCategories,
  DumperCategories,
  BulkerCategories,
  LVCOpenCategories,
  LVCContainerCategories,
  MiniPickupCategories,
};
