import "./Services.scss"

const Services = () => {
    return (
        <>
            <div className="Servises_MainDiv">
                <p className="ChooseUs_Head">WHY CHOOSE US</p>
                <p className="Experience_Head">Experience the best service with us</p>



                <div className="Servises_Subdiv">


                    <div className="Experience_Card_Div">

                        <div className="Card_div">
                            <img src="./search.png" alt="" />
                            <div>
                                <p>Complete Transparency</p>
                                <p>We provide a detailed quote at the very beginning of the trip so that all the terms and conditions are very clear and there is no point remaining for any dispute in the later stage.</p>
                            </div>
                        </div>

                        <div className="Card_div">
                            <img src="./Safe-Secure-payment.png" alt="" />
                            <div>
                                <p>Safe and Secure Payment</p>
                                <p>Your payment is 100% safe as we are a Professional and Registered Organization, Even If there is any excess payment done to EMT, you can get a refund within 2 hours.</p>
                            </div>
                        </div>

                        <div className="Card_div">
                            <img src="./Best_Rates.png" alt="" />
                            <div>
                                <p>Best Rate Guarantee</p>
                                <p>All the vehicles are directly sourced from Fleet Owners and therefore no middleman is involved. Hence we guarantee Best Rates.</p>
                            </div>
                        </div>
                    </div>

                    <div className="Experience_Card_Div">

                        <div>
                            <img width="100%" src="./india-truck-2.jpg" alt="" />
                        </div>

                        <div className="Card_div">
                            <img src="./Best_Rates.png" alt="" />
                            <div>
                                <p>E-POD</p>
                                <p>Immediately upon delivery, you can download the E-Pod from our website.</p>
                            </div>
                        </div>
                    </div>

                    <div className="Experience_Card_Div">
                        <div className="Card_div">
                            <img src="./End-to-end-Tracking.jpg" alt="" />
                            <div>
                                <p>Start-to-End Tracking</p>
                                <p>All the vehicles are trackable. We have a Control Tower that monitors every trip from start to end and ensures the timely delivery. We share the location of the vehicles twice a day so that you don’t need to keep following up on the vehicle</p>
                            </div>
                        </div>

                        <div className="Card_div">
                            <img src="./Ease-of-Transport1.jpg" alt="" />
                            <div>
                                <p>Easy of Transport</p>
                                <p>You do not need to call 10 suppliers for your requirement, you just need to call EMT. We have thousands of Trucks in our pool to meet your requirement at all times at every location.</p>
                            </div>
                        </div>

                        <div className="Card_div">
                            <img src="./On-Time-Delivery.png" alt="" />
                            <div>
                                <p>Time Commitment Deliveries</p>
                                <p>We provide a complete timeline for the journey in advance and our control team ensures it is followed.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}


export default Services