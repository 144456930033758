import React, { useState, useEffect } from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import { useFormik } from "formik";
import FileUploadBox from "../../../LpComponents/FileUploadBox/FileUploadBox";
import { screenshotFileUpload } from "../../../../db/FileUploadConfig/FileUpload.config";
import InputBox from "../../InputBox/InputBox";
import { addToAccountModalData } from "../../../../db/ModalsData/ModalsData.config";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import Spinner from "../../Spinner/Spinner";
import "./AddBalanceModal.scss";
import { CREATE_RECEIPT } from "../../../../gql/createReceipt";

function AddBalanceModal({ onClose }) {
  const [isCreateReceiptInfoLoading, setIsCreateReceiptInfoLoading] = useState(false);
  const [createReceiptInfo, { loading: createReceiptInfoLoading }] = useMutation(CREATE_RECEIPT);

  useEffect(() => {
    setIsCreateReceiptInfoLoading(createReceiptInfoLoading);
  }, [createReceiptInfoLoading]);

  const updateFilesStore = (newFiles) => {
    const updatedFilesStore = { ...initialValues };
    Object.keys(newFiles).forEach((key) => {
      formik.setFieldValue(key, newFiles[key]);
      if (updatedFilesStore.hasOwnProperty(key)) {
        updatedFilesStore[key] = newFiles[key];
      }
    });
    initialValues = {
      ...updatedFilesStore,
    };
  };
  let initialValues = {
    Amount: 0,
    UTR_Number: "",
    Type_Of_Payment: "",
    Payment_ScreenShot: "",
  };

  const validate = (values) => {
    // console.log("validate", values);

    let errors = {};

    if (!values.Payment_ScreenShot) {
      errors.Payment_ScreenShot = "Please upload all files";
    } else if (!values.Amount) {
      errors.Amount = "Please fill Amount";
    } else if (values.Amount === "0") {
      errors.Amount = "Field can't be 0";
    } else if (!values.UTR_Number) {
      errors.UTR_Number = "Please provide UTR No";
    } else if (!values.Type_Of_Payment) {
      errors.Type_Of_Payment = "Please provide Payment Type";
    }

    return errors;
  };
  const onSubmit = async (values) => {
    // console.log("onSubmit", values);

    await createReceiptInfo({
      variables: {
        createInput: {
          Payment_ScreenShot: values.Payment_ScreenShot,
          Type_Of_Payment: values.Type_Of_Payment,
          Amount: values.Amount,
          UTR_Number: values.UTR_Number,
        },
      },
    })
      .then((res) => {
        toast.success("Payment Requested Successfully");
        onClose();
      })
      .catch((err) => toast.error(err.message));
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  const handleformikdata = (Value, id) => {
    let data = Value;

    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="addBalanceModalDiv">
        <div className="forScroll">
          <p className="modalMainHeading">Bank Details -</p>
          <div className="bankDetailsDiv">
            <div className="eachContentDiv">
              <p className="contentHeading">Bank Name</p>:<p className="contentText">IDFC Bank</p>
            </div>
            <div className="eachContentDiv">
              <p className="contentHeading">Account Name</p>:<p className="contentText">Ease My Transport Pvt Ltd</p>
            </div>
            <div className="eachContentDiv">
              <p className="contentHeading">Account Number</p>:<p className="contentText">10083381835</p>
            </div>
            <div className="eachContentDiv">
              <p className="contentHeading">IFSC Code</p>:<p className="contentText">IDFB0041379</p>
            </div>
            <div className="eachContentDiv">
              <p className="contentHeading">Bank Branch Name</p>:<p className="contentText">Hinjewadi, Pune.</p>
            </div>
          </div>
          <div className="orDiv">
            <p className="orText">OR</p>
          </div>
          <div className="bankDetailsDiv">
            <div className="eachContentDiv">
              <p className="contentHeading">Bank Name</p>:<p className="contentText">Kotak Mahindra Bank</p>
            </div>
            <div className="eachContentDiv">
              <p className="contentHeading">Account Name</p>:<p className="contentText">Ease My Transport Pvt Ltd</p>
            </div>
            <div className="eachContentDiv">
              <p className="contentHeading">Account Number</p>:<p className="contentText">0945308049</p>
            </div>
            <div className="eachContentDiv">
              <p className="contentHeading">IFSC Code</p>:<p className="contentText">KKBK0000721</p>
            </div>
            <div className="eachContentDiv">
              <p className="contentHeading">Bank Branch Name</p>:<p className="contentText">Timber Market, Pune.</p>
            </div>
          </div>

          {/* <hr></hr> */}

          <div className="guideDiv">
            <p className="guideText">
              Please make payment on either of any above bank details and upload payment receipt screenshot below
            </p>
          </div>

          <div className="fileUploadDiv">
            <p className="modalMainHeading">Document Upload -</p>
            <FileUploadBox
              UploadFields={screenshotFileUpload}
              format={true}
              sendFilesFun={(e) => updateFilesStore(e)}
              err={formik.errors.Payment_ScreenShot}
              pageName={"AddBalance"}
            />

            {addToAccountModalData?.map((el) => (
              <div key={el.name} className="eachContentDiv">
                <p className="labelHeading">{el.contentHeading}</p>:
                <InputBox
                  className={"cnfQtyInput no-spinners"}
                  type={el.type}
                  name={el.name}
                  Iconinside={true}
                  modalform={true}
                  dropdowndata={el.dropdowncontent}
                  handleInputChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  handleformikdata={handleformikdata}
                  autoComplete={el.autoComplete}
                  placeholder={el.placeholder}
                  err={formik.errors[el.name]}
                  touched={formik.touched[el.name] ? formik.touched[el.name] : false}
                  nolable={true}
                />
              </div>
            ))}
          </div>
        </div>
        <PortalFooter>
          {isCreateReceiptInfoLoading ? (
            <Button text={<Spinner />} className={"loadingAcceptBtn"} type="button" />
          ) : (
            <Button text="Submit" className={"acceptBtn"} type="Submit" />
          )}

          <Button text="Cancel" className={"rejectBtn"} handleClick={onClose} />
        </PortalFooter>
      </div>
    </form>
  );
}

export default AddBalanceModal;
