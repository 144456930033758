import { gql } from "@apollo/client";

export const EDIT_BID_DETAILS = gql`
mutation EditBidDetails($editBidDetailsId: ID!, $userInput: BidDetailsInput!) {
  editBidDetails(id: $editBidDetailsId, userInput: $userInput) {
    _id
    LoadId
    UserId
    Price
    Unique_Id
    No_Of_Vehicle
    Confirm_Qty
    Committed_Vehicles
    Bid_Note
  }
}
`;