import React from "react";
import "./Image.scss";

function Image({ className, src, alt, handleImageClick }) {
  return (
    <img className={className} src={src} alt={alt} onClick={handleImageClick} />
  );
}

export default Image;
