import React, { useEffect, useState } from "react";
import CompletedBox from "./CompletedComponents/CompletedBox/CompletedBox";
import { useQuery } from "@apollo/client";
import TruckLoadingIndicator from "../../../../Components/Common/TruckLoadingIndicator/TruckLoadingIndicator";
import ShowNoDataFound from "../../../../Components/Common/ShowNoDataFound/ShowNoDataFound";
import "./Completed.scss";
import { GET_ONGOING_BOOKINGS } from "../../../../gql/getOngoingDataLP";

function Completed() {
  const [acceptedCommittedVehiclesData, setAcceptedCommittedVehiclesData] = useState([]);
  const { loading, data } = useQuery(GET_ONGOING_BOOKINGS);

  useEffect(() => {
    if (data && data.CommittedVehiclesAcceptedForLoadPartnerUserId) {
      let getData = data.CommittedVehiclesAcceptedForLoadPartnerUserId;
      getData = getData.filter((el) => {
        return el.POD_Submitted_To_LP;
      });
      setAcceptedCommittedVehiclesData(getData);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <TruckLoadingIndicator />
      ) : acceptedCommittedVehiclesData.length === 0 ? (
        <ShowNoDataFound msg={"No Bookings Available"} />
      ) : (
        <div className="completedPage">
          {acceptedCommittedVehiclesData?.map((el) => (
            <CompletedBox key={el.bookingNo} data={el} />
          ))}
        </div>
      )}
    </>
  );
}
export default Completed;
