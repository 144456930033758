import React from "react";
import "./AcceptClaimCnfModal.scss";
import { NumberFormatter } from "../../../../../CommonFunction/NumberFormatter/NumberFormatter";
import PortalFooter from "../../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../../Button/Button";
import { useSelector } from "react-redux";
import Spinner from "../../../Spinner/Spinner";

function AcceptClaimCnfModal({
  editCommittedVehiclesData,
  onClose,
  deductingAmount,
  loading,
}) {
  let accBal = useSelector((store) => store.accBalance.accBalance);
  let remainingBal = accBal - deductingAmount;

  return (
    <div className="acceptClaimCnfModalDiv">
      <div className="eachAcceptClaimCnfDiv">
        <p className="acceptClaimCnfModalHeading">Current Bal :</p>
        <p className="acceptClaimModalCnfText">{NumberFormatter(accBal)}/-</p>
      </div>
      <div className="eachAcceptClaimCnfDiv">
        <p className="acceptClaimCnfModalHeading">Amount to be paid :</p>
        <p className="acceptClaimModalCnfText">
          {NumberFormatter(deductingAmount)} /-
        </p>
      </div>
      <div className="eachAcceptClaimCnfDiv">
        <p className="acceptClaimCnfModalHeading">Remaining Balance :</p>
        <p className="acceptClaimModalCnfText">
          {NumberFormatter(remainingBal)}/-
        </p>
      </div>

      {remainingBal < 0 && (
        <p className="insufficientBalText">Insufficient Balance</p>
      )}
      <p className="acceptClaimCnfModalMessage">
        Do you want to Accept this Claim ?
      </p>

      <PortalFooter>
        {loading ? (
          <Button
            text={<Spinner />}
            className="loadingAcceptBtn"
            type="button"
          />
        ) : (
          <Button
            text={"Confirm"}
            className={remainingBal < 0 ? "acceptBtndisabled" : "acceptBtn"}
            disabled={remainingBal < 0 ? true : false}
            type="submit"
            handleClick={editCommittedVehiclesData}
          />
        )}

        <Button text={"Cancel"} className="rejectBtn" handleClick={onClose} />
      </PortalFooter>
    </div>
  );
}

export default AcceptClaimCnfModal;
