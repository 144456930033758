import axios from "axios";
import SelectAccType from "../../Components/Common/Login/SelectAccType";
import { useState } from "react";
import LoginForm from "../../Components/Common/Login/LoginForm";
import "./LoginPage.scss";

axios.defaults.withCredentials = true;

function LoginPage() {
  const [loginType, setLoginType] = useState();
  const [Login, setLogin] = useState(false);

  return (
    <>
      {!Login ? (
        <SelectAccType loginType={loginType} setLoginType={setLoginType} setLogin={setLogin} />
      ) : (
        <LoginForm loginType={loginType} setLogin={setLogin} />
      )}
    </>
  );
}

export default LoginPage;
