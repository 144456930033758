import React, { useEffect } from "react";
import Card from "../../../../../../Components/Common/Card/Card";
import CardContent from "../../../../../../Components/LpComponents/CardComponents/CardContent/CardContent";
import CardFooter from "../../../../../../Components/LpComponents/CardComponents/CardFooter/CardFooter";
import CardHeader from "../../../../../../Components/LpComponents/CardComponents/CardHeader/CardHeader";
import FromAndTo from "../../../../../../Components/LpComponents/CardComponents/FromAndTo/FromAndTo";
import Id from "../../../../../../Components/LpComponents/CardComponents/Id/Id";
import VehicleNo from "../../../../../../Components/LpComponents/VehicleNoBox/VehicleNo";
import AmountBox from "../AmountBox/AmountBox";
import CurrentTask from "../CurrentTask/CurrentTask";
import DateDetailsBox from "../DateDetailsBox/DateDetails";
import DetailsBox from "../DetailsBox/DetailsBox";
import StatusBox from "../StatusBox/StatusBox";
import "./OngoingBox.scss";
import socket from "../../../../../../CommonFunction/Socket/socketClient";

function OngoingBox({ data }) {
  useEffect(() => {
    socket.emit("joinRoom", data._id);
  }, []);

  return (
    <Card>
      <CardHeader>
        <Id bookingNo={data.Unique_Id} bookingDate={data.createdAt} />
        <FromAndTo fromCity={data.LoadId.From_City} toCity={data.LoadId.To_City} />
        <VehicleNo vehicleNo={data.Vehicle_Number.Vehicle_No} />
      </CardHeader>

      <CardContent>
        <DetailsBox
          from={data.LoadId.From_City}
          to={data.LoadId.To_City}
          vehicleNo={data.Vehicle_Number}
          driverName={data.Driver_Name}
          driverNo={data.Driver_Number}
          material={data.LoadId.Material_Category}
          exactContent={data.LoadId.Exact_Content}
          totalWeight={data.LoadId.Total_Weight}
          loadId={data.LoadId.Unique_Id}
          LoadID={data.LoadId._id}
          Notes={data.LoadId.Notes}
          BidNote={data.BidId.Bid_Note}
        />
        <AmountBox
          freightAmount={data.Freight_Amount}
          paymentType={data.LoadId.Payment_Type}
          Rate_per={data.LoadId.Rate_per}
          Vehicle_Capacity={data.Vehicle_Capacity}
        />
        <StatusBox
          CommittedVehicleId={data._id}
          Current_Stage={data.Current_Stage}
          Booking_Confirmed={data.Booking_Confirmed}
          Vehicle_Reached_At_Loading_Point={data.Vehicle_Reached_At_Loading_Point}
          Loading_Completed={data.Loading_Completed}
          Advance_Processed={data.Advance_Processed}
          Vehicle_Reach_At_Unloading_Point={data.Vehicle_Reach_At_Unloading_Point}
          Vehicle_Front_Img={data.Vehicle_Front_Img}
          Vehicle_Back_Img={data.Vehicle_Back_Img}
          Vehicle_Right_Img={data.Vehicle_Right_Img}
          Vehicle_Left_Img={data.Vehicle_Left_Img}
          Material_Img={data.Material_Img}
          Entry_Gate_Img={data.Entry_Gate_Img}
          POD_Front_Copy={data.POD_Front_Copy}
          POD_Back_Copy={data.POD_Back_Copy}
          Invoice_Copy={data.Invoice_Copy}
          Final_Invoice_Copy={data.Final_Invoice_Copy}
          Unloading_Completed={data.Unloading_Completed}
          Clear_Delevery_Confirmation={data.Clear_Delevery_Confirmation}
          POD_Collected_From_SP={data.POD_Collected_From_SP}
          POD_Submitted_To_LP={data.POD_Submitted_To_LP}
        />
        <DateDetailsBox
          Booking_Confirmed_Time={data.Booking_Confirmed_Time}
          Vehicle_Reached_At_Loading_Point_Time={data.Vehicle_Reached_At_Loading_Point_Time}
          Loading_Completed_Time={data.Loading_Completed_Time}
          Advance_Processed_Time={data.Advance_Processed_Time}
          Vehicle_Reach_At_Unloading_Point_Time={data.Vehicle_Reach_At_Unloading_Point_Time}
          Unloading_Completed_Time={data.Unloading_Completed_Time}
          Clear_Delevery_Confirmation_Time={data.Clear_Delevery_Confirmation_Time}
          POD_Collected_From_SP_Time={data.POD_Collected_From_SP_Time}
          POD_Submitted_To_LP_Time={data.POD_Submitted_To_LP_Time}
        />
      </CardContent>
      <CardFooter>
        <div className="liveflexbox">
          <CurrentTask
            SpId={data.BidId.UserId}
            loadId={data.LoadId._id}
            CommittedVehicleId={data._id}
            CommittedVehicleUniqueId={data.Unique_Id}
            VehicleNo={data.Vehicle_Number.Vehicle_No}
            Current_Stage={data.Current_Stage}
            Sp_Claim_Raised={data.Sp_Claim_Raised}
            Sp_Claim_Cleared={data.Sp_Claim_Cleared}
            Lp_Claim_Raised={data.Lp_Claim_Raised}
            Lp_Claim_Cleared={data.Lp_Claim_Cleared}
            freightAmount={data.Freight_Amount}
            paymentType={data.LoadId.Payment_Type}
            BookingUniqueId={data.Unique_Id}
            Rate_per={data.LoadId.Rate_per}
            Vehicle_Capacity={data.Vehicle_Capacity}
          />
        </div>
      </CardFooter>
    </Card>
  );
}

export default OngoingBox;
