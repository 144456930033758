import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { formikValidationAddLoad, formikinitialvalues, replaceUnderscore } from "../../../../CommonFunction/common";
import CustomSelect from "../../../../Components/Common/CustomSelect/CustomSelect";
import InputBox from "../../../../Components/Common/InputBox/InputBox";
import { useSelector, useDispatch } from "react-redux";
import "./Addloadsection.scss";
import CustomInputMask from "../../../../Components/Common/CustomInputMask/CustomInputMask";
import CustomDatePicker from "../../../../Components/Common/CustomDatePicker/CustomDatePicker";
import CustomTimePicker from "../../../../Components/Common/CustomTimePicker/CustomTimePicker";
import { PINCODE_QUERY } from "../../../../gql/getPincode";

const { useQuery, gql } = require("@apollo/client");

const Addloadsection = ({ fields, fielddata, Type, text, TypeOfPortal }) => {
  const [fieldss, setfields] = useState(fields);
  const [alldata, setalldata] = useState({});
  const [pincode, setpincode] = useState(0);
  const [pincodedata, setpincodedata] = useState();
  const [odc, setOdc] = useState("No");
  const dispatch = useDispatch();
  const FormData = useSelector((store) => store.Addload);

  useEffect(() => {
    if (fielddata) {
      let arr = [];
      for (var a = 0; a < fieldss.length; a++) {
        arr.push({ [fieldss[a].name]: fielddata[fieldss[a].name] });
        formik.setFieldValue(fieldss[a].name, fielddata[fieldss[a].name]);
      }

      let obj = {};
      for (var l = 0; l < arr.length; l++) {
        let temp = arr[l];
        for (var key in temp) {
          obj[key] = temp[key];
        }
      }

      if (obj) {
        setTimeout(() => {
          setalldata(obj);
        }, 3000);
      }
    }
  }, [fielddata]);

  // console.log(alldata, "alldata--------------------");

  const handleformikdata = (Value, id) => {
    let data = Value;
    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);
  };

  const { data, error } = useQuery(PINCODE_QUERY, {
    variables: { pincode: +pincode },
    skip: String(pincode).length !== 6,
  });

  useEffect(() => {
    setpincodedata(data);
  }, [data]);

  useEffect(() => {
    setOdc(FormData.fielddata?.Material_Details && FormData.fielddata.Material_Details.Over_Dimension_Cargo);
  }, [FormData.fielddata]);

  const addvalues = (places) => {
    let obj = [];
    let p = places || [];
    let areas = [];
    for (var c = 0; c < p.length; c++) {
      areas = [...areas, { lable: p[c].Name, value: p[c].Name }];
    }
    for (var a = 0; a < fieldss.length; a++) {
      if (fieldss[a].setvalue === "Area") {
        obj = [
          ...obj,
          {
            ...fieldss[a],
            dropdowncontent: areas,
          },
        ];
      } else {
        obj = [...obj, fieldss[a]];
      }
      setfields(obj);
    }
  };

  useEffect(() => {
    // console.log(alldata);
    if (odc !== "Yes") {
      // Exclude Material_Dimension and Material_Dimension_In fields from alldata
      const { Material_Dimension, Material_Dimension_In, ...dataWithoutMaterialDimension } = alldata;
      dispatch({
        type: "addload",
        payload: { data: dataWithoutMaterialDimension, key: Type },
      });
    } else {
      dispatch({
        type: "addload",
        payload: { data: alldata, key: Type },
      });
    }
    validate(alldata);
  }, [alldata]);

  const validate = (values) => {
    // console.log(values);
    setalldata(values);
    // console.log(alldata);
    const errors = {};
    if (!values.Unloading_Pincode) {
      errors.Unloading_Pincode = "Required";
    } else if (!values.Loading_Pincode) {
      errors.Loading_Pincode = "Required";
    } else if (!/^\d{6}$/.test(values.Unloading_Pincode)) {
      errors.Unloading_Pincode = "Invalid pincode";
    }
  };

  const formik = useFormik({
    initialValues: formikinitialvalues(fieldss),
    validationSchema: formikValidationAddLoad(fieldss),
    validate,
    onSubmit: (values) => {
      // console.log(values);
    },
  });

  useEffect(() => {
    if (alldata.From_City) {
      formik.setFieldValue("From_City", alldata.From_City);
    }
    if (alldata.Material_Category) {
      formik.setFieldValue("Material_Category", alldata.Material_Category);
    }
    if (alldata.Vehicle_Type) {
      formik.setFieldValue("Vehicle_Type", alldata.Vehicle_Type);
    }
    if (alldata.Load_Live_Duration) {
      formik.setFieldValue("Load_Live_Duration", alldata.Load_Live_Duration);
    }
    if (alldata.Auction_Date) {
      formik.setFieldValue("Auction_Date", alldata.Auction_Date);
    }
  }, [alldata]);

  // console.log(formik.values,formik.errors);
  // console.log(formik.errors,formik.touched)
  useEffect(() => {
    if (formik.dirty && formik.isValid) {
      dispatch({
        type: "errordataedit",
        payload: { data: true, key: "error" },
      });
    } else {
      dispatch({
        type: "errordataedit",
        payload: { data: false, key: "error" },
      });
    }
  }, [formik.errors]);

  useEffect(() => {
    if (formik.errors) {
      dispatch({
        type: "errordata",
        payload: { data: formik.errors, key: Type },
      });
    }
  }, [formik.errors]);

  useEffect(() => {
    const fetchPincodeDetails = () => {
      if (pincodedata) {
        addvalues(pincodedata.pincode);
        const city = pincodedata.pincode[0].District;
        const state = pincodedata.pincode[0].State;
        formik.setFieldValue("Unloading_City", city);
        formik.setFieldValue("Unloading_State", state);
        let cityField = document.getElementById("Unloading_City");
        let stateField = document.getElementById("Unloading_State");
        cityField.value = city;
        stateField.value = state;
      }
    };
    setpincode(formik.values.Unloading_Pincode);
    if (/^\d{6}$/.test(formik.values.Unloading_Pincode)) {
      fetchPincodeDetails();
    }
  }, [formik.values.Unloading_Pincode, pincodedata, error]);

  useEffect(() => {
    const fetchPincodeDetails = async () => {
      if (pincodedata) {
        addvalues(pincodedata.pincode);

        const city = pincodedata.pincode[0].District;
        const state = pincodedata.pincode[0].State;
        formik.setFieldValue("Loading_City", city);
        formik.setFieldValue("Loading_State", state);

        let cityField = document.getElementById("Loading_City");
        let stateField = document.getElementById("Loading_State");
        cityField.value = city;
        stateField.value = state;
      }
    };
    setpincode(formik.values.Loading_Pincode);
    if (/^\d{6}$/.test(formik.values.Loading_Pincode)) {
      fetchPincodeDetails();
    }
  }, [formik.values.Loading_Pincode, pincodedata, error]);

  return (
    <div className="Addloadsection">
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <div className="headingaddloadsection">{replaceUnderscore(Type)}</div>
        <div className={Type === "Vehicle_Details" ? "contentAddload1" : "contentAddload"}>
          {fieldss?.map((e) => {
            if (odc !== "Yes") {
              if (e.name === "Material_Dimension") {
                return <></>;
              } else if (e.name === "Material_Dimension_In") {
                return <></>;
              }
            }
            return (
              <div key={e.id} className="selectionsBox">
                {e.customSelect === true ? (
                  <CustomSelect
                    label={e.label}
                    options={e.dropdowncontent}
                    placeholder={e.placeholder}
                    isSearchable={e.isSearchable}
                    isMulti={e.isMulti}
                    closeMenuOnSelect={e.closeMenuOnSelect}
                    value={formik.values[e.name]}
                    name={e.name}
                    id={e.name}
                    type={e.type}
                    require={e.require}
                    handleCustomSelectChange={formik.handleChange}
                    handleformikdata={handleformikdata}
                    handleBlur={formik.handleBlur}
                    err={formik.errors[e.name] ? formik.errors[e.name] : false}
                    touched={formik.touched[e.name] ? formik.touched[e.name] : false}
                  />
                ) : (
                  ""
                )}
                {!e.customSelect && !e.mask && e.type !== "date" && e.type !== "time" ? (
                  <InputBox
                    field={text}
                    TypeOfPortal={TypeOfPortal}
                    name={e.name}
                    id={e.name}
                    placeholder={e.name}
                    value={formik.values[e.name]}
                    label={e.label}
                    className={"inputwidthAddload no-spinners"}
                    type={e.type}
                    modalform={true}
                    Iconinside={true}
                    searchType={e.searchType}
                    handleInputChange={formik.handleChange}
                    handelformikdata={handleformikdata}
                    handleBlur={formik.handleBlur}
                    dropdowndata={e.dropdowncontent}
                    hasApiCallback={e.hasApiCallback}
                    step={e.step}
                    disabled={e.disabled}
                    readOnly={e.readOnly}
                    formtype={"addload"}
                    require={e.require}
                    err={formik.errors[e.name] ? formik.errors[e.name] : false}
                    touched={formik.touched[e.name] ? formik.touched[e.name] : false}
                    isSearchable={e.isSearchable}
                    autoCapital={e.autoCapital}
                    autoEveryCapital={e.autoEveryCapital}
                    maxNo={e.maxNo}
                  />
                ) : (
                  ""
                )}

                {e.type === "date" ? (
                  <CustomDatePicker
                    label={e.label}
                    name={e.name}
                    value={formik.values[e.name]}
                    handleBlur={formik.handleBlur}
                    handleCustomDatePickerChange={formik.handleChange}
                    handleformikdata={handleformikdata}
                    require={e.require}
                    err={formik.errors[e.name] ? formik.errors[e.name] : false}
                    touched={formik.touched[e.name] ? formik.touched[e.name] : false}
                    mindate={e.mindate}
                  />
                ) : (
                  ""
                )}
                {e.type === "time" ? (
                  <CustomTimePicker
                    label={e.label}
                    name={e.name}
                    value={formik.values[e.name]}
                    handleBlur={formik.handleBlur}
                    handleCustomTimePickerChange={formik.handleChange}
                    handleformikdata={handleformikdata}
                    require={e.require}
                    err={formik.errors[e.name] ? formik.errors[e.name] : false}
                    touched={formik.touched[e.name] ? formik.touched[e.name] : false}
                  />
                ) : (
                  ""
                )}
                {e.mask === true ? (
                  <CustomInputMask
                    label={e.label}
                    name={e.name}
                    value={formik.values[e.name]}
                    handleInputMaskChange={formik.handleChange}
                    handleformikdata={handleformikdata}
                    handleBlur={formik.handleBlur}
                    mask={"99 x *9 x*9"}
                    errors={formik.errors[e.name] ? formik.errors[e.name] : false}
                    touched={formik.touched[e.name] ? formik.touched[e.name] : false}
                  />
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </form>
    </div>
  );
};

export default Addloadsection;
