import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import Reactportal from "../../../../../../Components/SpComponents/Reactportal/Reactportal";
import Table from "../../../../../../Components/SpComponents/Table/Table";
import TBody from "../../../../../../Components/SpComponents/Table/TableComponents/TBody/TBody";
import TD from "../../../../../../Components/SpComponents/Table/TableComponents/TD/TD";
import TH from "../../../../../../Components/SpComponents/Table/TableComponents/TH/TH";
import THead from "../../../../../../Components/SpComponents/Table/TableComponents/THead/THead";
import TR from "../../../../../../Components/SpComponents/Table/TableComponents/TR/TR";
import AwardedAcceptedModal from "../../../../../../Components/Common/AllModals/AwardedAcceptedModal/AwardedAcceptedModal";
import Button from "../../../../../../Components/Common/Button/Button";
import CnfModal from "../../../../../../Components/Common/AllModals/CnfModal/CnfModal";
import FormattedTime from "../../../../../../Components/Common/FormattedTime/FormattedTime";
import socket from "../../../../../../CommonFunction/Socket/socketClient";
import Cookies from "js-cookie";
import { CREATE_VOUCHER_ENTRIES } from "../../../../../../CommonFunction/gql/createVoucherEntries";
import { EDIT_COMMITTED_VEHICLES_DATA } from "../../../../../../gql/editCommitedVehicle";
import { GET_ACCOUNT_DETAILS_BY_USERID } from "../../../../../../gql/getAccountDetailsByUserId";

function ResultTable({ awardedData, HandleRefetch, loadId, LPUserId }) {
  const spAccountBalanceID = Cookies.get("AccountBalanceId");
  const [awardedAcceptModal, setAwardedAcceptModal] = useState(false);
  const [cnfModal, setCnfModal] = useState(false);
  const [ID, setID] = useState("");
  const [lpAccountBalanceId, setLpAccountBalanceId] = useState("");

  const [isEditCommittedVehiclesLoading, setIsEditCommittedVehiclesLoading] = useState(false);
  const [createVoucherEntries, { loading: createVoucherEntriesLoading }] = useMutation(CREATE_VOUCHER_ENTRIES);

  const [editCommittedVehicles, { loading: editCommittedVehiclesLoading }] = useMutation(EDIT_COMMITTED_VEHICLES_DATA);

  const { data: accDetailsData } = useQuery(GET_ACCOUNT_DETAILS_BY_USERID, {
    variables: {
      userId: LPUserId,
    },
  });

  useEffect(() => {
    if (accDetailsData && accDetailsData.GetAccountBalanceIdWithUserId[0]) {
      setLpAccountBalanceId(accDetailsData.GetAccountBalanceIdWithUserId[0].id);
    }
  }, [accDetailsData]);

  useEffect(() => {
    setIsEditCommittedVehiclesLoading(editCommittedVehiclesLoading || createVoucherEntriesLoading);
  }, [editCommittedVehiclesLoading, createVoucherEntriesLoading]);

  async function rejectAwardedVehicle(id) {
    await editCommittedVehicles({
      variables: {
        editCommittedVehiclesId: id,
        userInput: {
          Is_Rejected: true,
          Is_Accepted: false,
        },
      },
    })
      .then(async (res) => {
        let transactions = [
          {
            Transaction_Type: "Credit",
            Account_ID: spAccountBalanceID,
            Amount: 2000,
          },
          {
            Transaction_Type: "Debit",
            Account_ID: lpAccountBalanceId,
            Amount: 2000,
          },
        ];

        await createVoucherEntries({
          variables: {
            createInput: {
              Entry_Type: "Journal Voucher",
              Voucher_Type: "Journal Transaction",
              Narration: `Vehicle Rejection Charges (${res.data.editCommittedVehicles.Vehicle_Number.Vehicle_No})`,
              Date: new Date(),
              Doc_No: res.data.editCommittedVehicles.Unique_Id,
            },
            transactions: transactions,
          },
        }).then(() => {
          toast.success("Awarded Vehicle Rejected");
          socket.emit("EditConfirmVechileswithId", loadId);
          setCnfModal(false);
          HandleRefetch();
        });
      })
      .catch((err) => toast.error(err.message));
  }

  if (awardedData.length === 0) {
    return <>No Bid Placed</>;
  }

  return (
    <div>
      <Table>
        <THead>
          <TR>
            <TH>Sr. No</TH>
            <TH>Status</TH>
            <TH>Action</TH>
            <TH>Booking Id</TH>
            <TH>Vehicle No</TH>
            <TH>Driver Details</TH>
            <TH>Reporting Time</TH>
          </TR>
        </THead>
        <TBody>
          {awardedData?.map((el, i) => (
            <TR key={el.Unique_Id}>
              <TD>{i + 1}</TD>
              <TD>
                <p className={el.Is_Confirmed ? "awardedStatusText boxcenter" : "pendingStatusText"}>
                  {el.Is_Confirmed ? "Awarded" : "Pending"}
                  {el.Is_Accepted ? "- Accepted" : ""}
                  {el.Is_Rejected ? (
                    <>
                      - <p className="rejectedStatusText">Rejected</p>
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </TD>

              <TD>
                {el.Is_Confirmed ? (
                  <div className="awardedBnDiv boxcenter">
                    {el.Is_Accepted ? (
                      "-"
                    ) : el.Is_Rejected ? (
                      ""
                    ) : (
                      <Button
                        text="Accept"
                        className="acptBtn"
                        handleClick={() => {
                          setAwardedAcceptModal(true);
                          setID(el._id);
                        }}
                      />
                    )}

                    {el.Is_Rejected ? (
                      "-"
                    ) : el.Is_Accepted ? (
                      ""
                    ) : (
                      <Button
                        text="Reject"
                        className="rejBtn"
                        handleClick={() => {
                          setCnfModal(true);
                          setID(el._id);
                        }}
                      />
                    )}
                  </div>
                ) : (
                  ""
                )}
              </TD>
              <TD>{el.Is_Accepted ? el.Unique_Id : "-"}</TD>
              <TD>{el.Vehicle_Number && el.Vehicle_Number.Vehicle_No}</TD>
              <TD>{el.Driver_Name ? `${el.Driver_Name} - ${el.Driver_Number}` : "-"}</TD>
              <TD>{el.Reporting_Time ? <FormattedTime time={el.Reporting_Time} /> : "-"}</TD>
            </TR>
          ))}
          <Reactportal
            open={awardedAcceptModal}
            modalHeading="Vehicle Confirmation"
            onClose={() => setAwardedAcceptModal(false)}
            modalClassName={"VehicleCnfModal"}
            contentClassName={"VehicleCnfModal-content"}
          >
            <AwardedAcceptedModal
              ID={ID}
              loadId={loadId}
              onClose={() => {
                setAwardedAcceptModal(false);
              }}
              RefetchUpdated={() => HandleRefetch()}
            />
          </Reactportal>
          <Reactportal
            open={cnfModal}
            modalHeading="Confirmation"
            modalClassName="confirmationModal"
            contentClassName="confirmationModal-content"
            onClose={() => setCnfModal(false)}
          >
            <CnfModal
              onClose={() => setCnfModal(false)}
              cnfMessage={
                "Do you want to reject these awarded vehicles? (If you reject this awarded vehicle penalty of ₹ 2000 will deduct from your account)"
              }
              editCommittedVehiclesData={() => {
                rejectAwardedVehicle(ID);
              }}
              loading={isEditCommittedVehiclesLoading}
            />
          </Reactportal>
        </TBody>
      </Table>
    </div>
  );
}

export default ResultTable;
