import { gql } from "@apollo/client";

export const CREATE_USER_MUTATION_LOGIN = gql`
  mutation Login($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      token
      user {
        id
        Unique_Id
        accType
        firstName
        FullName
        mobileNumber
        email
        Root_User
        password
        createdAt
        lastName
        Type_Of_User
        Is_Active
        message
      }
      AccountData {
        _id
        UserId
        Unique_Id
        Aadhar_Number
        Orgnaization_Type
        Business_Address
        Business_Address_2
        Business_City
        Business_Name
        Business_Pan
        Business_Pincode
        Business_State
        Email
        GST_Number
        Land_Line
        Mobile_Number
        Pan_Number
        Proprietor_Name
        Residential_Address
        Residential_Address_2
        Residential_City
        Residential_Pincode
        Residential_State
        Aadhar_Card
        Pan_Card
        Proprietor_Photo
        GST_Certificate
        Shop_Act_Licence
        Office_Board_Photo
        MSME_certificate
        Self_Declaration_video
        Other_Doc_1
        Other_Doc_2
        Bank_Name
        Account_Type
        BankAccount_Type
        IFSC_Code
        Account_Number
        Cancelled_Cheque
        Visiting_Card
        Is_Verified
        Is_Mobile_Number_Verified
        Is_Email_Verified
        Is_Bank_Account_Lock
        Is_Document_Verified
        createdAt
        updatedAt
      }
      message
    }
  }
`;

export const GET_LOGIN_OTP = gql`
  query SendLoginOtp($emailOrNumber: String!, $accType: String!) {
    sendLoginOtp(emailOrNumber: $emailOrNumber, accType: $accType) {
      message
      sessionId
    }
  }
`;

export const VERIFY_LOGIN_OTP = gql`
  query VerifyLoginOtp($otp: Int!, $sessionId: String) {
    verifyLoginOtp(otp: $otp, sessionId: $sessionId) {
      token
      user {
        id
        Unique_Id
        accType
        FullName
        firstName
        mobileNumber
        email
        Type_Of_User
        Is_Active
        Root_User
        password
        createdAt
        lastName
        message
        EXPO_Token
      }
      AccountData {
        _id
        UserId
        Unique_Id
        Aadhar_Number
        Orgnaization_Type
        Business_Address
        Business_Address_2
        Business_City
        Business_Name
        Business_Pan
        Business_Pincode
        Business_State
        Email
        GST_Number
        Land_Line
        Mobile_Number
        Pan_Number
        Proprietor_Name
        Residential_Address
        Residential_Address_2
        Residential_City
        Residential_Pincode
        Residential_State
        Aadhar_Card
        Pan_Card
        Proprietor_Photo
        GST_Certificate
        Shop_Act_Licence
        Office_Board_Photo
        MSME_certificate
        Self_Declaration_video
        Other_Doc_1
        Other_Doc_2
        Bank_Name
        Account_Type
        BankAccount_Type
        IFSC_Code
        Account_Number
        Cancelled_Cheque
        Visiting_Card
        Owned_Vehicles
        Total_Vehicles
        Is_Verified
        Is_Document_Verified
        Is_Lock
        Is_Mobile_Number_Verified
        Is_Email_Verified
        Is_Bank_Account_Lock
        Is_Pan_Verified
        Is_Bank_Verified
        Is_Aadhar_Verified
        TermsAndConditionAgreed
        TermsAndConditionAgreedOn
        createdAt
        updatedAt
      }
      message
    }
  }
`;
