import { gql } from "@apollo/client";

export const PINCODE_QUERY = gql`
  query PincodeQuery($pincode: Int!) {
    pincode(pincode: $pincode) {
      Division
      State
      District
      Name
    }
  }
`;
