import React, { useState } from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "./CustomTimePicker.scss";
import moment from "moment";

function CustomTimePicker({
  label,
  name,
  handleCustomTimePickerChange,
  handleformikdata,
  value,
  err,
  handleBlur,
  touched,
  require,
}) {
  const [selectedTime, setSelectedTime] = useState("");
  const TimeArr = value && value.split(" ");
  const formattedTime = moment(TimeArr, "HH:mm");

  const formikdatamanage = (time) => {
    handleCustomTimePickerChange({
      target: {
        name: name,
        value: time,
      },
    });

    handleformikdata(time, name);
  };

  const handleChange = (value) => {
    const timeSelected = value._d;
    setSelectedTime(value);
    formikdatamanage(timeSelected.toTimeString());
  };

  return (
    <div>
      <p className="tpLabel">
        {label}
        {require && " *"}
      </p>
      <div
        className={
          selectedTime ? "time-picker-container" : "time-picker-container1"
        }
      >
        <TimePicker
          name={name}
          className={touched && err && "rc-time-picker_err"}
          showSecond={false}
          use12Hours
          format="hh:mm A"
          // placeholder="Select Time"
          value={value ? formattedTime : selectedTime}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          clearIcon
          minuteStep={15}
        />
      </div>
      {/* <p className="errmessage">{err}</p> */}
    </div>
  );
}

export default CustomTimePicker;
