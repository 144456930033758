import React from "react";
import { MdCancel, MdOutlineModeEditOutline, MdVerified } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";
import Button from "../../../../../../../Components/Common/Button/Button";

function UserStatus({ data, edit, handleClick, handleToggleEdit, verifiedDetails }) {
  return (
    <div className="userStatus">
      <div className="statusHeadingDiv">
        <p className="statusSubHeading">
          {!data.Is_Lock
            ? "Get the best out of EaseMyTransport by adding the remaining details!"
            : "Please feel free to contact our helpline number if you would like to edit or change any details on your profile."}
        </p>
        <div className="bothBtnDiv">
          {data.Is_Verified ? (
            <Button text={"Active"} className={"activeDiv"} disabled={true} />
          ) : (
            <Button text={"Inactive"} className={"notActiveDiv"} disabled={true} />
          )}
          {!data.Is_Lock ? (
            <div className="saveBtnBox">
              {edit ? (
                <Button
                  text={"Edit"}
                  className={"acceptBtn buttonDiv"}
                  handleClick={handleClick}
                  icon={<MdOutlineModeEditOutline />}
                />
              ) : (
                <Button
                  text={"Cancel"}
                  className="acceptBtn buttonDiv"
                  handleClick={handleToggleEdit}
                  icon={<MdCancel />}
                />
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="statusDetails">
        {verifiedDetails?.Is_Aadhar_Verified ? (
          <div className="statusDetailFields">
            <MdVerified
              style={{
                color: "green",
              }}
            />
            <p className="contentText">Aadhar Verified</p>
          </div>
        ) : (
          <div className="statusDetailFields">
            <RxCrossCircled
              style={{
                color: "red",
              }}
            />
            <p className="contentText">Aadhar Not Verified</p>
          </div>
        )}

        {verifiedDetails?.Is_Pan_Verified ? (
          <div className="statusDetailFields">
            <MdVerified
              style={{
                color: "green",
              }}
            />
            <p className="contentText">PAN Verified</p>
          </div>
        ) : (
          <div className="statusDetailFields">
            <RxCrossCircled
              style={{
                color: "red",
              }}
            />
            <p className="contentText">PAN Number Not Verified</p>
          </div>
        )}

        {verifiedDetails?.Is_Bank_Verified ? (
          <div className="statusDetailFields">
            <MdVerified
              style={{
                color: "green",
              }}
            />
            <p className="contentText">Bank Details Verified</p>
          </div>
        ) : (
          <div className="statusDetailFields">
            <RxCrossCircled
              style={{
                color: "red",
              }}
            />
            <p className="contentText">Bank Details Not Verified</p>
          </div>
        )}
        {data.Is_Email_Verified ? (
          <div className="statusDetailFields">
            <MdVerified
              style={{
                color: "green",
              }}
            />
            <p className="contentText">Email ID Verified</p>
          </div>
        ) : (
          <div className="statusDetailFields">
            <RxCrossCircled
              style={{
                color: "red",
              }}
            />
            <p className="contentText">Email ID Not Verified</p>
          </div>
        )}
        {data.Is_Mobile_Number_Verified ? (
          <div className="statusDetailFields">
            <MdVerified
              style={{
                color: "green",
              }}
            />
            <p className="contentText">Mobile Number Verified</p>
          </div>
        ) : (
          <div className="statusDetailFields">
            <RxCrossCircled
              style={{
                color: "red",
              }}
            />
            <p className="contentText">Mobile Number Not Verified</p>
          </div>
        )}

        {verifiedDetails?.Is_Document_Verified ? (
          <div className="statusDetailFields">
            <MdVerified
              style={{
                color: "green",
              }}
            />
            <p className="contentText">Documents Verified</p>
          </div>
        ) : (
          <div className="statusDetailFields">
            <RxCrossCircled
              style={{
                color: "red",
              }}
            />
            <p className="contentText">Documents Not Verified</p>
          </div>
        )}

        {data.Is_Verified ? (
          <div className="statusDetailFields">
            <MdVerified
              style={{
                color: "green",
              }}
            />
            <p className="contentText">Profile Verified</p>
          </div>
        ) : (
          <div className="statusDetailFields">
            <RxCrossCircled
              style={{
                color: "red",
              }}
            />
            <p className="contentText">Profile Unverified</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserStatus;
