import React from "react";
import {FaTruck} from "react-icons/fa"
import "./TruckLoadingIndicator.scss";

function TruckLoadingIndicator() {
  return (
    <>
      <p className="loadingText boxcenter">
        Loading... <FaTruck className="truckIcon"/>
      </p>
    </>
  );
}

export default TruckLoadingIndicator;
