import React from "react";
import Button from "../../Common/Button/Button";
import PortalFooter from "../Reactportal/PortalFooter";
import Spinner from "../../Common/Spinner/Spinner";
import "./CnfModal.scss";

function CnfModal({ listArr, newBidDetails, onClose, loading }) {
  return (
    <div className="cnfModalContentBox">
      <p className="cnfModalText">
        <ul>
          {listArr?.map((el) => (
            <li key="el">{el}</li>
          ))}
        </ul>
      </p>

      <PortalFooter>
        {loading ? (
          <Button
            text={<Spinner />}
            type="submit"
            className={"loadingAcceptBtn"}
          />
        ) : ( 
          <Button
            text={"Confirm"}
            type="submit"
            className={"acceptBtn"}
            handleClick={() => {
              newBidDetails();
              onClose();
            }}
            disabled={loading}
          />
        )}

        <Button
          handleClick={onClose}
          text="Back"
          className={"rejectBtn"}
          disabled={loading}
        />
      </PortalFooter>
    </div>
  );
}

export default CnfModal;
