import React, { useState, useEffect } from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import { useDispatch } from "react-redux";
import "./LoadPostTypeModal.scss";

function LoadPostTypeModal({ next, onClose }) {
  const [LoadType, setLoadType] = useState("Open Load");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "LoadType",
      payload: { data: LoadType, key: "Load_Post_Type" },
    });
  }, [LoadType]);

  return (
    <>
      <div className="LoadPostTypeModalDiv">
        <div className="TypesOfLoadDiv">
          {/* <div
            className="descDiv"
            onClick={() => {
              setLoadType("Auction Load");
            }}
          >
            <div className="radio-btn">
              <h3 className="radioLable">30 Min Auction</h3>
              <input
                className="radioInput"
                type="radio"
                value={LoadType}
                name="loadType"
                checked={LoadType === "Auction Load"}
              />
            </div>
            Your load will be auctioned for a limited duration of 30 minutes,
            during which all service providers (SPs) can participate and place
            their bids. The fixed time slot ensures a higher number of active
            users, leading to increased competition and a greater number of
            bidders.
          </div> */}

          <div
            className="descDiv"
            onClick={() => {
              setLoadType("Open Load");
            }}
          >
            <div className="radio-btn">
              <h3 className="radioLable">Open Load</h3>
              <input
                className="radioInput"
                type="radio"
                value={LoadType}
                name="loadType"
                checked={LoadType === "Open Load"}
              />
            </div>
            Your load will be made available to all SPs for a maximum of 24 hours. Interested parties can submit their
            bids, which will remain valid for 1 hour. The load will expire either at the set time or once you confirm
            the vehicle, whichever occurs earlier.
          </div>

          <div
            className="descDiv"
            onClick={() => {
              setLoadType("Fixed Load");
            }}
          >
            <div className="radio-btn">
              <h3 className="radioLable">Fixed Price</h3>
              <input
                className="radioInput"
                type="radio"
                value={LoadType}
                name="loadType"
                checked={LoadType === "Fixed Load"}
              />
            </div>
            Your load will be live for a maximum of 24 hours. You can set a fixed price for the load, and anyone
            interested in providing a vehicle at your specified offer price can accept your offer. Once the offer is
            accepted, you will need to confirm the vehicle. The load will expire either at the set time or once you
            confirm the vehicle, whichever occurs earlier.
          </div>
        </div>
      </div>
      <PortalFooter>
        <Button text={"Next"} className="acceptBtn" handleClick={next} />
        <Button text={"Cancel"} className="rejectBtn" handleClick={onClose} />
      </PortalFooter>
    </>
  );
}

export default LoadPostTypeModal;
