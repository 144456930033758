import React, { useEffect, useState } from "react";
import Reactportal from "../../../Components/LpComponents/Reactportal/Reactportal";
import { Loadfilds, DocUpload, AuctionLoadData, OpenLoadData, FixedLoadData } from "./AdddLoad.Config";
import Addloadsection from "./Addloadsection/Addloadsection";
import PortalFooter from "../../../Components/LpComponents/Reactportal/PortalFooter";
import Button from "../../../Components/Common/Button/Button";
import FileUploadBox from "../../../Components/LpComponents/FileUploadBox/FileUploadBox";
import AddLoadFooter from "./AddLoadFooter/AddLoadFooter";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { FaEdit } from "react-icons/fa";
import socket from "../../../CommonFunction/Socket/socketClient";
import Cookies from "js-cookie";
import ProfileStatusModal from "../../../Components/Common/AllModals/ProfileStatusModal/ProfileStatusModal";
import { useNavigate } from "react-router-dom";
import LoadPostTypeModal from "../../../Components/Common/AllModals/LoadPostTypeModal/LoadPostTypeModal";
import "./Addload.scss";
import { GET_USER_ACCOUNT_BALANCE } from "../../../gql/getUserAccountBalance";
import { get_coustomer } from "../../../gql/getCoustomer";
import { CREATE_LOAD_MUTATION } from "../../../gql/createLoad";
import { EDIT_LOAD } from "../../../gql/editLoad";
import { DELETE_LOAD_MUTATION } from "../../../gql/deleteLoad";

const Addload = ({ isEdit, fielddata, show, TypeOfPortal, submitType }) => {
  const [toggle, settoggle] = useState(show ? show : false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openLoadPostTypeModal, setOpenLoadPostTypeModal] = useState(false);
  const [accBalance, setAccBalance] = useState(0);
  const [alldata, setalldata] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [errdata, seterrdata] = useState({});
  const [errors, setErrors] = useState(false);
  const [notesdata, setNotesdata] = useState("");
  const [loadType, setLoadType] = useState("");
  const [allFieldsData, setAllFieldsData] = useState(fielddata);
  const [files, setFiles] = useState({});
  const [createLoad, { loading: createLoadLoading }] = useMutation(CREATE_LOAD_MUTATION);
  const [editLoad, { loading: editLoadLoading }] = useMutation(EDIT_LOAD);
  const [deleteLoad, { loading: deleteLoadLoading }] = useMutation(DELETE_LOAD_MUTATION);

  const [isCreateLoadLoading, setIsCreateLoadLoading] = useState(false);
  const FormData = useSelector((store) => store.Addload);
  const dispatch = useDispatch();
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");
  const { data } = useQuery(get_coustomer);
  const navigate = useNavigate();
  let accDataId = Cookies.get("accDataId");
  const { data: accountData } = useQuery(GET_USER_ACCOUNT_BALANCE, {
    variables: {
      accountTypeId: accDataId,
    },
  });
  let accBal = useSelector((store) => store.accBalance.accBalance);

  useEffect(() => {
    setIsCreateLoadLoading(createLoadLoading || editLoadLoading || deleteLoadLoading);
  }, [createLoadLoading, editLoadLoading, deleteLoadLoading]);

  useEffect(() => {
    if (accountData && accountData.GetAccountBalanceWithAccountType_ID) {
      setAccBalance(accountData.GetAccountBalanceWithAccountType_ID[0].Account_Balance);
    }
  }, [accountData]);

  useEffect(() => {
    if (data) {
      setCustomerData(data.AccountSearchFromCookies);
    }
  }, [data]);

  function closeModalWithGoToProfile() {
    setOpenProfileModal(false);
    navigate("/profile-settings");
  }

  function next() {
    setOpenLoadPostTypeModal(false);
    settoggle(!toggle);
  }
  const LoadPostTypeModalOnClose = () => {
    setOpenLoadPostTypeModal(false);
    dispatch({
      type: "LoadType",
      payload: { data: "", key: "Load_Post_Type" },
    });
  };

  useEffect(() => {
    let allfiles = {};
    for (var key in files) {
      allfiles = {
        ...allfiles,
        ...files[key],
      };
    }

    dispatch({
      type: "addload",
      payload: { data: allfiles, key: "Documents" },
    });
  }, [files]);

  useEffect(() => {
    socket.emit("InAddload", "I am in add load");
  }, [toggle]);

  useEffect(() => {
    setalldata(FormData.fielddata);
  }, [FormData]);

  useEffect(() => {
    setLoadType(FormData.LoadTypes && FormData.LoadTypes.data);
  }, [FormData]);

  useEffect(() => {
    seterrdata(FormData.errors);
  }, [FormData]);

  useEffect(() => {
    if (FormData.errorsedit && FormData.errorsedit.error) {
      setErrors(FormData.errorsedit.error);
    }
  }, [FormData.errorsedit]);

  useEffect(() => {
    setAllFieldsData(fielddata);
  }, [fielddata, TypeOfPortal]);

  useEffect(() => {
    if (show === true) {
      settoggle(true);
    }
  }, [show]);

  useEffect(() => {
    if (TypeOfPortal === "Edit Load") {
      if (allFieldsData && allFieldsData.Notes) {
        setNotesdata(allFieldsData.Notes);
      }
    }
  }, [allFieldsData]);

  const submit = async (closeForm) => {
    let data = { Notes: notesdata };
    for (let key in alldata) {
      data = {
        ...data,
        ...alldata[key],
      };
    }

    let edata = {
      Load_Details: false,
      Loading_Point: false,
      Material_Details: false,
      Unloading_Point: false,
      Vehicle_Details: false,
      Auction_Load: loadType === "Auction Load" ? false : true,
      Open_Load: loadType === "Open Load" ? false : true,
      Documents: true,
    };

    for (let key in alldata) {
      if (key === "Documents") {
        continue;
      }
      if (errdata[key] && Object.keys(errdata[key]).length > 0 === undefined) {
        edata[key] = false;
      }
      if (
        errdata[key] &&
        alldata[key] &&
        Object.keys(errdata[key]).length > 0 &&
        Object.keys(alldata[key]).length > 0
      ) {
        edata[key] = false;
      }
      if (
        errdata[key] &&
        alldata[key] &&
        !Object.keys(errdata[key]).length > 0 &&
        Object.keys(alldata[key]).length > 0
      ) {
        edata[key] = true;
      }
      if (
        errdata[key] &&
        alldata[key] &&
        Object.keys(errdata[key]).length > 0 &&
        !Object.keys(alldata[key]).length > 0
      ) {
        edata[key] = false;
      }
    }

    let allFieldsValid = Object.values(edata).every((value) => value === true);

    if (!allFieldsValid) {
      toast.error("Please Fill All The Mandatory Details");
      return;
    }
    // }

    if (TypeOfPortal === "Edit Load") {
      delete data.createdAt;
      delete data.id;
      delete data.updatedAt;
      delete data.__typename;
      if (data.Target_Price === null) {
        delete data.Load_Live_Duration;
        delete data.Target_Price;
      }
      if (data.Expected_Price == null) {
        delete data.Auction_Date;
        delete data.Auction_Time;
        delete data.Expected_Price;
        delete data.Load_Manager;
      }
    }

    if (TypeOfPortal === "Edit Load") {
      await editLoad({
        variables: { id: allFieldsData._id, userInput: data },
      })
        .then((res) => {
          toast.success("Load Edited Sucessful");
          dispatch({ type: "set_loadData", payload: true });
          if (closeForm) {
            settoggle(false);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else if (TypeOfPortal === "Delete Load") {
      await deleteLoad({
        variables: { id: allFieldsData._id },
      })
        .then(() => {
          toast.success("Load Deleted");
          dispatch({ type: "set_loadData", payload: true });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      await createLoad({
        variables: {
          createInput: { ...data, Load_Post_Type: loadType },
        },
      })
        .then(async (res) => {
          //***************Create JV **************

          // let transactions = [
          //   {
          //     Transaction_Type: "Credit",
          //     Account_ID: AccountBalanceId,
          //     Amount: 100,
          //   },
          //   {
          //     Transaction_Type: "Debit",
          //     Account_ID: AccountBalanceIds.Sales_Account_Load_Post_Charges,
          //     Amount: 100,
          //   },
          // ];

          // await createVoucherEntries({
          //   variables: {
          //     createInput: {
          //       Entry_Type: "Journal Voucher",
          //       Voucher_Type: "Journal Transaction",
          //       Narration: `Load Post Charge (${res.data.createLoad.From_City} to ${res.data.createLoad.To_City})`,
          //       Date: new Date(),
          //       Doc_No: res.data.createLoad.Unique_Id,
          //     },
          //     transactions: transactions,
          //   },
          // })

          //  ***********Auto Transactions *****************

          // await autoTransactions({
          //   variables: {
          //     chargeInput: {
          //       Event_Code: "65130cef10b20ccf3f87cff4",
          //       BookingID: res.data.createLoad._id,
          //     },
          //   },
          // });

          setNotesdata("");
          socket.emit("NewLoadAdded", res);
          dispatch({ type: "set_loadData", payload: true });
          toast.success("Load added");

          if (closeForm) {
            settoggle(false);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  const Closeformfunction = () => {
    settoggle(false);
    setNotesdata("");
    dispatch({ type: "ClearAddData", payload: { data: {} } });
  };

  return (
    <div className="addLoadForm">
      {isEdit ? (
        <FaEdit
          className="loadEditBtn"
          onClick={() => {
            settoggle(!toggle);
          }}
        />
      ) : (
        <Button
          className={"addLoadFormBtn"}
          text="+ Add Load"
          handleClick={() => {
            if (accInfo.Type_Of_User === "Sub_User" || (customerData.Is_Verified && accBal >= 100)) {
              setOpenLoadPostTypeModal(true);
            } else {
              setOpenProfileModal(true);
            }
          }}
        />
      )}

      <Reactportal
        open={openProfileModal}
        modalHeading={"Your Profile is Inactive"}
        modalClassName={"confirmationModal"}
        contentClassName={"confirmationModal-content"}
        onClose={() => setOpenProfileModal(false)}
      >
        <ProfileStatusModal
          ProfoleData={customerData}
          accInfo={accInfo}
          accBalance={accBalance}
          onClose={() => setOpenProfileModal(false)}
          closeModalWithGoToProfile={closeModalWithGoToProfile}
        />
      </Reactportal>

      <Reactportal
        open={openLoadPostTypeModal}
        modalHeading={"Setect Load Post Type"}
        modalClassName={"loadDetailModal"}
        contentClassName={"loadDetailModal-content"}
        onClose={LoadPostTypeModalOnClose}
      >
        <LoadPostTypeModal onClose={LoadPostTypeModalOnClose} next={next} />
      </Reactportal>

      <Reactportal
        open={toggle}
        modalClassName={"myModal"}
        contentClassName={"addloadform-content"}
        modalHeading={TypeOfPortal === "Edit Load" ? "Edit Load Post" : "+ Add Load Entry"}
        onClose={Closeformfunction}
        TypeOfPortal={TypeOfPortal}
        TypeOfId={"Load Id :"}
        id={allFieldsData?.Unique_Id ?? ""}
      >
        <div className="Allloadmaindiv">
          {Loadfilds.map((el, i) => {
            return (
              <div key={el.id}>
                <Addloadsection
                  fields={el.fields}
                  Type={el.name}
                  fielddata={allFieldsData}
                  TypeOfPortal={TypeOfPortal}
                />
              </div>
            );
          })}

          {loadType === "Auction Load" || (allFieldsData && allFieldsData.Load_Post_Type === "Auction Load") ? (
            <Addloadsection
              fields={AuctionLoadData.fields}
              Type={AuctionLoadData.name}
              fielddata={allFieldsData}
              TypeOfPortal={TypeOfPortal} 
            />
          ) : (
            ""
          )}
          {loadType === "Open Load" || (allFieldsData && allFieldsData.Load_Post_Type === "Open Load") ? (
            <Addloadsection
              fields={OpenLoadData.fields}
              Type={OpenLoadData.name}
              fielddata={allFieldsData}
              TypeOfPortal={TypeOfPortal}
            />
          ) : (
            ""
          )}

          {loadType === "Fixed Load" || (allFieldsData && allFieldsData.Load_Post_Type === "Fixed Load") ? (
            <Addloadsection
              fields={FixedLoadData.fields}
              Type={FixedLoadData.name}
              fielddata={allFieldsData}
              TypeOfPortal={TypeOfPortal}
            />
          ) : (
            ""
          )}

          <div className="moredetailsection">
            <div className="note">
              <p>NOTES: </p>
              <textarea
                name="note"
                value={notesdata ? notesdata : null}
                placeholder="Please Enter Notes If any other information needs to be provided"
                onChange={(e) => setNotesdata(e.target.value)}
              ></textarea>
            </div>

            <div className="filessection">
              <FileUploadBox
                UploadFields={DocUpload}
                allFieldsData={allFieldsData}
                sendFilesFun={(e) => {
                  setFiles({
                    ...files,
                    files1: e,
                  });
                }}
                pageName={"AddLoadPage"}
              />
            </div>
          </div>
        </div>
        <PortalFooter>
          <AddLoadFooter
            submitType={submitType}
            submitForm={submit}
            SaveAndClose={() => submit("Is_Close")}
            onClose={Closeformfunction}
            errors={errors}
            loading={isCreateLoadLoading}
          />
        </PortalFooter>
      </Reactportal>
    </div>
  );
};

export default Addload;
