import { gql } from "@apollo/client";

export const CREATE_SUB_USER_MUTATION = gql`
  mutation CreateSubUser($SubUserInput: SubUserInput!) {
    CreateSubUser(SubUserInput: $SubUserInput) {
      id
      message
    }
  }
`;
