import React from "react";
import logoemt from "../../../Assets/Logo/logoemt.png";
import Infosection from "./Infosection/Infosection";
import { Link } from "react-router-dom";
import Addload from "../../../Templets/Forms/AddLoad/Addload";
import Cookies from "js-cookie";
import { MobileNumber } from "../../../db/MobileNumber/MobileNumber";
import "./Header.scss";

const Header = () => {
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");
  const accType = accInfo.accType;

  return (
    <div className="headerdiv">
      <div className="logodiv">
        <Link to={accType === "Load Partner Account" ? "/live" : "/open"}>
          <img src={logoemt} className="emtlogo" alt="emtlogo" />
        </Link>
      </div>

      <div className="searchBoxDiv">
        <div className="search">
          {accType === "Load Partner Account" && (
            <Addload TypeOfPortal="Add Load Entry" submitType={"Save Load"} />
          )}
        </div>
        <div className="helpNoDiv">
          {accType === "Supply Partner Account" ? (
            <p className="helpLineNoText">
              Helpline No :<br></br> {MobileNumber.MobileNumberForSP}
            </p>
          ) : (
            <p className="helpLineNoText">
              Helpline No :<br></br> {MobileNumber.MobileNumberForLP}
            </p>
          )}
        </div>
        <div>
          <Infosection />
        </div>
      </div>
    </div>
  );
};

export default Header;
