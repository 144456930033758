import React from 'react'
import "./TBody.scss"

function TBody({children}) {
  return (
    <tbody>
        {children}
    </tbody>
  )
}

export default TBody
