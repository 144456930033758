import React, { useEffect, useState } from "react";
import AddUserModal from "../../../../../../Components/Common/AllModals/AddUserModal/AddUserModal";
import ReactTable from "../../../../../../Components/Common/ReactTable/ReactTable";
import Button from "../../../../../../Components/Common/Button/Button";
import ReactPortal from "../../../../../../Components/LpComponents/Reactportal/Reactportal";
import { COLOUMNS } from "./columns";
import { NotificationData } from "./UserManagement.config";
import { useQuery } from "@apollo/client";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import TruckLoadingIndicator from "../../../../../../Components/Common/TruckLoadingIndicator/TruckLoadingIndicator";
import { GET_SUB_USERS } from "../../../../../../gql/getSubUser";
import "./UserManagementPage.scss";

function UserManagementPage() {
  const [addUserModal, setAddUserModal] = useState(false);
  const accInfo = JSON.parse(Cookies.get("accInfo"));
  const [alldata, setalldata] = useState([]);
  const { data, loading, refetch } = useQuery(GET_SUB_USERS, {
    variables: {
      Root_User_Id: accInfo.id,
    },
  });
  const dispatch = useDispatch();
  const isLoadData = useSelector((store) => store.loadData.loadData);

  useEffect(() => {
    if (data && data.getAllSubUsersForRootUser) {
      const filteredData = data.getAllSubUsersForRootUser.filter((user) => user.Type_Of_User === "Sub_User");
      setalldata(filteredData);
    }
  }, [data]);

  useEffect(() => {
    if (isLoadData) {
      refetch();
      dispatch({ type: "set_loadData", payload: false });
    }
  }, [isLoadData, refetch]);

  return (
    <div className="userManagementPage">
      <p className="userManagementHeading">User-Management</p>

      <div className="userManagementContent">
        <Button text="+ Add User" className={"addLoadFormBtn"} handleClick={() => setAddUserModal(true)} />

        <ReactPortal
          open={addUserModal}
          modalClassName="addUserModal"
          contentClassName="addUserModal-content"
          modalHeading={"Add User"}
          onClose={() => setAddUserModal(false)}
        >
          <AddUserModal onClose={() => setAddUserModal(false)} NotificationData={NotificationData} />
        </ReactPortal>

        <p className="contentHeading">Directory</p>
        {loading ? (
          <TruckLoadingIndicator />
        ) : alldata.length <= 0 ? (
          "No Sub-User's Added Yet!"
        ) : (
          <ReactTable DATA={alldata} COLOUMNS={COLOUMNS} editUserModal={true} pagination={true} />
        )}
      </div>
    </div>
  );
}

export default UserManagementPage;
