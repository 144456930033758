import React, { useEffect, useRef } from "react";

function OutsideClick({ setToggled, children, className }) {
  const componentRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setToggled(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });
  return <div className={className} ref={componentRef}>{children}</div>;
}

export default OutsideClick;
