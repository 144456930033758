import React from "react";
import "./FromAndTo.scss";
const FromAndTo = ({ fromCity, toCity, totalDistance }) => {
  return (
    <div className="FromAndTo">
      <p>
        {fromCity} to {toCity} {totalDistance ? `(${totalDistance} km)` : ""}
      </p>
    </div>
  );
};

export default FromAndTo;
