import React, { useState } from "react";
import Button from "../../../../Components/Common/Button/Button";
import Reactportal from "../../../../Components/LpComponents/Reactportal/Reactportal";
import CnfModal from "../../../../Components/Common/AllModals/CnfModal/CnfModal";
import { useSelector } from "react-redux";

function AddLoadFooter({ onClose, submitForm, SaveAndClose, loading }) {
  const [cnfModal, setCnfModal] = useState(false);
  const [save, setSave] = useState(false);
  let accBal = useSelector((store) => store.accBalance.accBalance);

  // console.log(save, "seavvvveee");

  return (
    <>
      <Button
        className="acceptBtn"
        text={"Save"}
        handleClick={() => {
          setCnfModal(true);
          setSave(true);
        }}
      />
      <Button
        className="acceptBtn"
        text={"Save & Close"}
        handleClick={() => {
          setCnfModal(true);
          setSave(false);
        }}
      />
      <Button className="rejectBtn" text={"Cancel"} handleClick={onClose} />

      <Reactportal
        open={cnfModal}
        modalHeading={"Confirmation"}
        modalClassName={"confirmationModal"}
        contentClassName={"confirmationModal-content"}
        onClose={() => setCnfModal(false)}
      >
        <CnfModal
          cnfMessage={
            "Please enusre the load post is correct. Once the load is posted imediatley it will go live. Load post charges will be debitted from your balance."
          }
          onClose={() => setCnfModal(false)}
          editCommittedVehiclesData={save ? submitForm : SaveAndClose}
          loading={loading}
        />
      </Reactportal>
    </>
  );
}

export default AddLoadFooter;
