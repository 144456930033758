import React, { useState } from "react";
import { FaListOl } from "react-icons/fa";
import { RxLapTimer } from "react-icons/rx";
import { GoCircleSlash } from "react-icons/go";
import { RiAuctionFill } from "react-icons/ri";
import Tippy from "@tippy.js/react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import "./SpFilterBox.scss";
import "tippy.js/dist/tippy.css";
import { MdOutlineFiberManualRecord } from "react-icons/md";
import { BiFont } from "react-icons/bi";

function FIcon() {
  return <div className="FIcon">F</div>;
}
function SpFilterBox() {
  const iconsArr = [
    { reactIcon: "FaListOl" },
    { reactIcon: "Bids_Placed" },
    { reactIcon: "GoCircleSlash" },
    { reactIcon: "RxLapTimer" },
    { reactIcon: "BiFont" },
    { reactIcon: "MdOutlineFiberManualRecord" },
    { reactIcon: "FIcon" },
  ];
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItem1, setSelectedItem1] = useState(iconsArr[0].reactIcon);
  const dispatch = useDispatch();

  // const [alldata, setalldata] = useState({});
  // const { slectedFilter } = useSelector((store) => store.filterData);

  function handleIconClick(item, item2) {
    setSelectedItem(item);
    setSelectedItem1(item2);
  }

  useEffect(() => {
    // console.log(selectedItem1);
    dispatch({
      type: "addfields",
      payload: { data: selectedItem, key: "Filters" },
    });
  }, [selectedItem]);

  return (
    <div className="SpFilterBox boxcenter">
      <Tippy content={"All Loads"} placement="bottom">
        <div
          className={selectedItem1 === "FaListOl" ? "selectedIcon boxcenter" : "SpFilterIconsDiv boxcenter"}
          onClick={() => handleIconClick({}, "FaListOl")}
        >
          <FaListOl className="SpFilters" />
        </div>
      </Tippy>
      <Tippy content={"Bid Placed"} placement="bottom">
        <div
          className={selectedItem1 === "RiAuctionFill" ? "selectedIcon boxcenter" : "SpFilterIconsDiv boxcenter"}
          onClick={() => handleIconClick({ Bids_Placed: "true" }, "RiAuctionFill")}
        >
          <RiAuctionFill className="SpFilters" />
        </div>
      </Tippy>
      <Tippy content={"No Bid Placed"} placement="bottom">
        <div
          className={selectedItem1 === "GoCircleSlash" ? "selectedIcon boxcenter" : "SpFilterIconsDiv boxcenter"}
          onClick={() => handleIconClick({ No_Bids_Placed: "true" }, "GoCircleSlash")}
        >
          <GoCircleSlash className="SpFilters" />
        </div>
      </Tippy>
      {/* <Tippy content={"Ongoing Auction"} placement="bottom">
        <div
          className={
            selectedItem1 === "RxLapTimer"
              ? "selectedIcon boxcenter"
              : "SpFilterIconsDiv boxcenter"
          }
          onClick={() => handleIconClick({"On_Going_Auction":"true"},"RxLapTimer")}
        >
          <RxLapTimer className="SpFilters" />
        </div>
      </Tippy> */}
      {/* <Tippy content={"Auction"} placement="bottom">
        <div
          className={
            selectedItem1 === "BiFont"
              ? "selectedIcon boxcenter"
              : "SpFilterIconsDiv boxcenter"
          }
          onClick={() => handleIconClick({"Load_Post_Type":"Auction Load"},"BiFont")}
        >
          <BiFont className="SpFilters" />
        </div>
      </Tippy> */}
      <Tippy content={"Open"} placement="bottom">
        <div
          className={
            selectedItem1 === "MdOutlineFiberManualRecord" ? "selectedIcon boxcenter" : "SpFilterIconsDiv boxcenter"
          }
          onClick={() => handleIconClick({ Load_Post_Type: "Open Load" }, "MdOutlineFiberManualRecord")}
        >
          <MdOutlineFiberManualRecord className="SpFilters" />
        </div>
      </Tippy>
      <Tippy content={"Fixed"} placement="bottom">
        <div
          className={selectedItem1 === "FIcon" ? "selectedIconFix boxcenter" : "SpFilterIconsDivFix boxcenter"}
          onClick={() => handleIconClick({ Load_Post_Type: "Fixed Load" }, "FIcon")}
        >
          <FIcon className="SpFiltersFixed" />
        </div>
      </Tippy>
    </div>
  );
}

export default SpFilterBox;
