import { useState, useRef, useEffect } from "react";

const useTimer = (initialSeconds) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const timerId = useRef(null);

  useEffect(() => {
    return () => stop();
  }, []);

  const start = () => {
    if (timerId.current === null) {
      setIsTimerRunning((prev) => true);
      timerId.current = setInterval(() => {
        setSeconds((prev) => {
          if (prev === 1) {
            stop();
            return initialSeconds;
          }
          return prev - 1;
        });
      }, 1000);
    }
  };

  const stop = () => {
    if (timerId.current) {
      setIsTimerRunning((prev) => false);
      clearInterval(timerId.current);
      timerId.current = null;
    }
  };

  return { seconds, start, stop, isTimerRunning };
};

export default useTimer;
