import React from "react";
import "./Note.scss";
const Note = ({Notes}) => {
  let notesArr = Notes.split("\n");


  if(!Notes){
    return <p></p>
  }

  return (
    <div className="note">
      <p className="noteHeading">Important Note :</p>
      {notesArr?.map((el) => (
        <p key={el} className="noteText">
          {el}
        </p>
      ))}
    </div>
  );
};

export default Note;
