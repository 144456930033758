import React, { useEffect, useState } from "react";
import { AllInputFields } from "./UserProfileData.config";
import UserProfileSections from "./UserProfileData/UserProfileSection";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import UserStatus from "./UserProfileData/UserStatus";
import { toast } from "react-toastify";
import Reactportal from "../../../../../../Components/LpComponents/Reactportal/Reactportal";
import BankDetailsModal from "../../../../../../Components/Common/AllModals/BankDetailsModal/BankDetailsModal";
import { Stepper } from "../../../../../../Components";
import { get_coustomer } from "../../../../../../gql/getCoustomer";
import { Edit_Coustomer } from "../../../../../../gql/editCoustomer";
import { PAN_VERIFY } from "../../../../../../gql/getPan";
import { BANK_ACCOUNT_VERIFY } from "../../../../../../gql/getBank";
import "./UserProfilePage.scss";

function UserProfilePage() {
  const [edit, setEdit] = useState(true);
  const { data, refetch } = useQuery(get_coustomer);
  const [editAccount] = useMutation(Edit_Coustomer);
  const FormData = useSelector((store) => store.editProfile);
  const [errdata, seterrdata] = useState({});
  const [bankModal, setBankModal] = useState(false);
  const [hancan, setHancan] = useState(false);

  const { fielddata } = useSelector((store) => store.editProfile);
  const [allFieldsData, setAllFieldsData] = useState(data ? data.AccountSearchFromCookies : []);

  const [verifyBank] = useMutation(BANK_ACCOUNT_VERIFY);

  const [verifyPan] = useMutation(PAN_VERIFY);

  const Refetch = useSelector((store) => store.editProfile.refresh);
  const dispatch = useDispatch();

  useEffect(() => {
    const allFormData = {};
    AllInputFields.forEach((formItem) => {
      const formItemData = {};
      formItem.fields.forEach((field) => {
        formItemData[field.name] = allFieldsData[field.name];
      });
      allFormData[formItem.name] = { ...formItemData };
    });

    dispatch({
      type: "setProfileData",
      payload: allFormData,
    });
  }, [allFieldsData]);

  useEffect(() => {
    if (data) {
      setAllFieldsData(data.AccountSearchFromCookies);
    }
  }, [data]);

  useEffect(() => {
    seterrdata(FormData.errors);
  }, [FormData]);

  useEffect(() => {
    refetch();
    if (Refetch) {
      dispatch({ type: "Refetch", payload: false });
    }
  }, [Refetch]);

  const handleClick = () => {
    setEdit(false);
  };

  const handleClickSave = async (toggleLoading, handleNext) => {
    toggleLoading();

    if (allFieldsData && allFieldsData._id) {
      let data = {};
      for (let key in fielddata) {
        data = {
          ...data,
          ...fielddata[key],
        };
      }

      let edata = {
        // Business_Details: true,
        Bank_Details: false,
      };

      for (let key in fielddata) {
        if (key === "Documents") {
          edata["Documents"] =
            !!fielddata[key].Aadhar_Card && !!fielddata[key].Pan_Card && !!fielddata[key].Proprietor_Photo;
          continue;
        }
        if (
          key === "Bank_Details" &&
          data.Account_Number &&
          data.BankAccount_Type &&
          data.Bank_Name &&
          data.IFSC_Code &&
          data.IFSC_Code.length === 11
        ) {
          edata["Bank_Details"] = true;
          continue;
        }
        if (errdata[key] && Object.keys(errdata[key]).length > 0 === undefined) {
          edata[key] = false;
        }
        if (
          errdata[key] &&
          fielddata[key] &&
          Object.keys(errdata[key]).length > 0 &&
          Object.keys(fielddata[key]).length > 0
        ) {
          edata[key] = false;
        }
        if (
          errdata[key] &&
          fielddata[key] &&
          !Object.keys(errdata[key]).length > 0 &&
          Object.keys(fielddata[key]).length > 0
        ) {
          edata[key] = true;
        }
        if (
          errdata[key] &&
          fielddata[key] &&
          Object.keys(errdata[key]).length > 0 &&
          !Object.keys(fielddata[key]).length > 0
        ) {
          console.log(key);
          edata[key] = false;
        }
      }

      let allFieldsValid = Object.values(edata).every((value) => value === true);

      if (!allFieldsValid) {
        toast.error("Please Fill All The Mandatory Details");
        toggleLoading();
        return;
      }

      if (typeof data.Land_Line !== "string" && data.Land_Line !== undefined) {
        data.Land_Line = "";
      }
      editAccount({
        variables: { id: allFieldsData._id, userInput: data },
      })
        .then((res) => {
          refetch();
          setEdit(true);
          toast.success("Profile Edited Sucess");
          handleNext();
        })
        .catch((err) => {
          toast.error("Enter Valid Field");
          console.log(err.message);
        })
        .finally(() => toggleLoading());
    }
  };

  const handleBankVerifiy = (toggleLoading, handleNext) => {
    toggleLoading();
    verifyBank({
      variables: {
        bankAccountVerifyUserInput2: {
          Acc_Number: fielddata.Bank_Details.Account_Number,
          IFSC: fielddata.Bank_Details.IFSC_Code,
          Account_Type: fielddata.Bank_Details.BankAccount_Type,
          Bank_Name: fielddata.Bank_Details.Bank_Name,
        },
      },
    })
      .then((response) => {
        toast.success("Bank Details Verified!");
        dispatch({
          type: "editProfileData",
          payload: {
            key: "Verified_Details",
            data: {
              name: "Is_Bank_Verified",
              value: true,
            },
          },
        });
        handleNext();
      })
      .catch(({ message }) => {
        if (message === "FAILURE INVALID BENEFICIARY MOBILE NO/MAS") {
          toast.error("Invalid bank details!");
        }
        console.log("Error:", message);
      })
      .finally(() => toggleLoading());
  };

  const handlePanVerify = (toggleLoading, handleNext) => {
    toggleLoading();

    verifyPan({
      variables: {
        userInput: {
          PanNumber: fielddata.Business_Details.Business_Pan,
        },
      },
    })
      .then((response) => {
        toast.success("Pan Number Verified");
        dispatch({
          type: "editProfileData",
          payload: {
            key: "Verified_Details",
            data: {
              name: "Is_Pan_Verified",
              value: true,
            },
          },
        });
        handleNext();
      })
      .catch((error) => {
        console.log("Error", error);
        toast.error(error.message);
      })
      .finally(() => toggleLoading());
  };

  return (
    <div className="userProfilePage">
      <p className="userProfilePageHeading">My Profile</p>
      <UserStatus
        data={allFieldsData}
        verifiedDetails={fielddata.Verified_Details}
        edit={edit}
        handleClick={handleClick}
        handleToggleEdit={() => setEdit((prev) => !prev)}
      />

      <Stepper
        height="400px"
        edit={edit}
        handleClickSave={handleClickSave}
        handleBankVerifiy={handleBankVerifiy}
        handlePanVerify={handlePanVerify}
      >
        {AllInputFields.slice(0, 4).map((formItem, index) => (
          <UserProfileSections
            key={formItem.name}
            message={formItem.message}
            data={formItem}
            name={formItem.name}
            fielddata={allFieldsData}
            formData={fielddata[formItem.name]}
            edit={edit}
            hancan={hancan}
            title={formItem.name}
            index={index}
          />
        ))}
      </Stepper>
      <Reactportal
        open={bankModal}
        modalHeading={"Bank Details Confirmation"}
        modalClassName={"confirmationModal"}
        contentClassName={"confirmationModal-content"}
        onClose={() => setBankModal(false)}
      >
        <BankDetailsModal
          bankMessage={
            "Please enusre the Bank Details are correct. Once your bank details have been verified and locked in our system, they cannot be directly edited. If you need to make changes to your bank details, please send an email from your registered email address to our support team."
          }
          onClose={() => setBankModal(false)}
          editBankData={() => {
            handleClickSave();
            setBankModal(false);
          }}
          bankdata={FormData.fielddata.Bank_Details}
        />
      </Reactportal>
    </div>
  );
}

export default UserProfilePage;
