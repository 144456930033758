export const filterBoxData = [
  {
    id: Math.random() * 10,
    name: "Global_Search",
    label: "Search here",
    type: "search",
    placeholder: "Search here",
    isSearchable: false,
  },
  {
    id: Math.random() * 10,
    name: "Origin_City",
    label: "Origin City",
    type: "inputdrop",
    placeholder: "Search here",
    searchType: true,
    isSearchable: false,
    autoCapital:false,
  },
  {
    id: Math.random() * 10,
    name: "Destination_City",
    label: "Destination City",
    type: "inputdrop",
    placeholder: "Search here",
    searchType: true,
    autoCapital:true,
    autoComplete: "off",
    isSearchable: false,
  },
  {
    id: Math.random() * 10,
    name: "Origin_State",
    label: "Origin State",
    type: "inputdrop",
    placeholder: "Search here",
    searchType: true,
    autoComplete: "off",
    isSearchable: false,
    autoCapital:true,
  },
  {
    id: Math.random() * 10,
    name: "Destination_State",
    label: "Destination State",
    type: "inputdrop",
    placeholder: "Search here",
    searchType: true,
    isSearchable: false,
    autoCapital:true,
  },
  {
    id: Math.random() * 10,
    name: "Vehicle_Type",
    label: "Vehicle type",
    type: "inputdrop",
    placeholder: "Search here",
    isSearchable: true,
    autoCapital:true,
    dropdowncontent: [
      "Trailer Dala Body",
      "Trailer Flat Bed",
      "Open Body Truck",
      "Container",
      "Tanker",
      "Tipper",
      "Dumper",
      "Bulker",
      "LVC Open",
      "LVC Container",
      "Mini/Pickup",
    ],
  },
  {
    id: Math.random() * 10,
    name: "Payment_Type",
    label: "Payment Type",
    type: "inputdrop",
    autoCapital:true,
    placeholder: "Search here",
    isSearchable: true,
    dropdowncontent: ["Advance 90%","Advance 100%", "To Pay"],
  },
  {
    id: Math.random() * 10,
    name: "Material_Insurance",
    label: "Is Material Insured ?",
    type: "inputdrop",
    placeholder: "Search here",
    isSearchable: true,
    dropdowncontent: ["Yes", "No"],
  },
  {
    id: Math.random() * 10,
    name: "ODC",
    label: "ODC",
    type: "inputdrop",
    placeholder: "Search here",
    isSearchable: true,
    dropdowncontent: ["Yes", "No"],
  },
  {
    id: Math.random() * 10,
    name: "Loading_By",
    label: "Loading By",
    type: "inputdrop",
    placeholder: "Search here",
    isSearchable: true,
    dropdowncontent: ["By Hand", "By Fork Lift", "By Crane"],
  },
  {
    id: Math.random() * 10,
    name: "Loading_Scope",
    label: "Loading Scope",
    type: "inputdrop",
    placeholder: "Search here",
    isSearchable: true,
    dropdowncontent: ["Consignor", "Transporter"],
  },
  {
    id: Math.random() * 10,
    name: "Unloading_Scope",
    label: "Unloading Scope",
    type: "inputdrop",
    placeholder: "Search here",
    isSearchable: true,
    dropdowncontent: ["Consignor", "Transporter"],
  },
  {
    id: Math.random() * 10,
    name: "Loading_Date",
    label: "Loading Date",
    type: "date",
    placeholder: "Search here",
  },
];
