import React, { useEffect, useState } from "react";
import { IoMdAddCircle } from "react-icons/io";
import WalletBalancePopup from "./WalletBalanceComponents/WalletBalancePopup/WalletBalancePopup";
import { useMutation, useQuery } from "@apollo/client";
import Cookies from "js-cookie";
import NumberFormatter from "../NumberFormatter/NumberFormatter";
import "./WalletBalance.scss";
import { useDispatch } from "react-redux";
import socket from "../../../CommonFunction/Socket/socketClient";
import { GET_USER_ACCOUNT_BALANCE } from "../../../gql/getUserAccountBalance";

function WalletBalance() {
  const [accBalance, setAccBalance] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [accBalId, setAccBalId] = useState("");
  const dispatch = useDispatch();

  let accDataId = Cookies.get("accDataId");

  const { data, refetch } = useQuery(GET_USER_ACCOUNT_BALANCE, {
    variables: {
      accountTypeId: accDataId,
    },
  });

  useEffect(() => {
    if (data && data.GetAccountBalanceWithAccountType_ID) {
      Cookies.set("AccountBalanceId", data.GetAccountBalanceWithAccountType_ID[0]._id);
      setAccBalId(data.GetAccountBalanceWithAccountType_ID[0]._id);
      setAccBalance(data.GetAccountBalanceWithAccountType_ID[0].Account_Balance);
      dispatch({
        type: "set_accountBalance",
        payload: data.GetAccountBalanceWithAccountType_ID[0].Account_Balance,
      });
    }
  }, [data]);

  function updateState() {
    setModalIsOpen(!modalIsOpen);
  }
  useEffect(() => {
    if (socket) {
      socket.on("Update_Balance", (e) => {
        refetch();
      });
    }

    return () => {
      if (socket) {
        socket.off("Update_Balance");
      }
    };
  }, [accBalance, accBalId]);

  useEffect(() => {
    socket.on("NewLoad_Recived", () => {
      refetch();
    });
  }, []);

  useEffect(() => {
    socket.on("CurrentTask_Update", (id) => {
      refetch();
    });
  }, []);

  return (
    <div className="walletBalanceDiv boxcenter">
      <div className="balTextDiv boxcenter">
        <p className="walletBalanceHeading">Balance :</p>
        <p className={accBalance < 500 ? "lowBal boxcenter" : "walletBalanceContent boxcenter"}>
          <NumberFormatter number={accBalance} /> /-
        </p>
      </div>
      <div className="addWalletBalDiv boxcenter">
        <IoMdAddCircle className="addIcon" onClick={updateState} />
        {modalIsOpen ? (
          <div className="popupDiv">
            <WalletBalancePopup setModalIsOpen={setModalIsOpen} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default WalletBalance;
