import React from "react";
import "./BidNote.scss";

function BidNote({ bidnote }) {
  if (!bidnote) {
    return;
  }

  return (
    <div className="bidNoteDiv">
      <p className="bidNoteHeading">Bid Note</p>
      <p className="bidNoteText">{bidnote}</p>
    </div>
  );
}

export default BidNote;
