import React, { useMemo } from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import { useState } from "react";
import ModalCard from "../ModalCard/ModalCard";
import { gql, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import "./UserProfileModal.scss";
import Spinner from "../../Spinner/Spinner";
import InputBox from "../../InputBox/InputBox";
import { toast } from "react-toastify";
let counter = 1;

const Pan_Verify = gql`
  mutation Pan_Verify($userInput: Kyc_Input) {
    Pan_Verify(UserInput: $userInput) {
      message
    }
  }
`;

const BusinessVerifiactionModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const [verifyPan, { loading: panVerifyLoading, error: panVerifyError }] = useMutation(Pan_Verify);
  const { session_id } = useSelector((store) => store.kycData);
  const [formDetails, setFormDetails] = useState({
    PanNumber: "",
  });
  const panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
  const {
    fielddata: { Verified_Details },
  } = useSelector((store) => store.editProfile);

  const handleFormChange = (event) => {
    const { value, name } = event.target;

    setFormDetails({
      ...formDetails,
      [name]: value.toUpperCase(),
    });
  };

  const isFormFilled = useMemo(
    () => Object.values(formDetails).every((value) => value.length === 10) && panRegex.test(formDetails.PanNumber),
    [formDetails]
  );

  const handlePanVerify = async (panDetails) => {
    await verifyPan({
      variables: {
        userInput: panDetails,
      },
    })
      .then((response) => {
        onClose();
        toast.success("Pan Number Verified");
        dispatch({
          type: "editProfileData",
          payload: {
            key: "Verified_Details",
            data: {
              name: "Is_Pan_Verified",
              value: true,
            },
          },
        });
      })
      .catch((error) => {
        console.log("Error", error);
        toast.error(error.message);
      });
  };

  // console.log("re-rendering", counter++);

  return (
    <>
      <div className="modal-body">
        <ModalCard>
          <InputBox
            type="text"
            placeholder="Enter PAN Number"
            maxNo={10}
            name="PanNumber"
            value={formDetails.PanNumber}
            handleInputChange={handleFormChange}
            handleBlur={() => {}}
            uppercase={true}
            autoComplete="off"
          />
        </ModalCard>
      </div>
      <PortalFooter>
        {!Verified_Details?.Is_Pan_Verified && (
          <Button
            text={panVerifyLoading ? <Spinner /> : "Verify"}
            handleClick={() => handlePanVerify(formDetails)}
            className={isFormFilled ? "acceptBtn" : "disabledBtnBank"}
            disabled={!isFormFilled}
          />
        )}
        <Button text="Cancel" className="rejectBtn" handleClick={onClose} />
      </PortalFooter>
    </>
  );
};

export default BusinessVerifiactionModal;
