import React, { useEffect, useState } from "react";
import CompletedBox from "./CompletedComponents/CompletedBox/CompletedBox";
import { useQuery } from "@apollo/client";
import TruckLoadingIndicator from "../../../../Components/Common/TruckLoadingIndicator/TruckLoadingIndicator";
import ShowNoDataFound from "../../../../Components/Common/ShowNoDataFound/ShowNoDataFound";
import "./Completed.scss";
import { CommittedVehiclesByUserId_withPopulate } from "../../../../gql/getOngoingData";

function Completed() {
  const [acceptedCommittedVehiclesData, setAcceptedCommittedVehiclesData] = useState([]);
  const { loading, data } = useQuery(CommittedVehiclesByUserId_withPopulate, {
    variables: {
      isAccepted: true,
    },
  });

  useEffect(() => {
    if (data && data.CommittedVehiclesByUserId_withPopulate) {
      let getData = data.CommittedVehiclesByUserId_withPopulate;
      getData = getData.filter((el) => {
        return el.POD_Collected_From_SP;
      });
      setAcceptedCommittedVehiclesData(getData);
    }
  }, [data]);

  return loading ? (
    <TruckLoadingIndicator />
  ) : acceptedCommittedVehiclesData.length === 0 ? (
    <ShowNoDataFound msg={"No Bookings Available"} />
  ) : (
    <div className="completedPage">
      {acceptedCommittedVehiclesData?.map((el) => (
        <CompletedBox key={el.id} data={el} />
      ))}
    </div>
  );
}

export default Completed;
