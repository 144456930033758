const initial = {
    session_id: "",
};

export function KYCProcessReducer(state = initial, { type, payload }) {
    switch (type) {
        case "setSession_id": {
            return {
                ...state,
                session_id: payload,
            };
        }

        case 'resetSession_id': {
            return initial;
        }

        default: {
            return state;
        }
    }
}