import React from "react";
import "./UnloadingPoint.scss";
import drop from "../../../../Assets/Icons/drop.png";

const UnloadingPoint = ({ area, landmark, city, state, pincode }) => {
  return (
    <div className="unloadingbody">
      <div className="UPHeadingDiv">
        <img src={drop} alt="drop" />
        <p className="UPHeading">Unlaoding Point</p>
      </div>

      <p className="UPContent">
        {landmark}, {area},<br></br>
        {city}, {state}, {pincode}
      </p>
    </div>
  );
};

export default UnloadingPoint;
