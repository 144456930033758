import React from "react";
import { RiCloseCircleFill } from "react-icons/ri";

function PortalHeader({ modalHeading, loadId, onClose }) {
  return (
    <div className="header">
      <div className="flex">
        <p className="headingText">{modalHeading}</p>
      </div>

      <div className="flex">
        {loadId ? <p>Load Id : {loadId}</p> : ""}
        <RiCloseCircleFill className="closeIcon" onClick={onClose} />
      </div>
    </div>
  );
}

export default PortalHeader;
