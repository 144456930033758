
import "./About.scss"

const About = () => {
    return (
        <>
            <div className="AboutUs_MainDiv">
                <div className="AboutUs_text">
                    <p className="AboutUs">ABOUT US</p>
                    <p>Best Road Transportation Services In India</p>
                    <p>We are India’s best Truck Suppliers as we have thousands of trucks attached to us that can be Aligned for your trip all over India. All the Vehicles are verified for Fitness, Documents, and No Dues.</p>
                    <p>All the Drivers are verified and Background checked.</p>
                </div>

            </div>

        </>
    )
}

export default About