import { gql } from "@apollo/client";

export const GET_SPPAGE_COUNT = gql`
  query GetSpPageData_Count {
    GetSpPageData_Count {
      Live_Count
      Resulted_Count
      Awarded
      Archive_Count
      OnGoing
      Completed
    }
  }
`;

export const GET_LPPAGE_COUNT = gql`
  query GetLpPageData_Count {
    GetLpPageData_Count {
      Live_Count
      Resulted_Count
      Awarded
      Archive_Count
      OnGoing
      Completed
    }
  }
`;