import React, { useState } from "react";
import Button from "../../Button/Button";
import InputBox from "../../InputBox/InputBox";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import "./EditUserModal.scss";
import { EDIT_SUB_USER } from "../../../../gql/editSubUser";

function EditUserModal({ onClose, cells }) {
  const [editSubUser] = useMutation(EDIT_SUB_USER);
  const dispatch = useDispatch();
  const [currentIsActive, setCurrentIsActive] = useState(cells.row.original.Is_Active);

  const initialValues = {
    firstName: cells.row.original.firstName,
    email: cells.row.original.email,
    mobileNumber: cells.row.original.mobileNumber,
    password: "",
    Is_Active: "",
  };

  const validate = (values) => {
    // console.log("validate", values);

    let errors = {};
    if (values.firstName.length < 3) {
      errors.firstName = "At least 3 letters required";
    } else if (String(values.mobileNumber).length < 10) {
      errors.mobileNumber = "Enter Valid 10 Digit Mobile Number";
    } else if (values.password) {
      if (values.password.search(/[A-Z]/) < 0) {
        errors.password = "At least one uppercase letter required";
      } else if (!/[a-z]/.test(values.password)) {
        errors.password = "At least one lowercase letter required";
      } else if (values.password.search(/[0-9]/) < 0) {
        errors.password = "At least one number is required";
      }
    }

    return errors;
  };

  const onSubmit = async (values) => {
    // console.log("onSubmit", values);

    if (values.password) {
      values = {
        ...values,
        password: values.password,
      };
    } else {
      delete values.password;
    }

    const { firstName, email, mobileNumber, password, Is_Active } = values;
    const originalValues = cells.row.original;

    if (
      firstName === originalValues.firstName &&
      email === originalValues.email &&
      mobileNumber === originalValues.mobileNumber &&
      currentIsActive === originalValues.Is_Active &&
      !password
    ) {
      onClose();
      return;
    }

    values.Is_Active = currentIsActive;

    await editSubUser({
      variables: {
        editSubUserId: cells.row.original.id,
        userInput: values,
      },
    })
      .then(() => {
        dispatch({ type: "set_loadData", payload: true });
        onClose();
        toast.success("User Profile Updated");
      })
      .catch((err) => console.log(err));
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  const handleformikdata = (Value, id) => {
    let data = Value;
    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);
  };

  function handleCheckboxChecked(e) {
    // console.log(e.target.checked);
    setCurrentIsActive(e.target.checked);
  }

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <div className="addUserModalDiv">
        <div className="addUserModalContentDiv boxcenter">
          <div className="addUserModalHeaderDiv">
            <p className="addUserModalHeader">User Name :</p>
          </div>
          <InputBox
            className={"filterInputBox"}
            name="firstName"
            type="text"
            placeholder={cells.row.original.firstName}
            handleInputChange={formik.handleChange}
            handleformikdata={handleformikdata}
            autoComplete={false}
            autoCapital={true}
            err={formik.errors.firstName}
            touched={formik.touched.firstName ? formik.touched.firstName : false}
          />
        </div>
        <div className="addUserModalContentDiv boxcenter">
          <div className="addUserModalHeaderDiv">
            <p className="addUserModalHeader">User Email :</p>
          </div>
          <InputBox
            className={"filterInputBox"}
            type="email"
            name="email"
            placeholder={cells.row.original.email}
            handleInputChange={formik.handleChange}
            handleformikdata={handleformikdata}
            autoComplete={false}
          />
        </div>
        <div className="addUserModalContentDiv boxcenter">
          <div className="addUserModalHeaderDiv">
            <p className="addUserModalHeader">Mobile No :</p>
          </div>
          <InputBox
            className={"filterInputBox no-spinners"}
            type="number"
            name="mobileNumber"
            placeholder={cells.row.original.mobileNumber}
            handleInputChange={formik.handleChange}
            handleformikdata={handleformikdata}
            autoComplete={false}
            maxNo={10}
            err={formik.errors.mobileNumber}
            touched={formik.touched.mobileNumber ? formik.touched.mobileNumber : false}
          />
        </div>
        <div className="addUserModalContentDiv boxcenter">
          <div className="addUserModalHeaderDiv">
            <p className="addUserModalHeader">Password :</p>
          </div>
          <InputBox
            className={"filterInputBox no-spinners"}
            type="password"
            name="password"
            handleInputChange={formik.handleChange}
            handleformikdata={handleformikdata}
            autoComplete={false}
            err={formik.errors.password}
            touched={formik.touched.password ? formik.touched.password : false}
          />
        </div>

        <div className="addUserModalContentDiv boxcenter">
          <div className="addUserModalHeaderDiv">
            <p className="addUserModalHeader">Is Active :</p>
          </div>
          <input
            type="checkbox"
            name="Is_Active"
            id="Is_Active"
            checked={currentIsActive}
            onChange={handleCheckboxChecked}
          />
        </div>

        {/* <div className="handleNotificationBox">
        <div className="emailNotification">
          <p className="notificationHeading">Email Notification</p>
          <p className="contentText">
            <InputBox type="checkbox" />
            Load Post
          </p>
          <p className="contentText">
            <InputBox type="checkbox" />
            Auction Start
          </p>
          <p className="contentText">
            <InputBox type="checkbox" />
            Allotment
          </p>
        </div>
        <div className="SMSNotification">
          <p className="notificationHeading">SMS Notification</p>
          <p className="contentText">
            <InputBox type="checkbox" />
            Load Post
          </p>
          <p className="contentText">
            <InputBox type="checkbox" />
            Auction Start
          </p>
          <p className="contentText">
            <InputBox type="checkbox" />
            Allotment
          </p>
        </div>
      </div> */}

        <PortalFooter>
          <Button text="Update" className={"acceptBtn"} type="submit" />
          <Button text="Cancel" className={"rejectBtn"} handleClick={onClose} />
        </PortalFooter>
      </div>
    </form>
  );
}

export default EditUserModal;
