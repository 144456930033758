import "./App.scss";
import Allroutes from "./Allroutes/Allroutes";
import SpRoutes from "./Allroutes/SpRoutes";
import { ToastContainer } from "react-toastify";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");
  const storedResponse = Cookies.get("token");

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_URI}/graphql`,
    credentials: "include",
    cache: new InMemoryCache(),
  });

  const authLink = setContext((_, { headers }) => {
    let token = storedResponse;
    return {
      headers: {
        ...headers,
        authorization: token ? token : "",
      },
    };
  });

  /* This code is creating a new instance of the ApolloClient class with a link and cache. The link is a
combination of an authLink and an httpLink, which are used to connect to a GraphQL server. The cache
is an in-memory cache used to store and manage the client-side data. */

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <>
      <ApolloProvider client={client}>
        <div className="App">
          {accInfo && accInfo.accType === "Load Partner Account" ? <Allroutes /> : <SpRoutes />}
        </div>
        <ToastContainer
          className="toastContainer"
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </ApolloProvider>
    </>
  );
}

export default App;
