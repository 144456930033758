import React, { useState } from "react";
import Button from "../../../../../../Components/Common/Button/Button";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import Reactportal from "../../../../../../Components/SpComponents/Reactportal/Reactportal";
import CnfModal from "../../../../../../Components/Common/AllModals/CnfModal/CnfModal";
import LoadingCompleteModal from "../../../../../../Components/Common/AllModals/CurrentTaskModal/LoadingCompletedModal/LoadingCompleteModal";
import VehicleReachedAtUnloadingPointModal from "../../../../../../Components/Common/AllModals/CurrentTaskModal/VehicleReachedAtUnloadingPointModal/VehicleReachedAtUnloadingPointModal";
import UnloadingComplete from "../../../../../../Components/Common/AllModals/CurrentTaskModal/UnloadingCompleteModal/UnloadingCompleteModal";
import CurrentTaskTable from "../../../../../../Components/Common/CurrentTaskTable/CurrentTaskTable";
import ViewClaimModal from "../../../../../../Components/Common/AllModals/CurrentTaskModal/ViewClaimModal/ViewClaimModal";
import RaiseClaimModal from "../../../../../../Components/Common/AllModals/RaiseClaimModal/RaiseClaimModal";
import socket from "../../../../../../CommonFunction/Socket/socketClient";
import { EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE } from "../../../../../../gql/editCommitedVehiclesWithoutVerifyBalance";
import "./CurrentTask.scss";

function CurrentTask({
  LpId,
  loadId,
  CommittedVehicleId,
  CommittedVehicleUniqueId,
  VehicleNo,
  Current_Stage,
  Sp_Claim_Raised,
  Lp_Claim_Raised,
  Sp_Claim_Cleared,
  Lp_Claim_Cleared,
  paymentType,
}) {
  const [
    editCommittedVehiclesDataWithoutVerifyBalance,
    { loading: editCommittedVehiclesDataWithoutVerifyBalanceLoading },
  ] = useMutation(EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE);

  const [cnfModal, setCnfModal] = useState(false);
  const [loadingCompletedModal, setLoadingCompletedModal] = useState(false);
  const [noClaimCnfModal, setNoClaimCnfModal] = useState(false);
  const [viewClaimModal, setViewClaimModal] = useState(false);
  const [spRaiseClaimModal, setSpRaiseClaimModal] = useState(false);

  const [vehicleReahedAtUnloadingPointModal, setVehicleReahedAtUnloadingPoint] = useState(false);
  const [unloadingComplete, setUnloadComplete] = useState(false);

  async function handleCommittedVehiclesChangeWithoutVerifyBalance({ updateObj, msg }) {
    await editCommittedVehiclesDataWithoutVerifyBalance({
      variables: {
        editCommittedVehiclesWithoutVerifyUserWithBalanceId: CommittedVehicleId,
        userInput: updateObj,
      },
    })
      .then((res) => {
        socket.emit("Update_CurrentTask", LpId, CommittedVehicleId);
        toast.success(msg);
      })
      .catch((err) => toast.error(err.message));
  }

  return (
    <div className="currentTaskDiv">
      <p className="currentTaskHeader">Current Task</p>

      {Current_Stage === "Booking Confirmed" ? (
        <>
          <p className="taskMessage">Please confirm once the vehicle reaches at loading point</p>
          <Button text="Reached at Loading Point" className={"acceptBtn"} handleClick={() => setCnfModal(true)} />

          <Reactportal
            open={cnfModal}
            modalHeading="Confirmation"
            onClose={() => setCnfModal(false)}
            modalClassName={"cnfModal"}
            contentClassName={"cnfModal-content"}
          >
            <CnfModal
              editCommittedVehiclesData={() => {
                handleCommittedVehiclesChangeWithoutVerifyBalance({
                  updateObj: {
                    Vehicle_Reached_At_Loading_Point: true,
                    Current_Stage: "Vehicle Reached At Loading Point",
                    Vehicle_Reached_At_Loading_Point_Time: new Date(),
                  },
                  msg: "Vehicle Reached At Loading Point",
                });
              }}
              cnfMessage={"Is Vehicle Reached At Loading Point ?"}
              onClose={() => setCnfModal(false)}
              loading={editCommittedVehiclesDataWithoutVerifyBalanceLoading}
            />
          </Reactportal>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "Vehicle Reached At Loading Point" ? (
        <>
          <p className="taskMessage">Please confirm once the loading has been completed</p>
          <Button text="Loading Complete" className={"acceptBtn"} handleClick={() => setLoadingCompletedModal(true)} />

          <Reactportal
            open={loadingCompletedModal}
            modalHeading="Confirmation"
            onClose={() => setLoadingCompletedModal(false)}
            modalClassName={"cnfModal"}
            contentClassName={"cnfModal-content"}
          >
            <LoadingCompleteModal
              paymentType={paymentType}
              LpId={LpId}
              loadId={loadId}
              CommittedVehicleId={CommittedVehicleId}
              onClose={() => setLoadingCompletedModal(false)}
            />
          </Reactportal>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "Loading Completed" ? (
        <>
          <p className="taskMessage">Awaiting for the advance to be proccessed by the customer</p>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "Advance Processed" ? (
        <>
          <p className="taskMessage">Please confirm once the vehicle reaches at unloading point</p>
          <Button
            text="Reached at Unloading Point"
            className={"acceptBtn"}
            handleClick={() => setVehicleReahedAtUnloadingPoint(true)}
          />
          <Reactportal
            open={vehicleReahedAtUnloadingPointModal}
            modalHeading="Confirmation"
            onClose={() => setVehicleReahedAtUnloadingPoint(false)}
            modalClassName={"cnfModal"}
            contentClassName={"cnfModal-content"}
          >
            <VehicleReachedAtUnloadingPointModal
              editCommittedVehiclesData={(initialValues) => {
                handleCommittedVehiclesChangeWithoutVerifyBalance({
                  updateObj: {
                    ...initialValues,
                    Vehicle_Reach_At_Unloading_Point: true,
                    Current_Stage: "Vehicle Reach At Unloading Point",
                    Vehicle_Reach_At_Unloading_Point_Time: new Date(),
                  },
                  msg: "Vehicle Reach At Unloading Point",
                });
              }}
              onClose={() => setVehicleReahedAtUnloadingPoint(false)}
              loading={editCommittedVehiclesDataWithoutVerifyBalanceLoading}
            />
          </Reactportal>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "Vehicle Reach At Unloading Point" ? (
        <>
          <p className="taskMessage">Please confirm once the vehicle has been unloaded</p>
          <Button text="Unloading Complete" className={"acceptBtn"} handleClick={() => setUnloadComplete(true)} />
          <Reactportal
            open={unloadingComplete}
            modalHeading="Confirmation"
            onClose={() => setUnloadComplete(false)}
            modalClassName={"cnfModal"}
            contentClassName={"cnfModal-content"}
          >
            <UnloadingComplete
              LpId={LpId}
              loadId={loadId}
              CommittedVehicleId={CommittedVehicleId}
              onClose={() => setUnloadComplete(false)}
            />
          </Reactportal>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "Unloading Completed" ? (
        <>
          <div className="currentTaskTableDiv">
            <CurrentTaskTable CommittedVehicleId={CommittedVehicleId} />
          </div>

          <div className="actionBtnDiv">
            {Lp_Claim_Raised && !Lp_Claim_Cleared && (
              <div className="spClaimViewBtn">
                <Button text="View Claim" className={"acceptBtn"} handleClick={() => setViewClaimModal(true)} />
                <Reactportal
                  open={viewClaimModal}
                  modalClassName="cnfModal"
                  contentClassName="cnfModal-content"
                  modalHeading="Claim Details"
                  onClose={() => setViewClaimModal(false)}
                >
                  <ViewClaimModal
                    LpId={LpId}
                    loadId={loadId}
                    CommittedVehicleId={CommittedVehicleId}
                    CommittedVehicleUniqueId={CommittedVehicleUniqueId}
                    VehicleNo={VehicleNo}
                    SpClaim={true}
                    onClose={() => setViewClaimModal(false)}
                    Sp_Claim_Cleared={Sp_Claim_Cleared}
                    Lp_Claim_Cleared={Lp_Claim_Cleared}
                  />
                </Reactportal>
              </div>
            )}

            {!Sp_Claim_Raised && !Sp_Claim_Cleared ? (
              <div className="lpClaimBtnDiv">
                <Button text="Raise A Claim" className={"acceptBtn"} handleClick={() => setSpRaiseClaimModal(true)} />
                <Reactportal
                  open={spRaiseClaimModal}
                  modalClassName="cnfModal"
                  contentClassName="cnfModal-content"
                  modalHeading="Raise Claim"
                  onClose={() => setSpRaiseClaimModal(false)}
                >
                  <RaiseClaimModal
                    LpId={LpId}
                    loadId={loadId}
                    SpRaise={true}
                    CommittedVehicleId={CommittedVehicleId}
                    onClose={() => setSpRaiseClaimModal(false)}
                  />
                </Reactportal>

                <Button text="No Claim" className={"rejectBtn"} handleClick={() => setNoClaimCnfModal(true)} />
                <Reactportal
                  open={noClaimCnfModal}
                  modalHeading={"Confirmation"}
                  onClose={() => setNoClaimCnfModal(false)}
                  modalClassName={"cnfModal"}
                  contentClassName={"cnfModal-content"}
                >
                  <CnfModal
                    cnfMessage={
                      "Hereafter you cannot raise a claim, Are you sure that you don't want to raise a claim ?"
                    }
                    editCommittedVehiclesData={() => {
                      let obj;

                      if (Lp_Claim_Cleared === true) {
                        obj = {
                          Sp_Claim_Cleared: true,
                          // Sp_Claim_Raised: false,
                          Clear_Delevery_Confirmation: true,
                          Current_Stage: "Clear Delevery Confirmation",
                          Clear_Delevery_Confirmation_Time: new Date(),
                        };
                      } else {
                        obj = {
                          Sp_Claim_Cleared: true,
                          // Sp_Claim_Raised: false,
                        };
                      }
                      handleCommittedVehiclesChangeWithoutVerifyBalance({
                        updateObj: obj,
                        msg: "No Claim Raised by you",
                      });
                    }}
                    onClose={() => setNoClaimCnfModal(false)}
                    loading={editCommittedVehiclesDataWithoutVerifyBalanceLoading}
                  />
                </Reactportal>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "Clear Delevery Confirmation" ? (
        <>
          <p className="taskMessage">Awaiting for the physical POD (Proof of Delivery) collected form SP.</p>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default CurrentTask;
