import React, { useEffect, useRef, useState } from "react";
import { replaceUnderscore } from "../../../CommonFunction/common";
import { BsChevronDown, BsSearch } from "react-icons/bs";
import OutsideClick from "../OutSideClick/OutsideClick";
import InputDropdown from "../InputDropdown/InputDropdown";
import { useLazyQuery } from "@apollo/client";
import Cookies from "js-cookie";
import { GET_SUB_USERS } from "../../../gql/getSubUser";
import { SEARCH_CITIES, SEARCH_STATE } from "../../../gql/getCitiState";
import "./InputBox.scss";

function InputBox({
  className,
  id,
  handleInputChange,
  type,
  label,
  formLabel,
  placeholder,
  value,
  name,
  err,
  touched,
  button,
  handelformikdata,
  dropdowndata,
  modalform,
  isRequired,
  hasApiCallback,
  autoComplete,
  checked,
  maxlength,
  minlength,
  defaultValue,
  TypeOfPortal,
  disabled,
  step,
  readOnly,
  min,
  max,
  JurnalDropdown,
  searchType,
  reset,
  formtype,
  handleBlur,
  isSearchable,
  require,
  nolable,
  foredit,
  maxNo,
  autoCapital,
  uppercase,
  autoEveryCapital,
  outOfFocus,
}) {
  const [open, setopen] = useState(false);
  const [fieldcontent, setfeildcontent] = useState("");
  const [searchText, setSearchText] = useState("");
  const numberInputRef = useRef(null);
  const [userID, setUserID] = useState();
  const [getCities, { data: dataCity }] = useLazyQuery(SEARCH_CITIES);
  const [getSearchState, { data: dataState }] = useLazyQuery(SEARCH_STATE);
  const [getRootUser, { data: dataUser }] = useLazyQuery(GET_SUB_USERS);
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");
  
  useEffect(() => {
    if (accInfo && accInfo.Type_Of_User === "Root_User") {
      setUserID(accInfo.id);
    } else if (accInfo && accInfo.Type_Of_User === "Sub_User") {
      setUserID(accInfo.Root_User);
    }
  }, [accInfo]);

  const [dropDownDatacity, setDropDownDatacity] = useState([]);
  const [dropDownDatastate, setDropDownDatastate] = useState([]);
  const [loadManager, setLoadManager] = useState([]);
  const [selectData, setSelectData] = useState(dropdowndata);

  useEffect(() => {
    if (name === "From_City" || name === "To_City" || name === "Origin_City" || name === "Destination_City") {
      getCities({ variables: { keyword: searchText } });
      setDropDownDatacity(dataCity && dataCity.searchCities);
    }
  }, [searchText, dataCity]);

  useEffect(() => {
    if (name === "Origin_State" || name === "Destination_State") {
      getSearchState({ variables: { searchStatesKeyword2: searchText } });

      setDropDownDatastate(dataState && dataState.searchStates);
    }
  }, [searchText, dataState]);

  useEffect(() => {
    if (name === "Load_Manager") {
      getRootUser({
        variables: {
          Root_User_Id: userID,
        },
      });
      setTimeout(() => {
        setLoadManager(dataUser && dataUser.getAllSubUsersForRootUser);
      }, 500);
    }
  }, [userID, dataUser]);

  useEffect(() => {
    if (searchType && dropDownDatacity) {
      const arr = dropDownDatacity.map((item) => item.name);
      setSelectData(arr);
    }
  }, [dropDownDatacity]);

  useEffect(() => {
    if (searchType && dropDownDatastate) {
      const arr = dropDownDatastate.map((item) => item.name);
      setSelectData(arr);
    }
  }, [dropDownDatastate]);

  useEffect(() => {
    if (searchType && loadManager) {
      let arr = [];

      for (var a = 0; a < loadManager.length; a++) {
        arr.push(loadManager[a].firstName || loadManager[a].FullName);
      }
      setSelectData(arr);
    }
  }, [searchType, loadManager]);

  const formikdatamanage = (e) => {
    if (TypeOfPortal === "View Load") {
      return;
    }


    let inputValue = e.target.value;

    if (type === "number") {
      const numericValue = parseInt(inputValue);

      if (numericValue < min) {
        inputValue = String(min);
      } else if (numericValue > max) {
        inputValue = String(max);
      }
    }

    setfeildcontent(inputValue);

    handleInputChange({
      target: {
        name: e.target.name,
        value: inputValue,
      },
    });
  };

  const handleDropDownClose = (action) => {
    setopen(!open);
  };
  const handleSelectSearch = (searchValue) => {
    setopen(true);
    setSearchText(searchValue);
  };
  const inputcontent = (text) => {
    if (TypeOfPortal === "View Load") {
      return;
    }
    setfeildcontent(text);
 
    setTimeout(() => {
      handleInputChange({
        target: {
          name: name,
          value: isNaN(text) === true ? text : +text,
        },
      });
    }, 1);

    if (handelformikdata) {
      handelformikdata(text, name);
    }
  };

  useEffect(() => {
    if (type === "number") {
      const handleWheel = (event) => {
        event.preventDefault();
      };
      const numberInputElement = numberInputRef.current;
      numberInputElement.addEventListener("wheel", handleWheel);
      return () => {
        numberInputElement.removeEventListener("wheel", handleWheel);
      };
    }
  });

  useEffect(() => {
    if (outOfFocus) {
      setfeildcontent("");
      if (numberInputRef.current) {
        numberInputRef.current.blur();
      }
    }
  });

  const handleKeyDown = (evt) => {
    if (
      type === "number" &&
      evt.key !== "Backspace" &&
      evt.key !== "ArrowLeft" &&
      evt.key !== "ArrowRight" &&
      (isNaN(parseFloat(evt.key)) || isNaN(parseFloat(evt.target.value + evt.key))) && // Check if the input is not a valid number
      evt.key !== "Tab"
    ) {
      evt.preventDefault();
    }
  };

  const handleKeyDown1 = (evt) => {
    if (
      type === "number" &&
      evt.key !== "Backspace" &&
      evt.key !== "ArrowLeft" &&
      evt.key !== "ArrowRight" &&
      evt.key !== "." && // Allow the decimal point key
      (isNaN(parseFloat(evt.key)) || isNaN(parseFloat(evt.target.value + evt.key))) && // Check if the input is not a valid number
      evt.key !== "Tab"
    ) {
      evt.preventDefault();
    }
  };

  function convertToUppercase(e) {
    e.target.value = e.target.value.toUpperCase();
  }

  function capitalizeFirstLetter(e) {
    e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
  }

  function capitalizeEveryFirstLetter(e) {
    const words = e.target.value.split(" ");

    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    e.target.value = capitalizedWords.join(" ");
  }

  useEffect(() => {
    if (value === 0 || fieldcontent === 0) {
      setTimeout(() => {
        setfeildcontent("");
        handleInputChange({
          target: {
            name,
            value: "",
          },
        });
      }, 1);
    }
  }, [value, fieldcontent, handleInputChange, name]);

  useEffect(() => {
    if (reset) setfeildcontent("");
  }, [reset]);

  return (
    <div className={modalform ? "form__group field" : "inputboxdiv"}>
      <input
        className={
          name === "Orgnaization_Type" || name === "BankAccount_Type"
            ? "editable"
            : modalform
            ? `form__field ${className} ${touched && err ? "error_input" : ""} ${
                name === "Pan_Number" ? "TextUpper" : ""
              } no-spinners`
            : `${className} no-spinners`
        }
        id={id}
        placeholder={modalform ? "" : placeholder}
        value={value ? value : fieldcontent}
        name={name}
        type={type}
        required={isRequired}
        hasApiCallback={hasApiCallback}
        onFocus={() => setopen(!open)}
        autoComplete={autoComplete}
        isSearchable={isSearchable}
        onChange={(e) => {
          if (isSearchable) {
            return;
          }
          let text = e.target.value + "";
          if (text.length > maxNo) {
            return;
          }

          if (TypeOfPortal === "View Load") {
            return;
          }
          if (type === "inputdrop") {
            var lowercaseValue = e.target.value.toLowerCase();
            handleSelectSearch(lowercaseValue);
          }
          if (autoCapital) {
            capitalizeFirstLetter(e);
          }
          if (autoEveryCapital) {
            capitalizeEveryFirstLetter(e);
          }
          if (uppercase) {
            convertToUppercase(e);
          }
          formikdatamanage(e);
        }}
        onBlur={(e) => {
          handleBlur(e);
          setTimeout(() => {
            setopen(false);
          }, 300);
        }}
        onKeyDown={name === "Total_Weight" ? (evt) => handleKeyDown1(evt) : (evt) => handleKeyDown(evt)}
        checked={checked}
        minLength={minlength}
        maxLength={maxlength}
        defaultValue={defaultValue}
        readOnly={readOnly}
        ref={numberInputRef}
        disabled={disabled}
      />
      {selectData && open ? (
        <OutsideClick setToggled={setopen}>
          <div className={JurnalDropdown ? "Jurnaldropdown" : "inputdropdown"}>
            <InputDropdown
              dropdowndata={selectData}
              inputcontent={inputcontent}
              searchText={searchText}
              handleDropDownClose={handleDropDownClose}
              JurnalDropdown={JurnalDropdown}
            />
          </div>
        </OutsideClick>
      ) : (
        ""
      )}

      {modalform && !JurnalDropdown && !nolable ? (
        <label className={touched && err ? "error_label" : "form__label"}>
          {label ? replaceUnderscore(label) : replaceUnderscore(name)}
          {require && " *"}
        </label>
      ) : (
        ""
      )}

      {modalform && selectData && !foredit ? <BsChevronDown className="icondown" onClick={() => setopen(!open)} /> : ""}
      {modalform && type === "search" ? <BsSearch className="iconSearch" onClick={() => setopen(!open)} /> : ""}
      {button ? (
        <button className="search__button">
          <svg className="search__icon" aria-hidden="true" viewBox="0 0 24 20">
            <g>
              <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
            </g>
          </svg>
        </button>
      ) : (
        ""
      )}

      {formtype !== "addload" && <div className="errdiv">{touched && err && <p className="error">{err}</p>}</div>}
    </div>
  );
}

export default InputBox;
