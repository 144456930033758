import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomDatePicker from "../../../CustomDatePicker/CustomDatePicker";
import InputBox from "../../../InputBox/InputBox";
import PortalFooter from "../../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../../Button/Button";
import FileUploadBox from "../../../../LpComponents/FileUploadBox/FileUploadBox";
import { loadingCompleteFileUpload } from "../../../../../db/FileUploadConfig/FileUpload.config";
import { useMutation } from "@apollo/client";
import socket from "../../../../../CommonFunction/Socket/socketClient";
import { toast } from "react-toastify";
import "./LoadingCompleteModal.scss";
import Spinner from "../../../Spinner/Spinner";
import { EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE } from "../../../../../gql/editCommitedVehiclesWithoutVerifyBalance";

function LoadingCompleteModal({ paymentType, LpId, CommittedVehicleId, onClose }) {
  const [isEditCommittedVehiclesData, setIsEditCommittedVehiclesData] = useState(false);
  const [editCommittedVehiclesData, { loading: editCommittedVehiclesDataLoading }] = useMutation(
    EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE
  );

  useEffect(() => {
    if (editCommittedVehiclesDataLoading) {
      setIsEditCommittedVehiclesData(editCommittedVehiclesDataLoading);
      console.log(editCommittedVehiclesDataLoading, "loadingCompletedModal");
    }
  }, [editCommittedVehiclesDataLoading]);

  console.log(editCommittedVehiclesDataLoading, "loadingCompletedModalout");

  const updateFilesStore = (newFiles) => {
    const updatedFilesStore = { ...initialValues };
    Object.keys(newFiles).forEach((key) => {
      formik.setFieldValue(key, newFiles[key]);
      if (updatedFilesStore.hasOwnProperty(key)) {
        updatedFilesStore[key] = newFiles[key];
      }
    });

    initialValues = {
      ...updatedFilesStore,
    };
  };

  let initialValues = {
    Invoice_Number: "",
    Eway_Bill_Number: 0,
    Eway_Bill_Created_Date: "",
    Eway_Bill_Validate_Date: "",
    Vehicle_Front_Img: "",
    Vehicle_Back_Img: "",
    Vehicle_Right_Img: "",
    Vehicle_Left_Img: "",
    Material_Img: "",
    Invoice_Copy: "",
  };

  const validate = (values) => {
    // console.log("validate", values);

    let errors = {};

    if (!values.Invoice_Number) {
      errors.Invoice_Number = "Invoice Number is required";
    } else if (!values.Eway_Bill_Number) {
      errors.Eway_Bill_Number = "EwayBill Number is required";
    } else if (!values.Eway_Bill_Created_Date) {
      errors.Eway_Bill_Created_Date = "EwayBill Created Date is required";
    } else if (!values.Eway_Bill_Validate_Date) {
      errors.Eway_Bill_Validate_Date = "EwayBill Validate Date is required";
    }
    // else if (!values.Vehicle_Front_Img) {
    //   errors.Vehicle_Front_Img = "Please Provide All Documents";
    // } else if (!values.Vehicle_Back_Img) {
    //   errors.Vehicle_Back_Img = "Please Provide All Documents";
    // } else if (!values.Vehicle_Right_Img) {
    //   errors.Vehicle_Right_Img = "Please Provide All Documents";
    // } else if (!values.Vehicle_Left_Img) {
    //   errors.Vehicle_Left_Img = "Please Provide All Documents";
    // } else if (!values.Material_Img) {
    //   errors.Material_Img = "Please Provide All Documents";
    // } else if (!values.Invoice_Copy) {
    //   errors.Invoice_Copy = "Please Provide All Documents";
    // }

    return errors;
  };

  const onSubmit = async (values) => {
    // console.log("onSubmit", values);

    let formData;

    if (paymentType === "To Pay") {
      formData = {
        ...values,
        Loading_Completed: true,
        Loading_Completed_Time: new Date(),
        Advance_Processed: true,
        Current_Stage: "Advance Processed",
        Advance_Processed_Time: new Date(),
      };
    } else {
      formData = {
        ...values,
        Loading_Completed: true,
        Current_Stage: "Loading Completed",
        Loading_Completed_Time: new Date(),
      };
    }

    await editCommittedVehiclesData({
      variables: {
        editCommittedVehiclesWithoutVerifyUserWithBalanceId: CommittedVehicleId,
        userInput: formData,
      },
    })
      .then(() => {
        socket.emit("Update_CurrentTask", LpId, CommittedVehicleId);
        onClose();
        toast.success("Loading Completed");
      })
      .catch((err) => toast.error(err.message));
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  const handleformikdata = (Value, id) => {
    let data = Value;
    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);
  };

  return (
    <div className="loadingCompleteModalDiv">
      <form onSubmit={formik.handleSubmit}>
        <div className="eachModalContentDiv">
          <p className="contentHeading">Invoice Number* :</p>
          <InputBox
            className={"cnfQtyInput no-spinners"}
            type="text"
            name="Invoice_Number"
            handleInputChange={formik.handleChange}
            handleformikdata={handleformikdata}
            autoComplete={"off"}
            err={formik.errors.Invoice_Number}
          />
        </div>
        <div className="eachModalContentDiv">
          <p className="contentHeading">E-way Bill Number* :</p>
          <InputBox
            className={"cnfQtyInput no-spinners"}
            type="number"
            name="Eway_Bill_Number"
            handleInputChange={formik.handleChange}
            handleformikdata={handleformikdata}
            autoComplete={"off"}
            err={formik.errors.Eway_Bill_Number}
          />
        </div>
        <div className="eachModalContentDiv">
          <p className="contentHeading">E-way Bill Created Date* :</p>
          <CustomDatePicker
            name="Eway_Bill_Created_Date"
            handleCustomDatePickerChange={formik.handleChange}
            handleformikdata={handleformikdata}
            err={formik.errors.Eway_Bill_Created_Date}
            autoComplete={"off"}
          />
        </div>
        <div className="eachModalContentDiv">
          <p className="contentHeading">E-way Bill Validate Date* :</p>
          <CustomDatePicker
            name="Eway_Bill_Validate_Date"
            handleCustomDatePickerChange={formik.handleChange}
            handleformikdata={handleformikdata}
            err={formik.errors.Eway_Bill_Validate_Date}
            autoComplete={"off"}
          />
        </div>
        <div className="eachModalContentDiv">
          <p className="contentMainHeading">Documents-</p>
        </div>
        <div className="fileUpload lastDiv">
          <FileUploadBox
            UploadFields={loadingCompleteFileUpload}
            format={true}
            err={
              formik.errors.Vehicle_Front_Img ||
              formik.errors.Vehicle_Back_Img ||
              formik.errors.Vehicle_Right_Img ||
              formik.errors.Vehicle_Left_Img ||
              formik.errors.Material_Img ||
              formik.errors.Invoice_Copy
            }
            sendFilesFun={(e) => {
              updateFilesStore(e);
            }}
            pageName={"OngoingPage"}
          />
        </div>
        <PortalFooter>
          {isEditCommittedVehiclesData ? (
            <Button text={<Spinner />} className={"loadingAcceptBtn"} type="button" />
          ) : (
            <Button text="Submit" className={"acceptBtn"} type="Submit" />
          )}
          <Button text="Back" className={"rejectBtn"} handleClick={onClose} />
        </PortalFooter>
      </form>
    </div>
  );
}

export default LoadingCompleteModal;
