import React from "react";
import "./UserCompanyPage.scss";

function UserCompanyPage() {
  return (
    <div className="userComapnyPage">
      <p className="userComapnyPageHeading">User-Company</p>

      <div className="userCompanyPageContent">
        <div className="companyEachDetails boxcenter">
          <p className="userCompanyEachDetailHeading">Branch Name :</p>
          <p className="userCompanyEachDetailContent">

          </p>
        </div>
        <div className="companyEachDetails boxcenter">
          <p className="userCompanyEachDetailHeading">Branch Address :</p>
          <p className="userCompanyEachDetailContent"></p>
        </div>
        <div className="companyEachDetails boxcenter">
          <p className="userCompanyEachDetailHeading">Branch Location :</p>
          <p className="userCompanyEachDetailContent"></p>
        </div>
      </div>
    </div>
  );
}

export default UserCompanyPage;
