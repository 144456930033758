import React from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import InputBox from "../../InputBox/InputBox";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import "./AddVehicleModal.scss";
import { CREATE_VEHICLE_MANAGEMENT } from "../../../../gql/addVehicles";

function AddVehicleModal({ onClose }) {
  const dispatch = useDispatch();

  const [createVehicleManagement] = useMutation(CREATE_VEHICLE_MANAGEMENT);

  const initialValues = {
    Vehicle_No: "",
  };

  const validate = (values) => {
    // console.log("validate", values);

    let errors = {};

    if (!values.Vehicle_No) {
      errors.Vehicle_No = "Required";
    }
    return errors;
  };

  const onSubmit = (values) => {
    // console.log("onSubmit", values);
    addVehicle(values);
  };

  const handleformikdata = (Value, id) => {
    let data = Value;
    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);
  };
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  async function addVehicle(values) {
    await createVehicleManagement({
      variables: {
        createInput: values,
      },
    })
      .then(() => {
        dispatch({ type: "set_loadData", payload: true });
        toast.success("Vehicle Added");
        onClose();
      })
      .catch((err) => toast.error(err.message));
  }

  return (
    <div className="addVehicleModal">
      <form onSubmit={formik.handleSubmit}>
        <div className="addVehicleModalContentDiv boxcenter">
          <div className="addVehicleModalHeaderDiv">
            <p className="addVehicleModalHeader">Vehicle Number </p>
          </div>
          :
          <InputBox
            className={"filterInputBox"}
            type="text"
            name="Vehicle_No"
            handleInputChange={formik.handleChange}
            handleformikdata={handleformikdata}
            uppercase={true}
            maxNo={10}
            autoComplete={"off"}
            err={formik.errors.Vehicle_No}
          />
        </div>
        <PortalFooter>
          <Button text="Add" className={"acceptBtn"} type="submit" />
          <Button text="Cancel" className={"rejectBtn"} handleClick={onClose} />
        </PortalFooter>
      </form>
    </div>
  );
}

export default AddVehicleModal;
