import React from "react";
import "./VehicleNo.scss";

function VehicleNo({ vehicleNo }) {
  return (
    <p className="vehicalno">
      {vehicleNo}
    </p>
  );
}

export default VehicleNo;
