import "./TermsAndConditions.scss";
import LandingPageTopNavBar from "../LandingPage/LandingPageTopNavBar/LandingPageTopNavBar";
import LandingPageNavBar from "../LandingPage/LandingPageNavBar/LandingPageNavBar";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import Footer from "../LandingPage/Footer/Footer";
import Spinner from "../../Components/Common/Spinner/Spinner";
import { GET_TERMS_AND_CONDITIONS } from "../../gql/termCondition";

const RenderListContainer = ({ data, title, className }) => {
  return (
    <div className={className}>
      <h2>{title}</h2>
      {data.map((term, index) => (
        <div key={index} className="termBox">
          <h3>
            {index + 1}. {term.Title}
          </h3>
          <p>{term.Description}</p>
        </div>
      ))}
    </div>
  );
};

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_TERMS_AND_CONDITIONS);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <LandingPageTopNavBar />
      <LandingPageNavBar />

      <div style={{ width: "90%", margin: "auto" }}>
        <p style={{ fontSize: 25, fontWeight: "bold" }}>Terms and Conditions for Ease My Transport Pvt Ltd</p>
        <p style={{ fontSize: 20, fontWeight: "bold" }}>Effective Date: 01-04-2024</p>
        <p style={{ fontSize: 20, fontWeight: "bold" }}>Welcome to Ease My Transport Pvt Ltd</p>
        <p style={{ fontSize: 18 }}>
          Ease My Transport Pvt Ltd ("we," "our," or "the Company") operates an online platform ("Platform") where users
          can post loads and trucks. By accessing or using our Platform, you agree to comply with and be bound by the
          following terms and conditions.
        </p>

        {data?.GetAllTermsAndConditionsByTypeOfAccount?.map(({ id, Title, Description, Points, List }, index) => (
          <div key={id}>
            <p style={{ fontSize: 20, fontWeight: "bold" }}>
              {index + 1}. {Title}
            </p>

            {!!Description && <p style={{ fontSize: 18 }}>{Description}</p>}

            <div>
              {Points.map((point) => (
                <div key={point.Serial_No} style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                  <text style={{ fontSize: 18 }}>
                    <b>• {point.Title} :</b> <text style={{ fontSize: 18 }}>{point.Description}</text>
                  </text>
                </div>
              ))}
            </div>

            {!!List.length && (
              <div>
                {List.map((list) => (
                  <div key={list}>
                    <p style={{ fontSize: 18 }}> • {list}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
