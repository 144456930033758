import React from "react";
import MyProfileRoutes from "../../../Allroutes/MyProfileRoutes";
import "./ProfilePage.scss";
import ProfilePageDashboard from "./ProfilePageDashboard/ProfilePageDashboard";

function ProfilePage() {
 
  return (
    <div className="profilePage">
      <ProfilePageDashboard />
      <MyProfileRoutes />
    </div>
  );
}

export default ProfilePage;
