import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { NumberFormatter } from "../../../../CommonFunction/NumberFormatter/NumberFormatter";
import "./Quote.scss";
import { BID_DETAILS_BY_LOAD_ID_QUERY_LP } from "../../../../gql/getBidDetailsForSP";

const Quote = ({ loadId, Expected_Price, Target_Price, Load_Post_Type }) => {
  const [bidPrice, setBidPrice] = useState(0);
  const { data } = useQuery(BID_DETAILS_BY_LOAD_ID_QUERY_LP, {
    variables: {
      createInput: {
        id: loadId,
        sort: 1,
        sortBy: "Price",
        limit: 5,
      },
    },
  });

  useEffect(() => {
    if (data && data.BidDetailsForSp[0]) {
      setBidPrice(data.BidDetailsForSp[0].Price);
    }
  }, [data]);

  return (
    <div className="Quote boxcenter">
      <div className="quote">
        <div className="quoteprice boxcenter">
          {bidPrice === 0 ? (
            <p className="noBidText">No Bid Placed</p>
          ) : (
            <>
              <span className="l1">L1 &nbsp;</span> <span className="bidPrice">{NumberFormatter(bidPrice)}/-</span>
            </>
          )}
        </div>

        {Load_Post_Type === "Auction Load" && (
          <p className="liveCeilingprice">Expected Price : {NumberFormatter(Expected_Price)}/-</p>
        )}
        {Load_Post_Type === "Open Load" && (
          <p className="liveCeilingprice">Target Price : {NumberFormatter(Target_Price)}/-</p>
        )}
        {Load_Post_Type === "Fixed Load" && (
          <p className="liveCeilingprice">Fixed Price : {NumberFormatter(Target_Price)}/-</p>
        )}
      </div>
    </div>
  );
};

export default Quote;
