import { gql } from "@apollo/client";

export const Get_Aadhar_captcha = gql`
  query Get_Aadhar_captcha {
    Get_Aadhar_captcha {
      session_id
      image_captcha
    }
  }
`;

export const Aadhar_With_Verify_Otp = gql`
  mutation Aadhar_With_Verify_Otp($userInput: Kyc_Input) {
    Aadhar_With_Verify_Otp(UserInput: $userInput) {
      session_id
      otp
      image_captcha
    }
  }
`;
export const Aadhaar_Download = gql`
mutation Aadhaar_Download($aadhaarDownloadUserInput2: Kyc_Input) {
  Aadhaar_Download(UserInput: $aadhaarDownloadUserInput2) {
    aadhaar_photo
    aadhaar_location
    aadhaar_address
    aadhaar_post_code
    aadhaar_name
    aadhaar_dob
    aadhaar_email
    aadhaar_mobile
    message
  }
}
`;
