import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import OTPInput from "react-otp-input";
import { useFormik } from "formik";
import { useLazyQuery } from "@apollo/client";
import { OTP_REQUEST, OTP_VERIFY } from "../../../gql/otpRequest";
import { toast } from "react-toastify";
import SpinnerIndicator from "../SpinnerIndicator/SpinnerIndicator";
import ChangePassword from "./ChangePassword";
import { useTimer } from "../../../hooks";

function CheckOtp({ sessionId, loginType, setLogin, content }) {
  const [otp, setOtp] = useState("");
  const [resetPassState, setResetPass] = useState(false);
  const [newSessionId, setNewSessionId] = useState("");
  const { isTimerRunning, seconds, start: handleStartTimer, stop: handleStopTimer } = useTimer(59);
  const [otpRequest] = useLazyQuery(OTP_REQUEST, {
    fetchPolicy: "no-cache",
  });

  const [otpVerify, { data, loading, error }] = useLazyQuery(OTP_VERIFY, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    handleStartTimer();
  }, []);

  useEffect(() => {
    if (data) {
      setNewSessionId(data.verifyOtpForPassword.sessionId);
      setResetPass(true);
      handleStopTimer();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const initialValues = { otp: "", sessionId: sessionId };

  const validate = (values) => {
    const errors = {};

    return errors;
  };

  const onSubmit = (values) => {
    console.log("inn");
    if (otp.length === 4) {
      otpVerify({
        variables: {
          otp: +otp,
          sessionId: sessionId,
        },
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  return (
    <>
      {resetPassState ? (
        <ChangePassword sessionId={newSessionId} setLogin={setLogin} loginType={loginType} />
      ) : (
        <div className="checkOtpDiv">
          <p className="checkOtpHeading">Enter 4 Digit OTP Code</p>
          <p className="checkOtpSubHeading">Enter the 4 digits code that you recceived on {content}.</p>
          <div className="otpDiv">
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="enterOtpDiv">
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  shouldAutoFocus={true}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              <div className="resendOtpTextDiv">
                {isTimerRunning ? (
                  <p className="resendOtpText">
                    Resend OTP in <span className="highlightSecText">{seconds}</span> seconds
                  </p>
                ) : (
                  <p className="resendOtpText">
                    Dont receive the OTP?{" "}
                    <span
                      className="highlightText"
                      onClick={() => {
                        otpRequest({
                          variables: {
                            emailOrNumber: content,
                            accType: loginType,
                          },
                        });
                        handleStartTimer();
                      }}
                    >
                      RESEND OTP
                    </span>
                  </p>
                )}
              </div>

              <div className="submitBtnDiv">
                <Button
                  className={otp.length !== 4 || loading ? "disabledBtnBank" : "acceptBtn"}
                  type={"submit"}
                  text={loading ? <SpinnerIndicator /> : "Verify & Proceed"}
                  disabled={loading || otp.length !== 4}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default CheckOtp;
