import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import {
  formikinitialvaluesforuser,
  formikValidation,
  replaceUnderscore,
} from "../../../../../../../CommonFunction/common";
import InputContentEditable from "./InputContentEditable";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import FileUploadBox from "../../../../../../../Components/LpComponents/FileUploadBox/FileUploadBox";
import InputBox from "../../../../../../../Components/Common/InputBox/InputBox";
import CustomInputMask from "../../../../../../../Components/Common/CustomInputMask/CustomInputMask";
import { PINCODE_QUERY } from "../../../../../../../gql/getPincode";

function UserProfileSections({ data, isLockBank, fielddata, name, message, Is_Lock, edit, hancan, formData, index }) {
  const [fields, setfields] = useState(data.fields);
  const [pincode, setpincode] = useState(0);
  const [pincodedata, setpincodedata] = useState();
  const [files, setFiles] = useState({});
  const dispatch = useDispatch();
  const dispatchRef = useRef(null);
  const [prevFormData, setPrevFormData] = useState({});
  const { fielddata: fielddataRedux } = useSelector((store) => store.editProfile);
  const { data: pincodeQueryData, error } = useQuery(PINCODE_QUERY, {
    variables: { pincode: +pincode },
    skip: String(pincode).length !== 6,
  });

  useEffect(() => {
    setpincodedata(pincodeQueryData);
  }, [pincodeQueryData]);

  const addvalues = (places) => {
    let obj = [];
    let p = places || [];
    let areas = [];
    for (var c = 0; c < p.length; c++) {
      areas = [...areas, { lable: p[c].Name, value: p[c].Name }];
    }
    for (var a = 0; a < fields.length; a++) {
      if (fields[a].setvalue === "Area") {
        obj = [
          ...obj,
          {
            ...fields[a],
            dropdowncontent: areas,
          },
        ];
      } else {
        obj = [...obj, fields[a]];
      }
      setfields(obj);
    }
  };

  useEffect(() => {
    if (formData) {
      let arr = [];
      for (var a = 0; a < fields.length; a++) {
        arr.push({ [fields[a].name]: formData[fields[a].name] });
        formik.setFieldValue(fields[a].name, formData[fields[a].name]);
      }
      let obj = {};
      for (var l = 0; l < arr.length; l++) {
        let temp = arr[l];
        for (var key in temp) {
          obj[key] = temp[key];
        }
      }
    }
  }, [formData]);

  const handleformikdata = (Value, id) => {
    let data = Value;
    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);

    if (id === "Orgnaization_Type") {
      if (data === "Individual") {
        const { Business_Details, Personal_Details } = fielddataRedux;

        dispatch({
          type: "editProfile",
          payload: {
            key: "Business_Details",
            data: {
              ...Business_Details,
              Business_Name: Personal_Details.Proprietor_Name,
              Business_Address: Personal_Details.Residential_Address,
              Business_Address_2: Personal_Details.Residential_Address_2,
              Business_Pincode: Personal_Details.Residential_Pincode,
              Business_State: Personal_Details.Residential_State,
              Business_City: Personal_Details.Residential_City,
              Business_Pan: Personal_Details.Pan_Number,
              Orgnaization_Type: data,
            },
          },
        });

        setPrevFormData((prev) => ({ ...prev, ...formik.values }));
      } else {
        dispatch({
          type: "editProfile",
          payload: {
            key: "Business_Details",
            data: { ...prevFormData, Orgnaization_Type: data },
          },
        });
      }
    }

    if (dispatchRef.current) {
      clearTimeout(dispatchRef.current);
    }

    dispatchRef.current = setTimeout(() => {
      dispatch({
        type: "editProfileData",
        payload: {
          key: name,
          data: {
            name: id,
            value: data,
          },
        },
      });
    }, 700);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.Business_Pincode) {
      errors.Business_Pincode = "Required";
    } else if (!values.Residential_Pincode) {
      errors.Residential_Pincode = "Required";
    } else if (!/^\d{6}$/.test(values.Business_Pincode)) {
      errors.Business_Pincode = "Invalid pincode";
    }
  };

  const formik = useFormik({
    initialValues: formikinitialvaluesforuser(fields),
    validationSchema: formikValidation(fields),
    validate,
    onSubmit: (values, { resetForm }) => {},
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.errors) {
      dispatch({
        type: "errordata",
        payload: { data: formik.errors, key: name },
      });
    }
  }, [formik.errors]);

  const fields1 = fields.slice(0, Math.ceil(fields.length / 2));
  const fields2 = fields.slice(Math.ceil(fields.length / 2), fields.length);
  const f1 = fields.slice(0, 4);
  const f2 = fields.slice(4, 8);
  const f4 = fields.slice(8, fields.length);

  useEffect(() => {
    const fetchPincodeDetails = () => {
      if (pincodedata && pincodedata.pincode && pincodedata.pincode.length > 0) {
        addvalues(pincodedata.pincode);
        const city = pincodedata.pincode[0].District;
        const state = pincodedata.pincode[0].State;
        formik.setFieldValue("Business_City", city);
        formik.setFieldValue("Business_State", state);
        dispatch({
          type: "editProfileData",
          payload: {
            key: name,
            data: { name: "Business_City", value: city },
          },
        });
        dispatch({
          type: "editProfileData",
          payload: {
            key: name,
            data: { name: "Business_State", value: state },
          },
        });
      }
    };

    setpincode(formik.values.Business_Pincode);

    if (/^\d{6}$/.test(formik.values.Business_Pincode)) {
      fetchPincodeDetails();
    }
  }, [formik.values.Business_Pincode, pincodedata, error]);

  useEffect(() => {
    const fetchPincodeDetails = () => {
      if (pincodedata && pincodedata.pincode && pincodedata.pincode.length > 0) {
        addvalues(pincodedata.pincode);
        const city = pincodedata.pincode[0].District;
        const state = pincodedata.pincode[0].State;
        formik.setFieldValue("Residential_City", city);
        formik.setFieldValue("Residential_State", state);
        dispatch({
          type: "editProfileData",
          payload: {
            key: name,
            data: { name: "Residential_City", value: city },
          },
        });
        dispatch({
          type: "editProfileData",
          payload: {
            key: name,
            data: { name: "Residential_State", value: state },
          },
        });
      }
    };

    setpincode(formik.values.Residential_Pincode);

    if (/^\d{6}$/.test(formik.values.Residential_Pincode)) {
      fetchPincodeDetails();
    }
  }, [formik.values.Residential_Pincode, pincodedata, error]);

  useEffect(() => {
    if (hancan) {
      delete fielddata._typename;
      formik.setValues(fielddata);
    }
  }, [hancan]);

  const handleInputEditable = (name) => {
    if (
      name === "Residential_City" ||
      name === "Residential_State" ||
      name === "Business_City" ||
      name === "Business_State"
    ) {
      return true;
    } else if (fielddataRedux?.Verified_Details?.Is_Aadhar_Verified && name === "Aadhar_Number") {
      return true;
    } else if (fielddataRedux?.Verified_Details?.Is_Pan_Verified && name === "Business_Pan") {
      return true;
    } else if (
      fielddataRedux?.Verified_Details?.Is_Bank_Verified &&
      (name === "Bank_Name" || name === "IFSC_Code" || name === "BankAccount_Type" || name === "Account_Number")
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className="userProfilePageContent">
        <div className="profileEdit">
          <div className="profileEditContent">
            <p className="profileHeading">{replaceUnderscore(name)}</p>
            <p className="contentText">{message}</p>
          </div>
        </div>
        {name !== "Documents" ? (
          <div className="contentBox">
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="profilFields">
                <div className="fieldsData">
                  {fields1.map((e) => {
                    return (
                      <>
                        {e.name === "BankAccount_Type" ? (
                          <>
                            <div className="detailsBox">
                              <div className="detailsBoxName">
                                <p className="contentText">
                                  Account Type
                                  {require ? "*" : null}
                                </p>
                              </div>

                              <div className="detailsBoxContent">
                                <div className="inputDiv">
                                  <InputBox
                                    formKey={name}
                                    name={e.name}
                                    id={e.name}
                                    placeholder={e.name}
                                    value={formik.values[e.name]}
                                    nolable={e.nolable}
                                    // label={e.label}
                                    // className={"inputwidthProfile no-spinners"}
                                    type={e.type}
                                    modalform={true}
                                    Iconinside={true}
                                    searchType={e.searchType}
                                    handleInputChange={formik.handleChange}
                                    handelformikdata={handleformikdata}
                                    handleBlur={formik.handleBlur}
                                    dropdowndata={e.dropdowncontent}
                                    hasApiCallback={e.hasApiCallback}
                                    step={e.step}
                                    disabled={edit}
                                    readOnly={handleInputEditable(e.name)}
                                    formtype={"addload"}
                                    require={e.require}
                                    foredit={edit}
                                    err={formik.errors[e.name] ? formik.errors[e.name] : false}
                                    touched={formik.touched[e.name] ? formik.touched[e.name] : false}
                                    isSearchable={e.isSearchable}
                                  />

                                  {edit ? null : <hr className="hrTagEdit" />}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {e.name === "Orgnaization_Type" ? (
                          <>
                            <div className="detailsBox">
                              <div className="detailsBoxName">
                                <p className="contentText">
                                  Orgnaization Type
                                  {require ? "*" : null}
                                </p>
                              </div>

                              <div className="detailsBoxContent">
                                <div className="inputDiv">
                                  <InputBox
                                    formKey={name}
                                    name={e.name}
                                    id={e.name}
                                    placeholder={e.name}
                                    value={formik.values[e.name]}
                                    nolable={e.nolable}
                                    // label={e.label}
                                    // className={"inputwidthProfile no-spinners"}
                                    type={e.type}
                                    modalform={true}
                                    Iconinside={true}
                                    searchType={e.searchType}
                                    handleInputChange={formik.handleChange}
                                    handelformikdata={handleformikdata}
                                    handleBlur={formik.handleBlur}
                                    dropdowndata={e.dropdowncontent}
                                    hasApiCallback={e.hasApiCallback}
                                    step={e.step}
                                    disabled={edit}
                                    readOnly={handleInputEditable(e.name)}
                                    formtype={"addload"}
                                    require={e.require}
                                    foredit={edit}
                                    err={formik.errors[e.name] ? formik.errors[e.name] : false}
                                    touched={formik.touched[e.name] ? formik.touched[e.name] : false}
                                    isSearchable={e.isSearchable}
                                  />

                                  {edit ? null : <hr className="hrTagEdit" />}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {e.name !== "Orgnaization_Type" && e.name !== "BankAccount_Type" ? (
                          <InputContentEditable
                            formKey={name}
                            key={e.name}
                            name={e.name}
                            id={e.name}
                            uppercase={e.uppercase}
                            placeholder={e.name}
                            value={formik.values[e.name]}
                            label={e.lable}
                            disabled={edit}
                            handleInputChange={formik.handleChange}
                            handleformikdata={handleformikdata}
                            MobileVerify={fielddata.Is_Mobile_Number_Verified}
                            EmailVerify={fielddata.Is_Email_Verified}
                            errors={formik.errors[e.name] ? formik.errors[e.name] : false}
                            touched={formik.touched[e.name] ? formik.touched[e.name] : false}
                            handleBlur={formik.handleBlur}
                            type={e.type}
                            min={e.min}
                            max={e.max}
                            data={fielddata}
                            readOnly={handleInputEditable(e.name)}
                            require={e.require}
                            autoCapital={e.autoCapital}
                          />
                        ) : null}
                      </>
                    );
                  })}
                </div>
                <div className="fieldsData">
                  {fields2.map((e) => {
                    return (
                      <>
                        {e.name === "Land_Line" ? (
                          <>
                            <div className="detailsBox">
                              <div className="detailsBoxName">
                                <p className="contentText">Land Line</p>
                              </div>

                              <div className="detailsBoxContent">
                                <div className="inputDiv">
                                  <CustomInputMask
                                    formKey={name}
                                    label={e.label}
                                    name={e.name}
                                    value={formik.values[e.name]}
                                    handleInputMaskChange={formik.handleChange}
                                    handleformikdata={handleformikdata}
                                    handleBlur={formik.handleBlur}
                                    disabled={edit}
                                    mask={"999-*99-*9999"}
                                    errors={formik.errors[e.name] ? formik.errors[e.name] : false}
                                    touched={formik.touched[e.name] ? formik.touched[e.name] : false}
                                  />

                                  {edit ? null : <hr className="hrTagEdit" />}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {e.name !== "Land_Line" ? (
                          <InputContentEditable
                            formKey={name}
                            key={e.name}
                            name={e.name}
                            id={e.name}
                            uppercase={e.uppercase}
                            placeholder={e.name}
                            value={formik.values[e.name]}
                            label={e.lable}
                            disabled={edit}
                            handleInputChange={formik.handleChange}
                            handleformikdata={handleformikdata}
                            verify={e.verify}
                            errors={formik.errors[e.name] ? formik.errors[e.name] : false}
                            touched={formik.touched[e.name] ? formik.touched[e.name] : false}
                            handleBlur={formik.handleBlur}
                            type={e.type}
                            min={e.min}
                            max={e.max}
                            readOnly={handleInputEditable(e.name)}
                            MobileVerify={fielddata.Is_Mobile_Number_Verified}
                            EmailVerify={fielddata.Is_Email_Verified}
                            require={e.require}
                            autoCapital={e.autoCapital}
                          />
                        ) : null}
                      </>
                    );
                  })}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="contentBox">
            <div className="profilFields">
              <div className="filessection">
                <FileUploadBox
                  UploadFields={f1}
                  allFieldsData={fielddataRedux["Documents"]}
                  format={"png"}
                  disabled={edit}
                  sendFilesFun={(e) => {
                    setFiles({
                      ...files,
                      files1: e,
                    });
                  }}
                  pageName="EditProfilePage"
                />
              </div>
              <div className="filessection">
                <FileUploadBox
                  UploadFields={f2}
                  allFieldsData={fielddataRedux["Documents"]}
                  format={"png"}
                  disabled={edit}
                  sendFilesFun={(e) => {
                    setFiles({
                      ...files,
                      files2: e,
                    });
                  }}
                  pageName="EditProfilePage"
                />
              </div>
              <div className="filessection">
                <FileUploadBox
                  UploadFields={f4}
                  allFieldsData={fielddataRedux["Documents"]}
                  format={"png"}
                  disabled={edit}
                  sendFilesFun={(e) => {
                    setFiles({
                      ...files,
                      files4: e,
                    });
                  }}
                  pageName="EditProfilePage"
                />
              </div>
            </div>
            <br></br>
          </div>
        )}
      </div>
    </>
  );
}

export default UserProfileSections;
