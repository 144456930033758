import { gql } from "@apollo/client";

export const GET_LOAD_SCHEMA = gql`
  query GetLoadSchema($id: ID!) {
    load(ID: $id) {
      _id
      From_City
      To_City
      Vehicle_Type
      Weight_Category
      Unloading_Pincode
      Unloading_Scope
      Unloading_State
      Unloading_Landmark
      Unloading_Name
      Unloading_City
      Unloading_Area
      Loading_Scope
      Loading_Time
      Loading_State
      Loading_Pincode
      Loading_Name
      Loading_Landmark
      Loading_Date
      Load_Manager
      Loading_Area
      Loading_By
      Loading_City
      Total_Weight
      Target_Price
      Rate_per
      Over_Dimension_Cargo
      Payment_Type
      Notes
      No_Of_Vehicels_Required
      Material_Dimension
      Material_Insurence
      Material_Category
      Load_Live_Duration
      Expected_Price
      Dangerous_Goods
      Exact_Content
      Auction_Time
      Auction_Date
      createdAt
      updatedAt
    }
  }
`;

export const GET_LOAD_SCHEMA_AC = gql`
  query GetallloadsByUnique_ID($uniqueId: String) {
    getallloadsByUnique_ID(Unique_Id: $uniqueId) {
      _id
      Unique_Id
      UserId
      Load_Post_Type
      Auction_Date
      Auction_Time
      Dangerous_Goods
      Exact_Content
      Expected_Price
      From_City
      Load_Live_Duration
      Load_Manager
      Loading_Area
      Loading_By
      Loading_City
      Loading_Date
      Loading_Landmark
      Loading_Name
      Loading_Pincode
      Loading_Scope
      Loading_State
      Loading_Time
      Loading_Address_Line_1
      Loading_Address_Line_2
      Material_Category
      Material_Dimension
      Material_Dimension_In
      Material_Insurence
      Material_Image
      Material_Image_2
      Material_Image_3
      No_Of_Vehicels_Required
      No_Of_Vehicels_Loading_Completed
      Notes
      Over_Dimension_Cargo
      Payment_Type
      Rate_per
      Target_Price
      To_City
      Total_Weight
      Unloading_Area
      Unloading_City
      Unloading_Landmark
      Unloading_Name
      Unloading_Pincode
      Unloading_Scope
      Unloading_State
      Unloading_Address_Line_1
      Unloading_Address_Line_2
      Vehicle_Type
      Weight_Category
      Is_Live
      Is_Auction_Started
      Is_Archive
      createdAt
      updatedAt
    }
  }
`;
