import React from "react";
import { Routes, Route } from "react-router-dom";
import UserCompanyPage from "../Pages/LpPages/ProfilePage/ProfilePageDashboard/DashboardPages/UserCompanyPage/UserCompanyPage";
import UserManagementPage from "../Pages/LpPages/ProfilePage/ProfilePageDashboard/DashboardPages/UserManagementPage/UserManagementPage";
import UserProfilePage from "../Pages/LpPages/ProfilePage/ProfilePageDashboard/DashboardPages/UserProfilePage/UserProfilePage";
import VehicleManagementPage from "../Pages/SpPages/ProfilePage/ProfilePageDashboard/DashboardPages/VehicleManagementPage/VehicleManagementPage";
import Cookies from "js-cookie";

function MyProfileRoutes() {
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");

  return (
    <Routes>
      {accInfo.Type_Of_User === "Root_User" ? (
        <>
          <Route path="/" element={<UserProfilePage />} />
          <Route path="/user-management" element={<UserManagementPage />} />
          <Route path="/user-compnay" element={<UserCompanyPage />} />
          <Route
            path="/vehicle-management"
            element={<VehicleManagementPage />}
          />
        </>
      ) : (
        <>
          <Route path="/" element={<UserCompanyPage />} />
          <Route path="/user-compnay" element={<UserCompanyPage />} />
        </>
      )}
    </Routes>
  );
}

export default MyProfileRoutes;
