import React, { useEffect, useState } from "react";
import "./Id.scss";

const Id = ({ loadId, bookingNo, bookingDate, createdAt }) => {
  const timestamp = new Date(bookingDate);
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = timestamp.toLocaleDateString("en-US", options);
  const [isNewLoad, setIsNewLoad] = useState(false);

  const date1 = new Date(createdAt);
  const date2 = new Date();

  const differenceInMilliseconds = Math.abs(date1 - date2);
  const oneHourInMilliseconds = 60 * 60 * 1000;

  useEffect(() => {
    if (differenceInMilliseconds < oneHourInMilliseconds) {
      setIsNewLoad(true);
    }
  }, [date1, date2]);

  return (
    <>
      <div className="spBodyid ">
        {loadId ? (
          <>
            {isNewLoad && (
              <div className="newLoadDiv">
                <p className="newLoadText">New</p>
              </div>
            )}

            <p className="p">Load ID : {loadId}</p>
          </>
        ) : null}
        {bookingNo && bookingDate ? (
          <p className="p">
            Booking ID : {bookingNo} ({formattedDate})
          </p>
        ) : null}
      </div>
    </>
  );
};

export default Id;
