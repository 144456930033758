const data = {
  fielddata: {},
  selectedRow: [],
  selectedRows: [],
  errors: {},
  refresh: false,
};

export function EditProfileReducer(state = data, { type, payload }) {
  // console.log(state);
  switch (type) {
    case 'setProfileData': {
      return {
        ...state,
        fielddata: payload
      }
    }

    case "editProfile": {
      return {
        ...state,
        fielddata: {
          ...state.fielddata,
          [payload.key]: payload.data,
        },
      };
    }

    case 'editProfileData': {
      return {
        ...state,
        fielddata: {
          ...state.fielddata,
          [payload.key]: {
            ...state.fielddata[payload.key],
            [payload?.data?.name]: payload?.data?.value,
          }
        },
      };
    }

    case "ClearAddData": {
      state = {
        fielddata: {},
        selectedRows: [],
      };
      return state;
    }
    case "errordata": {
      return {
        ...state,
        errors: {
          ...state.errors,
          [payload.key]: payload.data,
        },
      };
    }
    case "Refetch": {
      // console.log(payload);
      state = {
        ...state,
        refresh: payload,
      };
      // console.log(state);
      // console.log("in refresh.............................................",payload)
      return state;
    }
    default: {
      return state;
    }
  }
}
