import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../Pages/SpPages/Layout/Layout";
import LoginPage from "../Pages/LoginPage/LoginPage";
import Completed from "../Pages/SpPages/Booking/Completed/Completed";
import Ongoing from "../Pages/SpPages/Booking/Ongoing/Ongoing";
import Resulted from "../Pages/SpPages/Loads/Resulted/Resulted";
import ProfilePage from "../Pages/SpPages/ProfilePage/ProfilePage";
import SignupPage from "../Pages/SignUpPage/SignupPage";
import AwardedPage from "../Pages/SpPages/Loads/Awarded/AwardedPage";
import Open from "../Pages/SpPages/Loads/Open/Open";
import PrivateRoute from "./PrivateRoute";
import AccountStatement from "../Pages/LpPages/Reports/AccountStatment/AccountStatement";
import LandingPage from "../Pages/LandingPage/LandingPage";
 import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../Pages/TermsAndConditions/TermsAndConditions";
import Analytics from "../Pages/LpPages/Reports/Analytics/Analytics";


const SpRoutes = () => {
  return (
    <Routes>
      <Route path={"/login"} element={<LoginPage />} />
      <Route path={"/signup"} element={<SignupPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/open"
        element={
          <PrivateRoute>
            <Layout spFliterBox={true} filters={true}>
              <Open />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/resulted"
        element={
          <PrivateRoute>
            <Layout spFliterBox={false} filters={true}>
              <Resulted />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/awarded"
        element={
          <PrivateRoute>
            <Layout spFliterBox={false} filters={true}>
              <AwardedPage />
            </Layout>
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/archive"
        element={
          <PrivateRoute>
            <Layout spFliterBox={false} filters={true}>
              <ArchiveSP />
            </Layout>
          </PrivateRoute>
        }
      /> */}

      <Route
        path="/ongoing"
        element={
          <PrivateRoute>
            <Layout filters={false}>
              <Ongoing />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/completed"
        element={
          <PrivateRoute>
            <Layout filters={false}>
              <Completed />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/accountstatment"
        element={
          <PrivateRoute>
            <Layout>
              <AccountStatement />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/analytics"
        element={
          <PrivateRoute>
            <Layout>
              <Analytics />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/profile-settings/*"
        element={
          <PrivateRoute>
            <Layout>
              <ProfilePage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/*"
        element={<Navigate to="/login" />} // Redirect to login page
      />
    </Routes>
  );
};

export default SpRoutes;
