import React from 'react'
import "./THead.scss"

function THead({children}) {
  return (
    <thead>
        {children}
    </thead>
  )
}

export default THead
