import { gql } from "@apollo/client";

export const EDIT_COMMITTED_VEHICLES_DATA = gql`
  mutation EditCommittedVehicles($editCommittedVehiclesId: ID!, $userInput: CommittedVehiclesInput!) {
    editCommittedVehicles(id: $editCommittedVehiclesId, userInput: $userInput) {
      id
      BidId
      UserId
      LoadId
      Unique_Id
      Vehicle_Number {
        _id
        Unique_Id
        UserId
        Vehicle_No
        createdAt
        updatedAt
      }
      Vehicle_Capacity
      Driver_Name
      POD_Collected_From_SP
      POD_Submitted_To_LP
      Freight_Amount
      Platform_Charges
      Current_Stage
      Driver_Number
      Reporting_Time
      Is_Confirmed
      Is_Accepted
      Is_Rejected
      Booking_Confirmed
      Booking_Confirmed_Time
      Vehicle_Reached_At_Loading_Point
      Vehicle_Reached_At_Loading_Point_Time
      Loading_Completed
      Loading_Completed_Time
      Advance_Processed
      Advance_Processed_Time
      Vehicle_Reach_At_Unloading_Point
      Vehicle_Reach_At_Unloading_Point_Time
      Unloading_Completed
      Unloading_Completed_Time
      Clear_Delevery_Confirmation
      Clear_Delevery_Confirmation_Time
      POD_Collected_From_SP_Time
      POD_Submitted_To_LP_Time
      Invoice_Number
      Eway_Bill_Number
      Eway_Bill_Created_Date
      Eway_Bill_Validate_Date
      Vehicle_Front_Img
      Vehicle_Back_Img
      Vehicle_Right_Img
      Vehicle_Left_Img
      Material_Img
      Entry_Gate_Img
      POD_Front_Copy
      POD_Back_Copy
      Invoice_Copy
      Final_Invoice_Copy
      Holding_Charges
      Hamali_Charges
      Extra_Running
      Challan_Demand
      Other_Charges
      Other_Charges_Reason
      Narration
      Sp_Claim_Raised
      Lp_Claim_Raised
      Sp_Claim_Cleared
      Lp_Claim_Cleared
      createdAt
      updatedAt
    }
  }
`;
