import React, { useEffect, useState } from "react";
import AddVehicleModal from "../../../../../../Components/Common/AllModals/AddVehicleModal/AddVehicleModal";
import Button from "../../../../../../Components/Common/Button/Button";
import Reactportal from "../../../../../../Components/LpComponents/Reactportal/Reactportal";
import { vehicleColumns } from "../../../../../../db/VehiclesTableData/vehicleColumns";
import ReactTable from "../../../../../../Components/Common/ReactTable/ReactTable";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import "./VehicleManagementPage.scss";
import { GET_VEHICLES_DATA } from "../../../../../../gql/getVehicles";
import TruckLoadingIndicator from "../../../../../../Components/Common/TruckLoadingIndicator/TruckLoadingIndicator";

function VehicleManagementPage() {
  const [addVehicleModal, setAddVehicleModal] = useState(false);
  const [vehiclesData, setVehiclesData] = useState([]);
  const { data, loading, refetch } = useQuery(GET_VEHICLES_DATA);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data.GetVehicleManagementDetailsByUserId) {
      setVehiclesData(data.GetVehicleManagementDetailsByUserId);
    }
  }, [data]);

  const isLoadData = useSelector((store) => store.loadData.loadData);
  // console.log("is", isLoadData)

  useEffect(() => {
    if (isLoadData) {
      refetch();
      dispatch({ type: "set_loadData", payload: false });
    }
  }, [isLoadData, refetch]);

  return (
    <div className="vehicleManagementPage">
      <p className="vehicleManagementHeading">Vehicle-Management</p>
      <div className="vehicleManagementContent">
        <Button text="+ Add Vehicle" className={"addLoadFormBtn"} handleClick={() => setAddVehicleModal(true)} />

        <Reactportal
          open={addVehicleModal}
          modalClassName="addUserModal"
          contentClassName="addUserModal-content"
          modalHeading={"Add Vehicle"}
          onClose={() => setAddVehicleModal(false)}
        >
          <AddVehicleModal onClose={() => setAddVehicleModal(false)} />
        </Reactportal>

        <p className="contentHeading">Directory</p>
        {loading ? (
          <TruckLoadingIndicator />
        ) : vehiclesData.length <= 0 ? (
          "No Vehicles Added Yet!"
        ) : (
          <ReactTable DATA={vehiclesData} COLOUMNS={vehicleColumns} editVehicleModal={true} pagination={true} />
        )}
      </div>
    </div>
  );
}

export default VehicleManagementPage;
