import React from "react";
import "./DateToString.scss";
import FormattedTime from "../FormattedTime/FormattedTime";

function DateToString({ date, time }) { 
  const dateStr = new Date(+date);
  const dateObj = new Date(dateStr);
  const day = dateObj.getDate();
  let suffix = "th";

  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  const formattedDate = `${day}${suffix} ${dateObj.toLocaleString("default", {
    month: "long",
  })}`;

  return (
    <div className="dateToStringDiv">
      {formattedDate} (<FormattedTime time={time} />)
    </div>
  );
}

export default DateToString;
