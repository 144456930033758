import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import DateToString from "../../DateToString/DateToString";
import TuckLoadingIndicator from "../../TruckLoadingIndicator/TruckLoadingIndicator";
import "./LoadDetails.scss";
import ShowNoDataFound from "../../ShowNoDataFound/ShowNoDataFound";
import { GET_LOAD_SCHEMA, GET_LOAD_SCHEMA_AC } from "../../../../gql/getloadDetails";

function LoadDetails({ loadId, ACmodal, Notes, BidNote }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);

  const { loading: ogLoding, data: ogData } = useQuery(GET_LOAD_SCHEMA, {
    variables: { id: loadId },
  });

  const { loading: acLoading, data: acData } = useQuery(GET_LOAD_SCHEMA_AC, {
    variables: { uniqueId: loadId },
  });

  useEffect(() => {
    if (ACmodal && acData) {
      setData(acData && acData.getallloadsByUnique_ID[0]);
      setLoading(acLoading);
    }
  }, [acData]);

  useEffect(() => {
    if (!ACmodal && ogData) {
      setData(ogData && ogData.load);
      setLoading(ogLoding);
    }
  }, [ogData]);

  if (data === undefined) {
    return <ShowNoDataFound msg={"No Data Found"} />;
  }
  return (
    <>
      {loading ? (
        <TuckLoadingIndicator />
      ) : (
        <div className="loadDeatailsModal">
          <div className="flex">
            <div className="loadDetailsFlex">
              <p className="modalHeading">Load Details</p>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">From-To :</p>
                <p className="loadDetailsData">
                  {data.From_City} - {data.To_City}
                </p>
              </div>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">Loading Point :</p>
                <p className="loadDetailsData">
                  {data.Loading_Area}, {data.Loading_Landmark}, {data.Loading_City}, {data.Loading_State},{" "}
                  {data.Loading_Pincode}.
                </p>
              </div>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">Unloading Point :</p>
                <p className="loadDetailsData">
                  {data.Unloading_Area}, {data.Unloading_Landmark}, {data.Unloading_City}, {data.Unloading_State},{" "}
                  {data.Unloading_Pincode}.
                </p>
              </div>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">Loading :</p>
                <p className="loadDetailsData">
                  <DateToString date={data.Loading_Date} time={data.Loading_Time} />
                </p>
              </div>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">Payment Type :</p>
                <p className="loadDetailsData">{data.Payment_Type}</p>
              </div>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">Loading By :</p>
                <p className="loadDetailsData">{data.Loading_By}</p>
              </div>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">Loading Scope :</p>
                <p className="loadDetailsData">{data.Loading_Scope}</p>
              </div>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">Unloading Scope :</p>
                <p className="loadDetailsData">{data.Unloading_Scope}</p>
              </div>
            </div>
            <div className="vehicleDetailsFlex">
              <p className="modalHeading">Vehicle Required</p>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">Vehicle No :</p>
                <p className="loadDetailsData">{data.No_Of_Vehicels_Required}</p>
              </div>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">Vehicle Type :</p>
                <p className="loadDetailsData">
                  {data.Vehicle_Type} ({data.Weight_Category.join(", ")})
                </p>
              </div>

              <p className="modalHeading">Materials Details</p>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">Material Weight :</p>
                <p className="loadDetailsData">{data.Total_Weight} MT</p>
              </div>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">Material Category :</p>
                <p className="loadDetailsData">
                  {data.Material_Category} ({data.Exact_Content})
                </p>
              </div>
              <div className="eachLoadDetailsDiv boxcenter">
                <p className="contentText boxcenter">Material Insurance :</p>
                <p className="loadDetailsData">{data.Material_Insurence}</p>
              </div>

              {data.Material_Dimension && (
                <div className="eachLoadDetailsDiv boxcenter">
                  <p className="contentText boxcenter">Dimension (L*W*H) : </p>
                  <span>{data.Material_Dimension}</span>
                </div>
              )}

              {data.Over_Dimension_Cargo === "Yes" ? <p className="contentText odc">Over Dimentional Cargo</p> : ""}
              {data.Dangerous_Goods === "Yes" ? <p className="contentText dg">Dangerous Goods</p> : ""}
            </div>
          </div>
          {Notes && (
            <div className="notesBox">
              <p className="modalHeading">Notes :</p>
              <p className="contentText">
                <span>{Notes}</span>
              </p>
            </div>
          )}
          {BidNote && (
            <div className="bidNoteBox">
              <p className="modalHeading">Bid Note :</p>
              <p className="contentText">
                <span>{BidNote}</span>
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default LoadDetails;
