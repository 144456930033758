import React, { useState } from "react";
import Reactportal from "../../Reactportal/Reactportal";
import ShowDocsModal from "../../../Common/AllModals/ShowDocsModal/ShowDocsModal";
import "./MaterialDetails.scss";

const MaterialDetails = ({
  totalweight,
  matcat,
  exactcontent,
  materialinsurence,
  Material_Dimension,
  ODC,
  Dangerous_Goods,
  noofvechile,
  vehicletype,
  weightcategory,
  Material_Dimension_In,
  Material_Image,
  Material_Image_2,
  Material_Image_3,
}) => {
  let dimArr =
    Material_Dimension &&
    Material_Dimension_In &&
    Material_Dimension_In.split(" ")[1];

  const [materialImages, setMaterialImages] = useState(false);

  return (
    <div className="Materialdetails">
      <div>
        <div className="headingDiv">
          <h5>Material Details</h5>
          {(Material_Image || Material_Image_2 || Material_Image_3) && (
            <p
              className="viewMaterialImage"
              onClick={() => setMaterialImages(true)}
            >
              (Material Images)
            </p>
          )}
        </div>
        <li>Wt : {totalweight} MT</li>
        <li>
          {exactcontent} ({matcat})
        </li>

        <li>Material Insurance : {materialinsurence}</li>
        {Material_Dimension && (
          <li>
            Dimension (L×W×H) : {Material_Dimension} {dimArr}
          </li>
        )}

        {ODC === "Yes" ? <li className="odc">Over Dimentional Cargo</li> : ""}
        {Dangerous_Goods === "Yes" ? (
          <li className="dg">Dangerous Goods</li>
        ) : (
          ""
        )}
        <br></br>
        <h5>Vehicle Required</h5>
        <p className="vehicleDetailsHeading">No of vehicles : {noofvechile}</p>
        <p className="vehicleDetailsHeading">
          Vehicle Type : {vehicletype} <br></br>({weightcategory.join(", ")})
        </p>
      </div>

      <Reactportal
        open={materialImages}
        modalHeading={"Documents"}
        modalClassName={"loadDetailModal"}
        contentClassName={"loadDetailModal-content"}
        onClose={() => setMaterialImages(false)}
      >
        <ShowDocsModal
          documentsArr={[
            {
              name: "Material Image 1",
              url: Material_Image,
            },
            {
              name: "Material Image 2",
              url: Material_Image_2,
            },
            {
              name: "Material Image 3",
              url: Material_Image_3,
            },
          ]}
        />
      </Reactportal>
    </div>
  );
};

export default MaterialDetails;
