const formatDateDDMMYYYY = (date) => {
  const formatDate1 = date.toString();
  const formatDate2 = new Date(formatDate1);
  const formattedDate3 = formatDate2.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return formattedDate3;
};

export { formatDateDDMMYYYY };
