import React from "react";
import { useSelector } from "react-redux";
import PageFooter from "../../../Components/Common/PageFooter/PageFooter";
import TopNavbar from "../../../Components/Common/TopNavbar/TopNavbar";
import HeaderRowTwo from "../../../Components/Common/HeaderRowTwo/HeaderRowTwo";
import Header from "../../../Components/Common/Header/Header";
import "./Layout.scss";

function Layout({ filters, children }) {
  const isOpen = useSelector((store) => store.sidebarOpen.sidebarOpen);

  return (
    <div className="mainDiv">
      <Header />
      <TopNavbar filters={filters}/>
      <HeaderRowTwo />
      <div className={filters && isOpen ? "adjustPage" : "body"}>{children}</div>
      <PageFooter />
    </div>
  );
}

export default Layout;
