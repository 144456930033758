import React from "react";

const NumberFormatter = ({ number }) => {
  // Format the number into Indian Rupee and comma-separated format
  const formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  }).format(number);

  return <p className="formattedNumberText">{formattedNumber}</p>;
};

export default NumberFormatter;
