import React from "react";

const PortalFooter = ({ children}) => {
  return (
    <div className="lpModalFooter">
      {children}
    </div>
  );
};

export default PortalFooter;
