import { gql } from "@apollo/client";

export const GET_TERMS_AND_CONDITIONS = gql`
  query GetAllTermsAndConditionsByTypeOfAccount {
    GetAllTermsAndConditionsByTypeOfAccount {
      id
      Unique_Id
      Title
      Description
      Serial_No
      List
      Points {
        Title
        Description
        Serial_No
      }
      createdAt
      updatedAt
    }
  }
`;
