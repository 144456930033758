import "./TimeTracking.scss";

const TimeTracking = () => {
  return (
    <div className="TimeTracking_MainDiv">
      <p>REAL TIME TRACKING</p>
      <img src="./TimeTrack.jpg" alt="" />
    </div>
  );
};

export default TimeTracking;
