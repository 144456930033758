import React, { useEffect, useState } from "react";
import Table from "../../LpComponents/Table/Table";
import THead from "../../LpComponents/Table/TableComponents/THead/THead";
import TBody from "../../LpComponents/Table/TableComponents/TBody/TBody";
import TR from "../../LpComponents/Table/TableComponents/TR/TR";
import TH from "../../LpComponents/Table/TableComponents/TH/TH";
import { useQuery } from "@apollo/client";
import TD from "../../LpComponents/Table/TableComponents/TD/TD";
import socket from "../../../CommonFunction/Socket/socketClient";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import { getTotal } from "../../../CommonFunction/GetTotal/GetTotal";
import { NumberFormatter } from "../../../CommonFunction/NumberFormatter/NumberFormatter";
import "./CurrentTaskTable.scss";
import { GET_CLAIMS_BY_COMMITTEDVEHICLESID } from "../../../gql/getClaimsBYCommitedVehicles";

function CurrentTaskTable({ CommittedVehicleId, className }) {
  const { loading, data, error, refetch } = useQuery(GET_CLAIMS_BY_COMMITTEDVEHICLESID, {
    variables: {
      findByInputs: {
        CommittedVechicleID: CommittedVehicleId,
      },
    },
  });

  const [LpClaims, setLpClaims] = useState([]);
  const [SpClaims, setSpClaims] = useState([]);

  useEffect(() => {
    if (data && data.ClaimsByCommittedVehiclesID) {
      setLpClaims(data.ClaimsByCommittedVehiclesID.Lp);
      setSpClaims(data.ClaimsByCommittedVehiclesID.Sp);
    }
  }, [data]);

  useEffect(() => {
    socket.on("CurrentTask_Update", (id) => {
      // console.log("id", id);
      refetch();
    });
  }, []);

  // console.log(data, "dataaa", CommittedVehicleId);

  return (
    <div className={className ? className : "currentTasktable"}>
      <div className="eachClaimDiv">
        <p className="claimsHeading">LP Claims</p>
        {LpClaims.length === 0 ? (
          <p className="noTableMessage">No Charges Claimed from LP side yet.</p>
        ) : (
          <div className="tableDiv">
            <Table className="claimTable">
              <THead>
                <TR>
                  <TH className="claimTh">Claim</TH>
                  <TH className="claimTh">Amount</TH>
                  <TH className="claimTh">Status</TH>
                  <TH className="claimTh">Reason</TH>
                </TR>
              </THead>
              <TBody>
                {LpClaims?.map((el, i) => (
                  <TR key={el.id}>
                    <TD>Claim {i + 1}</TD>
                    <TD>{NumberFormatter(getTotal([el.Shortage_Charges, el.Damage_Charges]))} /-</TD>
                    {el.Is_Accepted ? (
                      <TD>
                        <p className="acceptedClaimText">Accepted</p>
                      </TD>
                    ) : el.Is_Rejected ? (
                      <TD>
                        <p className="rejectedClaimText">Rejected</p>
                      </TD>
                    ) : (
                      <TD>-</TD>
                    )}
                    {el.Reject_Reason ? <TD>{el.Reject_Reason}</TD> : <TD>-</TD>}
                  </TR>
                ))}
              </TBody>
            </Table>
          </div>
        )}
      </div>
      <div>
        <HorizontalLine VrLine={true} />
      </div>

      <div className="eachClaimDiv">
        <p className="claimsHeading">SP Claims</p>
        {SpClaims.length === 0 ? (
          <p className="noTableMessage">No Charges Claimed from SP side yet.</p>
        ) : (
          <div className="tableDiv">
            <Table className="claimTable">
              <THead>
                <TR>
                  <TH className="claimTh">Claim</TH>
                  <TH className="claimTh">Amount</TH>
                  <TH className="claimTh">Status</TH>
                  <TH className="claimTh">Reason</TH>
                </TR>
              </THead>
              <TBody>
                {SpClaims?.map((el, i) => (
                  <TR key={el.id}>
                    <TD>Claim {i + 1}</TD>
                    <TD>
                      {NumberFormatter(
                        getTotal([
                          el.Holding_Charges,
                          el.Hamali_Charges,
                          el.Extra_Running,
                          el.Challan_Charges,
                          el.Other_Charges,
                        ])
                      )}{" "}
                      /-
                    </TD>
                    {el.Is_Accepted ? (
                      <TD>
                        <p className="acceptedClaimText">Accepted</p>
                      </TD>
                    ) : el.Is_Rejected ? (
                      <TD>
                        <p className="rejectedClaimText">Rejected</p>
                      </TD>
                    ) : (
                      <TD>-</TD>
                    )}
                    {el.Reject_Reason ? <TD>{el.Reject_Reason}</TD> : <TD>-</TD>}
                  </TR>
                ))}
              </TBody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
}

export default CurrentTaskTable;
