import React, { useEffect, useState } from "react";
import Table from "../../../../../../Components/LpComponents/Table/Table";
import TBody from "../../../../../../Components/LpComponents/Table/TableComponents/TBody/TBody";
import TD from "../../../../../../Components/LpComponents/Table/TableComponents/TD/TD";
import TH from "../../../../../../Components/LpComponents/Table/TableComponents/TH/TH";
import THead from "../../../../../../Components/LpComponents/Table/TableComponents/THead/THead";
import TR from "../../../../../../Components/LpComponents/Table/TableComponents/TR/TR";
import "./AccountStatementTable.scss";
import DateFormatForAccountStatement from "./DateFormatForAccountStatement";
import { useDispatch } from "react-redux";
import { BsSearch } from "react-icons/bs";
import Reactportal from "../../../../../../Components/LpComponents/Reactportal/Reactportal";
import LoadDetails from "../../../../../../Components/Common/AllModals/LoadDetails/LoadDetails";
import BookingDetailsModal from "../../../../../../Components/Common/AllModals/BookingDetailsModal/BookingDetailsModal";

function AccountStatementTable({ accountStatementData }) {
  const [searchText, setSearchText] = useState("");
  const [selectedDocNo, setSelectedDocNo] = useState(null);
  const [bookingDetailsModal, setBookingDetailsModal] = useState(false);
  const [loadDetailsModal, setLoadDetailsModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (searchText) {
      dispatch({
        type: "search",
        payload: { data: searchText, key: "SearchDescription" },
      });
    }
  }, [searchText]);

  const openModal = (docNo) => {
    if (docNo.charAt(0) === "L") {
      setLoadDetailsModal(true);
    } else if (docNo.charAt(0) === "B") {
      setBookingDetailsModal(true);
    }
    setSelectedDocNo(docNo);
  };

  return (
    <>
      <Table>
        <THead>
          <TR>
            <TH className="alignleft headactable">Date</TH>
            <TH className="alignleft descdiv headactable">
              <div className="desctext">Description</div>
              <div className="searchContainer ">
                <input
                  className={"searchInput"}
                  label={"Search here"}
                  name={"Search_desc"}
                  id={"Search_desc"}
                  placeholder={"Search here"}
                  type={"search"}
                  value={searchText}
                  onChange={(e) => {
                    // console.log(e.target.value)
                    setSearchText(e.target.value);
                  }}
                />
                <BsSearch className="searchIcon" />
              </div>
            </TH>

            <TH className="alignleft headactable">Reference No</TH>
            <TH className="alignright headactable">Amount(₹)</TH>
            <TH className="alignright headactable">Balance(₹)</TH>
          </TR>
        </THead>
        <TBody>
          {accountStatementData?.map((el, index) => {
            return (
              <TR className="oddRow" key={el.id}>
                <TD className="alignleft">{<DateFormatForAccountStatement originalDate={el.Date} />}</TD>
                <TD className="alignleft">{el.Narration}</TD>

                <TD className="alignleft">
                  <div className="forLink" onClick={() => openModal(el.Doc_No)}>
                    {el.Doc_No}
                  </div>
                </TD>
                <Reactportal
                  open={bookingDetailsModal && selectedDocNo === el.Doc_No}
                  modalClassName="bookingDetailModal"
                  contentClassName="bookingDetailModal-content"
                  modalHeading={`Load Id : ${el.Doc_No}`}
                  onClose={() => {
                    setBookingDetailsModal(false);
                    setSelectedDocNo(null);
                  }}
                >
                  <BookingDetailsModal bookingId={el.Doc_No} key={el.Doc_No} ACmodal={true} />
                </Reactportal>

                <Reactportal
                  open={loadDetailsModal && selectedDocNo === el.Doc_No}
                  modalClassName="loadDetailModal"
                  contentClassName="loadDetailModal-content"
                  modalHeading={`Load Id : ${el.Doc_No}`}
                  onClose={() => {
                    setLoadDetailsModal(false);
                    setSelectedDocNo(null);
                  }}
                >
                  <LoadDetails loadId={el.Doc_No} key={el.Doc_No} ACmodal={true} />
                </Reactportal>
                <TD className="alignright">
                  {/* {el.Amount} */}
                  <div className="creditAmount">
                    {el.specificTransactions[0].Transaction_Type === "Debit" ? (
                      <>+{el.specificTransactions[0].Amount}</>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="debitAmount">
                    {el.specificTransactions[0].Transaction_Type === "Credit" ? (
                      <>-{el.specificTransactions[0].Amount}</>
                    ) : (
                      ""
                    )}
                  </div>
                </TD>
                <TD className="alignright">{el.closingBalance}</TD>
              </TR>
            );
          })}
        </TBody>
      </Table>
    </>
  );
}

export default AccountStatementTable;
