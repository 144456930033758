import { gql } from "@apollo/client";

export const SEARCH_CITIES = gql`
  query SearchCities($keyword: String!) {
    searchCities(keyword: $keyword) {
      id
      name
      value
      label
      createdAt
      updatedAt
    }
  }
`;

export const SEARCH_STATE = gql`
  query searchStates($searchStatesKeyword2: String!) {
    searchStates(keyword: $searchStatesKeyword2) {
      name
      label
      value
    }
  }
`;
