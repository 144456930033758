import { useQuery } from "@apollo/client";
import React from "react";
import { GET_VEHICLE_NO } from "../../../gql/getVehicles";

function GetVehicleNo({ vehicleNoId }) {
  const { data: getVehicleNoD } = useQuery(GET_VEHICLE_NO, {
    variables: {
      vehicleManagementId: vehicleNoId,
    },
  });

  return <>{getVehicleNoD ? getVehicleNoD.VehicleManagement.Vehicle_No : ""}</>;
}

export default GetVehicleNo;
