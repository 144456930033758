import React, { useEffect, useState } from "react";
import OngoingBox from "./Ongoing components/OngoingBox/OngoingBox";
import { useQuery } from "@apollo/client";
import TuckLoadingIndicator from "../../../../Components/Common/TruckLoadingIndicator/TruckLoadingIndicator";
import ShowNoDataFound from "../../../../Components/Common/ShowNoDataFound/ShowNoDataFound";
import socket from "../../../../CommonFunction/Socket/socketClient";
import { GET_ONGOING_BOOKINGS } from "../../../../gql/getOngoingDataLP";
import "./Ongoing.scss";

function Ongoing() {
  const [acceptedCommittedVehiclesData, setAcceptedCommittedVehiclesData] = useState([]);
  const { loading, data, refetch } = useQuery(GET_ONGOING_BOOKINGS);

  useEffect(() => {
    if (data && data.CommittedVehiclesAcceptedForLoadPartnerUserId) {
      const sortedData = [...data.CommittedVehiclesAcceptedForLoadPartnerUserId].sort(
        (a, b) => new Date(b.Booking_Confirmed_Time) - new Date(a.Booking_Confirmed_Time)
      );
      const filteredData = sortedData.filter((el) => el.Current_Stage !== "POD Submitted To LP");

      setAcceptedCommittedVehiclesData(filteredData);
    }
  }, [data]);

  useEffect(() => {
    socket.on("CurrentTask_Update", (id) => {
      refetch();
    });
  });

  return (
    <>
      {loading ? (
        <TuckLoadingIndicator />
      ) : acceptedCommittedVehiclesData.length === 0 ? (
        <ShowNoDataFound msg={"No Bookings Available"} />
      ) : (
        <div className="ongoingPage">
          {acceptedCommittedVehiclesData?.map((el) => (
            <OngoingBox key={el._id} data={el} />
          ))}
        </div>
      )}
    </>
  );
}

export default Ongoing;
