import { gql } from "@apollo/client";

export const EDIT_LOAD = gql`
  mutation EditLoad($id: ID!, $userInput: LoadInput!) {
    editLoad(id: $id, userInput: $userInput) {
      _id
      createdAt
      Weight_Category
      Unloading_State
      Vehicle_Type
      Unloading_Scope
      Unloading_Name
      Unloading_Pincode
      Unloading_City
      Unloading_Landmark
      Unloading_Area
      To_City
      Total_Weight
      Rate_per
      Target_Price
      No_Of_Vehicels_Required
      Over_Dimension_Cargo
      Payment_Type
      Notes
      Material_Dimension
      Material_Dimension_In
      Material_Insurence
      Loading_Time
      Material_Category
      Loading_State
      Loading_Scope
      Loading_Pincode
      Load_Live_Duration
      Loading_Address_Line_1
      Loading_Address_Line_2
      Unloading_Address_Line_1
      Unloading_Address_Line_2
      From_City
      Expected_Price
      Exact_Content
      Dangerous_Goods
      Auction_Time
      Auction_Date
      Loading_Area
      Load_Manager
      Loading_By
      Loading_City
      Loading_Name
      Loading_Landmark
      Loading_Date
      Material_Image
      Material_Image_2
      Material_Image_3
    }
  }
`;
