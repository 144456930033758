import React from "react";
import Card from "../../../../../../Components/Common/Card/Card";
import CardContent from "../../../../../../Components/SpComponents/CardComponents/CardContent/CardContent";
import CardFooter from "../../../../../../Components/SpComponents/CardComponents/CardFooter/CardFooter";
import CardHeader from "../../../../../../Components/SpComponents/CardComponents/CardHeader/CardHeader";
import FromAndTo from "../../../../../../Components/SpComponents/CardComponents/FromAndTo/FromAndTo";
import Id from "../../../../../../Components/SpComponents/CardComponents/Id/Id";
import LoadingDetails from "../../../../../../Components/SpComponents/CardComponents/LoadingDetails/LoadingDetails";
import LoadingPoint from "../../../../../../Components/SpComponents/CardComponents/LoadingPoint/LoadingPoint";
import Note from "../../../../../../Components/SpComponents/CardComponents/Note/Note";
import Pointofcontact from "../../../../../../Components/SpComponents/CardComponents/PointOfContact/PointOfContact";
import Typeofload from "../../../../../../Components/SpComponents/CardComponents/TypeOfLoad/TypeOfLoad";
import UnloadingPoint from "../../../../../../Components/SpComponents/CardComponents/UnloadingPoint/UnloadingPoint";
import VehicleDetails from "../../../../../../Components/SpComponents/CardComponents/VehicleDetails/MaterialDetails";
import Timer from "../../../../../../Components/Common/BiddingTimer/Timer";
import Quote from "../../../../../../Components/SpComponents/CardComponents/Quote/Quote";
import "./OpenCard.scss";
import AcceptBids from "../../../../../../Components/SpComponents/CardComponents/AcceptBids/AcceptBids";

function OpenCard({
  _id,
  loadId,
  Load_Post_Type,
  From_City,
  To_City,
  Total_Distance,
  Consignor_Area,
  Consignor_Landmark,
  Consignor_City,
  Consignor_State,
  Consignor_Pincode,
  Consignee_Area,
  Consignee_Landmark,
  Consignee_City,
  Consignee_State,
  Consignee_Pincode,
  No_Of_Vehicels_Required,
  Vehicle_Type,
  Weight_Category,
  Payment_Type,
  Material_Insurence,
  Loading_By,
  Loading_Scope,
  Unloading_Scope,
  Over_Dimension_Cargo,
  Total_Weight,
  Exact_Content,
  Loading_Date,
  Loading_time,
  Material_Category,
  Expected_Price,
  Dangerous_Goods,
  Material_Dimension,
  Material_Dimension_In,
  Load_Type,
  Rate_Per,
  Target_Price,
  Notes,
  Auction_Time,
  Auction_Date,
  Load_Live_Duration,
  Is_Live,
  Is_Auction_Started,
  Material_Image,
  Material_Image_2,
  Material_Image_3,
  BidDetails,
  createdAt,
}) {
  return (
    <Card>
      <div className="SpContainer">
        <CardHeader>
          <Id loadId={loadId} createdAt={createdAt} />
          <FromAndTo
            fromCity={From_City}
            toCity={To_City}
            totalDistance={Total_Distance}
          />
          <Typeofload Load_Post_Type={Load_Post_Type} Rate_Per={Rate_Per} />
        </CardHeader>
        <CardContent>
          <div className="detailssection">
            <LoadingPoint
              area={Consignor_Area}
              landmark={Consignor_Landmark}
              city={Consignor_City}
              state={Consignor_State}
              pincode={Consignor_Pincode}
            />
            <UnloadingPoint
              area={Consignee_Area}
              landmark={Consignee_Landmark}
              city={Consignee_City}
              state={Consignee_State}
              pincode={Consignee_Pincode}
            />
          </div>
          <div className="detailssection">
            <LoadingDetails
              fromcity={From_City}
              tocity={To_City}
              paymenttype={Payment_Type}
              loadingdate={Loading_Date}
              loadingtime={Loading_time}
              loadingby={Loading_By}
              loadingscope={Loading_Scope}
              unloadingscope={Unloading_Scope}
            />
          </div>
          <div className="detailssection">
            <VehicleDetails
              totalweight={Total_Weight}
              matcat={Material_Category}
              exactcontent={Exact_Content}
              totaldistance={Total_Distance}
              materialinsurence={Material_Insurence}
              Material_Dimension={Material_Dimension}
              Dangerous_Goods={Dangerous_Goods}
              ODC={Over_Dimension_Cargo}
              noofvechile={No_Of_Vehicels_Required}
              vehicletype={Vehicle_Type}
              weightcategory={Weight_Category}
              Material_Dimension_In={Material_Dimension_In}
              Material_Image={Material_Image}
              Material_Image_2={Material_Image_2}
              Material_Image_3={Material_Image_3}
            />
          </div>

          <div className="quoteandtimer">
            {Load_Post_Type === "Auction Load" && (
              <>
                <Timer
                  Auction_Time={Auction_Time}
                  Auction_Date={Auction_Date}
                />
                <Quote
                  No_Of_Vehicels_Required={No_Of_Vehicels_Required}
                  Total_Weight={Total_Weight}
                  ceilingprice={Expected_Price || Target_Price}
                  Rate_Per={Rate_Per}
                  loadId={_id}
                  Is_Auction_Started={Is_Auction_Started}
                  Is_Live={Is_Live}
                />
              </>
            )}
            {Load_Post_Type === "Open Load" && (
              <Quote
                ceilingprice={Expected_Price || Target_Price}
                No_Of_Vehicels_Required={No_Of_Vehicels_Required}
                Total_Weight={Total_Weight}
                Rate_Per={Rate_Per}
                loadId={_id}
                Load_Post_Type={Load_Post_Type}
              />
            )}
            {Load_Post_Type === "Fixed Load" && (
              <AcceptBids
                ceilingprice={Expected_Price || Target_Price}
                No_Of_Vehicels_Required={No_Of_Vehicels_Required}
                Total_Weight={Total_Weight}
                Rate_Per={Rate_Per}
                loadId={_id}
                Load_Post_Type={Load_Post_Type}
                BidDetails={BidDetails}
              />
            )}
          </div>
        </CardContent>
        <CardFooter>
          <div className="liveflexbox">
            <Note Notes={Notes} />
            <Pointofcontact
              createdAt={createdAt}
              Load_Live_Duration={Load_Live_Duration}
              Auction_Date={Auction_Date}
              Auction_Time={Auction_Time}
            />
          </div>
        </CardFooter>
      </div>
    </Card>
  );
}

export default OpenCard;
