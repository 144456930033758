import React from "react";
import LandingPageTopNavBar from "./LandingPageTopNavBar/LandingPageTopNavBar";
import LandingPageNavBar from "./LandingPageNavBar/LandingPageNavBar";
import About from "./About/About";
import MiddleImagePart from "./MiddlePartLandingPage/MiddlePartLandingPage";
import TimeTracking from "./TimeTracking/TimeTracking";
import Services from "./Services/Services";
import Footer from "./Footer/Footer";
import SupportSection from "./SupportSection/SupportSection";

function LandingPage() {
  return (
    <div>
      <LandingPageTopNavBar />
      <LandingPageNavBar />
      <div style={{ margin: "0 20px" }}>
        <MiddleImagePart />
        <About />
        <TimeTracking />
        <Services />
        <SupportSection />
        <Footer />
      </div>
    </div>
  );
}

export default LandingPage;
