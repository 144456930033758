const AllInputFields = [
  // Personal Details
  {
    id: Math.random() * 10,
    name: "Personal_Details",
    message: 'Basic info, for a faster booking experience',
    fields: [
      {
        id: Math.random() * 10,
        name: "Proprietor_Name",
        lable: "Proprietor Name (As in Aadhar card)",
        type: "text",
        require: true,
        autoCapital: true,
        min: 2,
        max: Infinity,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Residential_Address",
        lable: "Residential Address",
        type: "text",
        require: true,
        autoCapital: true,
        min: 2,
        max: Infinity,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Residential_Address_2",
        lable: "Residential Address",
        type: "text",
        require: false,
        autoCapital: true,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        lable: "Pincode",
        name: "Residential_Pincode",
        type: "number",
        require: true,
        autoCapital: true,
        min: 6,
        max: 6,
        formtype: "userProfile",
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Residential_City",
        lable: "City",
        setvalue: "City",
        disable: true,
        type: "text",
        min: 2,
        max: Infinity,
        readOnly: true,
        require: true,
        autoCapital: true,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Residential_State",
        lable: "State",
        setvalue: "State",
        disable: true,
        type: "text",
        min: 2,
        max: Infinity,
        readOnly: true,
        autoCapital: true,
        require: true,
        inputComponentType: 'InputContentEditable'
      },

      {
        id: Math.random() * 10,
        name: "Aadhar_Number",
        lable: "Aadhar Number",
        type: "number",
        require: true,
        autoCapital: true,
        min: 12,
        max: 12,
        formtype: "userProfile",
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Pan_Number",
        lable: "Pan Number",
        type: "text",
        uppercase: true,
        require: true,
        autoCapital: true,
        min: 10,
        max: 10,
        inputComponentType: 'InputContentEditable'
      },
    ],
  },
  // Business Details
  {
    id: Math.random() * 10,
    name: "Business_Details",
    message: 'Basic info, for a faster booking experience',
    fields: [
      {
        id: Math.random() * 10,
        name: "Orgnaization_Type",
        lable: "Orgnaization Type",
        require: true,
        type: "inputdrop",
        min: 3,
        max: Infinity,
        nolable: true,
        isSearchable: true,
        autoCapital: true,
        dropdowncontent: [
          "Limited",
          "Private Limited",
          "Public Limited",
          "Partnership",
          "Proprietor",
          "Individual",
        ],
        inputComponentType: 'InputBox'
      },
      {
        id: Math.random() * 10,
        name: "Business_Name",
        lable: "Business Name",
        type: "text",
        require: true,
        autoCapital: true,
        min: 2,
        max: Infinity,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Business_Address",
        lable: "Business Address",
        type: "text",
        require: true,
        autoCapital: true,
        min: 3,
        max: Infinity,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Business_Address_2",
        lable: "Business Address",
        type: "text",
        require: false,
        autoCapital: true,
        min: 0,
        max: Infinity,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Business_Pincode",
        lable: "Pincode",
        type: "number",
        min: 6,
        require: true,
        autoCapital: true,
        max: 6,
        formtype: "userProfile",
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Business_City",
        lable: "City",
        setvalue: "City",
        disable: true,
        type: "text",
        require: true,
        autoCapital: true,
        min: 3,
        max: Infinity,
        readOnly: true,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Business_State",
        lable: "State",
        disable: true,
        setvalue: "State",
        type: "text",
        require: true,
        autoCapital: true,
        min: 3,
        max: Infinity,
        readOnly: true,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Mobile_Number",
        lable: "Mobile Number",
        type: "number",
        require: true,
        verify: true,
        autoCapital: true,
        min: 10,
        max: 10,
        formtype: "userProfile",
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Land_Line",
        lable: "Land Line",
        type: "mask",
        autoCapital: true,
        mask: true,
        require: false,
        inputComponentType: 'CustomInputMask'
      },

      {
        id: Math.random() * 10,
        name: "Email",
        lable: "Email",
        type: "email",
        verify: false,
        uppercase: false,
        autoCapital: false,
        require: false,
        min: 3,
        max: Infinity,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "GST_Number",
        lable: "GST Number",
        type: "text",
        uppercase: true,
        require: false,
        autoCapital: true,
        min: 15,
        max: 15,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Business_Pan",
        lable: "Business PAN ",
        type: "text",
        uppercase: true,
        require: true,
        autoCapital: true,
        min: 10,
        max: 10,
        inputComponentType: 'InputContentEditable'
      },
    ],
  },
  // Bank Details
  {
    id: Math.random() * 10,
    name: "Bank_Details",
    message: 'Once your bank details have been verified and locked in our system, they cannot be directly edited. If you need to make changes to your bank details, please send an email from your registered email address to our support team.',
    fields: [
      {
        id: Math.random() * 10,
        name: "Bank_Name",
        lable: "Bank Name",
        type: "inputdrop",
        dropdowncontent: ["SBI", "HDFC", "IDFC", "BOI"],
        min: 2,
        max: Infinity,
        require: true,
        autoCapital: true,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "BankAccount_Type",
        lable: "Account Type",
        type: "inputdrop",
        require: true,
        min: 3,
        max: Infinity,
        nolable: true,
        isSearchable: true,
        autoCapital: true,
        dropdowncontent: ["Savings", "Current", "Cash Credit", "Overdraft"],
        inputComponentType: 'InputBox'
      },
      {
        id: Math.random() * 10,
        name: "IFSC_Code",
        lable: "IFSC Code",
        type: "text",
        uppercase: true,
        min: 11,
        max: 11,
        require: true,
        autoCapital: true,
        inputComponentType: 'InputContentEditable'
      },
      {
        id: Math.random() * 10,
        name: "Account_Number",
        lable: "Account Number",
        type: "text",
        // validate: true,
        min: 5,
        max: 16,
        require: true,
        autoCapital: true,
        inputComponentType: 'InputContentEditable',
        hideValue: true
      },
    ],
  },
  // Documents
  {
    name: "Documents",
    fields: [
      {
        id: Math.random() * 10,
        name: "Aadhar_Card",
        lable: "Aadhar Card*",
        forView: ['Load Partner Account', 'Supply Partner Account'],
        require: true
      },
      {
        id: Math.random() * 10,
        name: "Pan_Card",
        lable: "Pan Card*",
        forView: ['Load Partner Account', 'Supply Partner Account'],
        require: true
      },
      {
        id: Math.random() * 10,
        name: "Proprietor_Photo",
        lable: "Proprietor Photo*",
        forView: ['Load Partner Account', 'Supply Partner Account'],
        require: true
      },
      {
        id: Math.random() * 10,
        name: "Visiting_Card",
        lable: "Visiting Card",
        forView: ['Load Partner Account', 'Supply Partner Account']
      },
      {
        id: Math.random() * 10,
        name: "GST_Certificate",
        lable: "GST Certificate",
        forView: ['Load Partner Account', 'Supply Partner Account']
      },
      {
        id: Math.random() * 10,
        name: "Shop_Act_Licence",
        lable: "Shop Act Licence",
        forView: ['Load Partner Account', 'Supply Partner Account']
      },
      {
        id: Math.random() * 10,
        name: "Cancelled_Cheque",
        lable: "Cancelled Cheque",
        forView: ['Load Partner Account', 'Supply Partner Account']
      },
      {
        id: Math.random() * 10,
        name: "Office_Board_Photo",
        lable: "Office Board Photo",
        forView: ['Load Partner Account', 'Supply Partner Account']
      },
      {
        id: Math.random() * 10,
        name: "MSME_certificate",
        lable: "MSME certificate",
        forView: ['Load Partner Account', 'Supply Partner Account']
      },
      {
        id: Math.random() * 10,
        name: "Self_Declaration_video",
        lable: "Self Declaration video",
        forView: ['Supply Partner Account']
      },
      {
        id: Math.random() * 10,
        name: "Other_Doc_1",
        lable: "Other Doc 1",
        require: false,
        forView: ['Load Partner Account', 'Supply Partner Account'],
      },
      {
        id: Math.random() * 10,
        name: "Other_Doc_2",
        lable: "Other Doc 2",
        require: false,
        forView: ['Load Partner Account', 'Supply Partner Account']
      },
    ],
  },
  {
    name: "Verified_Details",
    fields: [
      { name: "Is_Aadhar_Verified" },
      { name: "Is_Pan_Verified" },
      { name: "Is_Bank_Verified" },
      { name: "Is_Mobile_Number_Verified" },
      { name: "Is_Document_Verified" },

    ]
  }
]

const BusinessData = {
  id: Math.random() * 10,
  name: "Business_Details",
  fields: [
    {
      id: Math.random() * 10,
      name: "Business_Name",
      lable: "Business Name",
      type: "text",
      require: true,
      autoCapital: true,
      min: 2,
      max: Infinity,
    },
    {
      id: Math.random() * 10,
      name: "Business_Address",
      lable: "Business Address",
      type: "text",
      require: true,
      autoCapital: true,
      min: 3,
      max: Infinity,
    },
    {
      id: Math.random() * 10,
      name: "Business_Address_2",
      lable: "Business Address",
      type: "text",
      require: false,
      autoCapital: true,
      min: 0,
      max: Infinity,
    },
    {
      id: Math.random() * 10,
      name: "Business_Pincode",
      lable: "Pincode",
      type: "number",
      min: 6,
      require: true,
      autoCapital: true,
      max: 6,

      formtype: "userProfile",
    },
    {
      id: Math.random() * 10,
      name: "Business_City",
      lable: "City",
      setvalue: "City",
      disable: true,
      type: "text",
      require: true,
      autoCapital: true,
      min: 3,
      max: Infinity,
      readOnly: true,
    },
    {
      id: Math.random() * 10,
      name: "Business_State",
      lable: "State",
      disable: true,
      setvalue: "State",
      type: "text",
      require: true,
      autoCapital: true,
      min: 3,
      max: Infinity,
      readOnly: true,
    },
    {
      id: Math.random() * 10,
      name: "Mobile_Number",
      lable: "Mobile Number",
      type: "number",
      require: true,
      verify: true,
      autoCapital: true,
      min: 10,
      max: 10,
      formtype: "userProfile",
    },
    {
      id: Math.random() * 10,
      name: "Land_Line",
      lable: "Land Line",
      type: "mask",
      autoCapital: true,
      mask: true,
      require: false,
    },

    {
      id: Math.random() * 10,
      name: "Email",
      lable: "Email",
      type: "email",
      verify: true,
      uppercase: false,
      autoCapital: false,
      require: true,
      min: 3,
      max: Infinity,
    },
    {
      id: Math.random() * 10,
      name: "GST_Number",
      lable: "GST Number",
      type: "text",
      uppercase: true,
      require: false,
      autoCapital: true,
      min: 15,
      max: 15,
    },
    {
      id: Math.random() * 10,
      name: "Business_Pan",
      lable: "Business PAN ",
      type: "text",
      uppercase: true,
      require: true,
      autoCapital: true,
      min: 10,
      max: 10,
    },
    {
      id: Math.random() * 10,
      name: "Orgnaization_Type",
      lable: "Orgnaization Type",
      require: true,
      type: "inputdrop",
      min: 3,
      max: Infinity,
      nolable: true,
      isSearchable: true,
      autoCapital: true,
      dropdowncontent: [
        "Limited",
        "Private Limited",
        "Public Limited",
        "Partnership",
        "Proprietor",
        "Individual",
      ],
    },
  ],
};

const PersonalData = {
  name: "Personal_Details",
  fields: [
    {
      id: Math.random() * 10,
      name: "Proprietor_Name",
      lable: "Proprietor Name (As in Aadhar card)",
      type: "text",
      require: true,
      autoCapital: true,
      min: 2,
      max: Infinity,
    },
    {
      id: Math.random() * 10,
      name: "Residential_Address",
      lable: "Residential Address",
      type: "text",
      require: true,
      autoCapital: true,
      min: 2,
      max: Infinity,
    },
    {
      id: Math.random() * 10,
      name: "Residential_Address_2",
      lable: "Residential Address",
      type: "text",
      require: false,
      autoCapital: true,
    },
    {
      id: Math.random() * 10,
      lable: "Pincode",
      name: "Residential_Pincode",
      type: "number",
      require: true,
      autoCapital: true,
      min: 6,
      max: 6,

      formtype: "userProfile",
    },
    {
      id: Math.random() * 10,
      name: "Residential_City",
      lable: "City",
      setvalue: "City",
      disable: true,
      type: "text",
      min: 2,
      max: Infinity,
      readOnly: true,
      require: true,
      autoCapital: true,
    },
    {
      id: Math.random() * 10,
      name: "Residential_State",
      lable: "State",
      setvalue: "State",
      disable: true,
      type: "text",
      min: 2,
      max: Infinity,
      readOnly: true,
      autoCapital: true,
      require: true,
    },

    {
      id: Math.random() * 10,
      name: "Aadhar_Number",
      lable: "Aadhar Number",
      type: "number",
      require: true,
      autoCapital: true,
      min: 12,
      max: 12,
      formtype: "userProfile",
    },
    {
      id: Math.random() * 10,
      name: "Pan_Number",
      lable: "Pan Number",
      type: "text",
      uppercase: true,
      require: true,
      autoCapital: true,
      min: 10,
      max: 10,
    },
  ],
};

const BankData = {
  name: "Bank_Details",
  fields: [
    {
      id: Math.random() * 10,
      name: "Bank_Name",
      lable: "Bank Name",
      type: "inputdrop",
      dropdowncontent: ["SBI", "HDFC", "IDFC", "BOI"],
      min: 2,
      max: Infinity,
      require: true,
      autoCapital: true,
    },
    {
      id: Math.random() * 10,
      name: "BankAccount_Type",
      lable: "Account Type",
      type: "inputdrop",
      require: true,
      min: 3,
      max: Infinity,
      nolable: true,
      isSearchable: true,
      autoCapital: true,
      dropdowncontent: ["Savings", "Current", "Cash Credit", "Overdraft"],
    },
    {
      id: Math.random() * 10,
      name: "IFSC_Code",
      lable: "IFSC Code",
      type: "text",
      uppercase: true,
      min: 11,
      max: 11,
      require: true,
      autoCapital: true,
    },
    {
      id: Math.random() * 10,
      name: "Account_Number",
      lable: "Account Number",
      type: "text",
      // validate: true,
      min: 5,
      max: 16,
      require: true,
      autoCapital: true,
    },
  ],
};

const DocumentsDataoForSP = {
  name: "Documents",
  fields: [
    {
      id: Math.random() * 10,
      name: "Aadhar_Card",
      lable: "Aadhar Card",
    },
    {
      id: Math.random() * 10,
      name: "Pan_Card",
      lable: "Pan Card",
    },
    {
      id: Math.random() * 10,
      name: "Proprietor_Photo",
      lable: "Proprietor Photo",
    },
    {
      id: Math.random() * 10,
      name: "Visiting_Card",
      lable: "Visiting Card",
    },
    {
      id: Math.random() * 10,
      name: "GST_Certificate",
      lable: "GST Certificate",
    },
    {
      id: Math.random() * 10,
      name: "Shop_Act_Licence",
      lable: "Shop Act Licence",
    },
    {
      id: Math.random() * 10,
      name: "Cancelled_Cheque",
      lable: "Cancelled Cheque",
    },
    {
      id: Math.random() * 10,
      name: "Office_Board_Photo",
      lable: "Office Board Photo",
    },
    {
      id: Math.random() * 10,
      name: "MSME_certificate",
      lable: "MSME certificate",
    },
    {
      id: Math.random() * 10,
      name: "Self_Declaration_video",
      lable: "Self Declaration video",
    },
    {
      id: Math.random() * 10,
      name: "Other_Doc_1",
      lable: "Other Doc 1",

      require: false,
    },
    {
      id: Math.random() * 10,
      name: "Other_Doc_2",
      lable: "Other Doc 2",
      require: false,
    },
  ],
};
const DocumentsDataoForLP = {
  name: "Documents",
  fields: [
    {
      id: Math.random() * 10,
      name: "Aadhar_Card",
      lable: "Aadhar Card",
    },
    {
      id: Math.random() * 10,
      name: "Pan_Card",
      lable: "Pan Card",
    },
    {
      id: Math.random() * 10,
      name: "Proprietor_Photo",
      lable: "Proprietor Photo",
    },
    {
      id: Math.random() * 10,
      name: "Visiting_Card",
      lable: "Visiting Card",
    },
    {
      id: Math.random() * 10,
      name: "GST_Certificate",
      lable: "GST Certificate",
    },
    {
      id: Math.random() * 10,
      name: "Shop_Act_Licence",
      lable: "Shop Act Licence",
    },
    {
      id: Math.random() * 10,
      name: "Cancelled_Cheque",
      lable: "Cancelled Cheque",
    },
    {
      id: Math.random() * 10,
      name: "Office_Board_Photo",
      lable: "Office Board Photo",
    },
    {
      id: Math.random() * 10,
      name: "MSME_certificate",
      lable: "MSME certificate",
    },
    {
      id: Math.random() * 10,
      name: "Other_Doc_1",
      lable: "Other Doc 1",

      require: false,
    },
    {
      id: Math.random() * 10,
      name: "Other_Doc_2",
      lable: "Other Doc 2",

      require: false,
    },
  ],
};
export { BusinessData, PersonalData, BankData, DocumentsDataoForSP, DocumentsDataoForLP, AllInputFields };