const initial = {
    accType: "",
  };
  
  export function AccountTypeReducer(state = initial, { type, payload }) {
    switch (type) {
      case "set_accType": {
        return {
          ...state,
          accType: payload,
        };
      }
      default: {
        return state;
      }
    }
  }