import React from "react";
import "./ProcessAdvanceModal.scss";
import PortalFooter from "../../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../../Button/Button";
import { useSelector } from "react-redux";
import { NumberFormatter } from "../../../../../CommonFunction/NumberFormatter/NumberFormatter";
import Spinner from "../../../Spinner/Spinner";

function ProcessAdvanceModal({
  editCommittedVehiclesData,
  onClose,
  advance,
  loading,
}) {
  let accBal = useSelector((store) => store.accBalance.accBalance);
  let remainingBal = accBal - advance;

  return (
    <div className="processAdvanceDiv">
      <div className="eachProcessAdvanceDiv">
        <p className="processAdvanceHeading">Current Bal :</p>
        <p className="processAdvanceText">{NumberFormatter(accBal)}/-</p>
      </div>
      <div className="eachProcessAdvanceDiv">
        <p className="processAdvanceHeading">Amount to be paid :</p>
        <p className="processAdvanceText">
          {NumberFormatter(Math.round(advance))} /-
        </p>
      </div>
      <div className="eachProcessAdvanceDiv">
        <p className="processAdvanceHeading">Remaining Balance :</p>
        <p className="processAdvanceText">
          {NumberFormatter(Math.round(remainingBal))}/-
        </p>
      </div>
      {remainingBal < 0 && (
        <p className="insufficientBalText">Insufficient Balance</p>
      )}

      <p className="processAdvanceCnfMessage">
        Are you sure to Process Advance ?
      </p>

      <PortalFooter>
        {loading ? (
          <Button
            text={<Spinner />}
            className="loadingAcceptBtn"
            type="button"
          />
        ) : (
          <Button
            text={"Confirm"}
            className={remainingBal < 0 ? "acceptBtndisabled" : "acceptBtn"}
            disabled={remainingBal < 0 ? true : false}
            type="submit"
            handleClick={editCommittedVehiclesData}
          />
        )}

        <Button text={"Cancel"} className="rejectBtn" handleClick={onClose} />
      </PortalFooter>
    </div>
  );
}

export default ProcessAdvanceModal;
