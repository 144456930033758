import React, { useState, useEffect } from "react";
import Button from "../../../Button/Button";
import Reactportal from "../../../../LpComponents/Reactportal/Reactportal";
import AddBalanceModal from "../../../AllModals/AddBalanceModal/AddBalanceModal";
import WithdrawnBalanceModal from "../../../AllModals/WithdrawnBalanceModal/WithdrawnBalanceModal";
import "./WalletBalancePopup.scss";
import { useQuery } from "@apollo/client";
import { get_coustomer } from "../../../../../gql/getCoustomer";

function WalletBalancePopup({ setModalIsOpen }) {
  const [addBalanceModal, setAddBalanceModal] = useState(false);
  const [withdrawnBalanceModal, setWithdrawnBalanceModal] = useState(false);
  const [isAccVerified, setIsAccVerified] = useState("");
  const { data } = useQuery(get_coustomer);

  useEffect(() => {
    console.log(data,"dstta")
    if (data.AccountSearchFromCookies) {
      setIsAccVerified(data.AccountSearchFromCookies.Is_Verified);
    }
  }, [data]);

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <div className="walletBalancePopupDiv" onClick={handleModalClick}>
        <div className="btnDiv ">
          <Button
            className={isAccVerified ? "walletbtn" : "btn1Disalbe"}
            text={"+ Add to Account"}
            disabled={!isAccVerified}
            handleClick={() => setAddBalanceModal(true)}
          />
          {!isAccVerified && <p className="walletBalancePopupErrText">KYC Pending</p>}
          <Button text={"Withdrawn"} className="walletbtnTwo" handleClick={() => setWithdrawnBalanceModal(true)} />
        </div>
      </div>
      <Reactportal
        open={addBalanceModal}
        modalHeading="Add to Account"
        modalClassName="confirmationModal"
        contentClassName={"confirmationModal-content"}
        onClose={() => {
          setAddBalanceModal(false);
          setModalIsOpen(false);
        }}
      >
        <AddBalanceModal
          onClose={() => {
            setAddBalanceModal(false);
            setModalIsOpen(false);
          }}
        />
      </Reactportal>
      <Reactportal
        open={withdrawnBalanceModal}
        modalHeading="Withdrawn from Account"
        modalClassName="confirmationModal"
        contentClassName={"confirmationModal-content"}
        onClose={() => {
          setWithdrawnBalanceModal(false);
          setModalIsOpen(false);
        }}
      >
        <WithdrawnBalanceModal
          onClose={() => {
            setWithdrawnBalanceModal(false);
            setModalIsOpen(false);
          }}
        />
      </Reactportal>
    </>
  );
}

export default WalletBalancePopup;
