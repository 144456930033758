import { gql } from "@apollo/client";

export const GET_USER_ACCOUNT_BALANCE = gql`
  query GetAccountBalanceWithAccountType_ID($accountTypeId: ID!) {
    GetAccountBalanceWithAccountType_ID(Account_Type_Id: $accountTypeId) {
      _id
      Unique_Id
      Account_Type
      Account_Name
      Account_Balance
      createdAt
      updatedAt
    }
  }
`;
