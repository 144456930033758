import React, { useEffect, useState } from "react";
import PortalFooter from "../../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../../Button/Button";
import FileUploadBox from "../../../../LpComponents/FileUploadBox/FileUploadBox";
import { unloadingCompleteFileUpload } from "../../../../../db/FileUploadConfig/FileUpload.config";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import InputBox from "../../../InputBox/InputBox";
import { unloadingCompletedModalData } from "../../../../../db/ModalsData/ModalsData.config";
import socket from "../../../../../CommonFunction/Socket/socketClient";
import "./UnloadingCompleteModal.scss";
import Spinner from "../../../Spinner/Spinner";
import { EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE } from "../../../../../gql/editCommitedVehiclesWithoutVerifyBalance";
import { CREATE_NEW_CLAIM } from "../../../../../gql/createClaims";
import { CREATE_POD } from "../../../../../gql/createPOD";

function UnloadingCompleteModal({ LpId, CommittedVehicleId, onClose }) {
  const [isEditCommittedVehiclesDataLoading, setIsEditCommittedVehiclesDataLoading] = useState(false);
  const [editCommittedVehiclesData, { loading: editCommittedVehiclesDataLoading }] = useMutation(
    EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE
  );
  const [createPODManagement, { loading: createPODManagementLoading }] = useMutation(CREATE_POD);
  const [createClaims, { loading: createClaimsLoading }] = useMutation(CREATE_NEW_CLAIM);
  const [spClaimRaised, setSpClaimRaised] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (selectedValue === "Yes") {
      setSpClaimRaised(true);
    } else setSpClaimRaised(false);
  }, [selectedValue]);

  const updateFilesStore = (newFiles) => {
    const updatedFilesStore = { ...initialValues };
    Object.keys(newFiles).forEach((key) => {
      formik.setFieldValue(key, newFiles[key]);
      if (updatedFilesStore.hasOwnProperty(key)) {
        updatedFilesStore[key] = newFiles[key];
      }
    });
    initialValues = {
      ...updatedFilesStore,
    };
  };

  let initialValues = {
    spClaim: "",
    Holding_Charges: 0,
    Hamali_Charges: 0,
    Extra_Running: 0,
    Challan_Charges: 0,
    Other_Charges: 0,
    Other_Charges_Reason: "",
    POD_Front_Copy: "",
    POD_Back_Copy: "",
    Final_Invoice_Copy: "",
    CommittedVehicleId: CommittedVehicleId,
  };
  const validate = (values) => {
    // console.log("validate", values);

    let errors = {};

    // if (!values.POD_Front_Copy) {
    //   errors.POD_Front_Copy = "Please upload all files";
    // } else if (!values.POD_Back_Copy) {
    //   errors.POD_Back_Copy = "Please upload all files";
    // } else if (!values.Final_Invoice_Copy) {
    //   errors.Final_Invoice_Copy = "Please upload all files";
    // } else
    if (!values.spClaim) {
      errors.spClaim = "Please select an option";
    } else if (spClaimRaised) {
      if (
        !values.Holding_Charges &&
        !values.Hamali_Charges &&
        !values.Extra_Running &&
        !values.Challan_Charges &&
        !values.Other_Charges
      ) {
        errors.Holding_Charges = "Please fill at least one field";
        errors.Hamali_Charges = "Please fill at least one field";
        errors.Extra_Running = "Please fill at least one field";
        errors.Challan_Charges = "Please fill at least one field";
        errors.Other_Charges = "Please fill at least one field";
      } else if (values.Holding_Charges === "0") {
        errors.Holding_Charges = "Field can't be 0";
      } else if (values.Hamali_Charges === "0") {
        errors.Hamali_Charges = "Field can't be 0";
      } else if (values.Extra_Running === "0") {
        errors.Extra_Running = "Field can't be 0";
      } else if (values.Challan_Charges === "0") {
        errors.Challan_Charges = "Field can't be 0";
      } else if (values.Other_Charges === "0") {
        errors.Other_Charges = "Field can't be 0";
      }

      if (values.Other_Charges && !values.Other_Charges_Reason) {
        errors.Other_Charges_Reason = "Please Enter Reason";
      }
    }

    return errors;
  };
  const onSubmit = async (values) => {
    let orgValues = { ...values };

    delete values["spClaim"];

    if (spClaimRaised) {
      delete values["POD_Front_Copy"];
      delete values["POD_Back_Copy"];
      delete values["Final_Invoice_Copy"];

      await handleEditCommittedVehicleData(orgValues)
        .then(
          async () =>
            await createClaims({
              variables: {
                createInput: values,
              },
            })
              .then(() => {
                toast.success("Unloading Completed");
                socket.emit("Update_CurrentTask", LpId, CommittedVehicleId);
                onClose();
              })
              .catch((err) => toast.error(err.message))
        )
        .catch((err) => toast.error(err.message));
    } else {
      delete values["Holding_Charges"];
      delete values["Hamali_Charges"];
      delete values["Extra_Running"];
      delete values["Challan_Charges"];
      delete values["Other_Charges"];
      delete values["Other_Charges_Reason"];
      delete values["CommittedVehicleId"];

      await editCommittedVehiclesData({
        variables: {
          editCommittedVehiclesWithoutVerifyUserWithBalanceId: CommittedVehicleId,
          userInput: {
            ...values,
            Sp_Claim_Cleared: true,
            Unloading_Completed: true,
            Current_Stage: "Unloading Completed",
            Unloading_Completed_Time: new Date(),
          },
        },
      })
        .then(async (res) => {
          await createPODManagement({
            variables: {
              createPodManagementInput: {
                POD_Copy_Front: orgValues.POD_Front_Copy,
                POD_Copy_Back: orgValues.POD_Back_Copy,
                Final_Invoice_Copy: orgValues.Final_Invoice_Copy,
                Booking_Id: res.data.editCommittedVehicles_Without_VerifyUserWithBalance.Unique_Id,
                Vehicle_Number: res.data.editCommittedVehicles_Without_VerifyUserWithBalance.Vehicle_Number._id,
                UserId: res.data.editCommittedVehicles_Without_VerifyUserWithBalance.UserId,
                LoadId: res.data.editCommittedVehicles_Without_VerifyUserWithBalance.LoadId,
                Driver_Name: res.data.editCommittedVehicles_Without_VerifyUserWithBalance.Driver_Name,
                Driver_Number: res.data.editCommittedVehicles_Without_VerifyUserWithBalance.Driver_Number,
              },
            },
          })
            .then(() => {
              toast.success("Unloading Completed");
              socket.emit("Update_CurrentTask", LpId, CommittedVehicleId);
              onClose();
            })
            .catch((err) => toast.error(err.message));
        })
        .catch((err) => toast.error(err.message));
    }
  };

  const handleEditCommittedVehicleData = async (orgValues) => {
    delete orgValues["spClaim"];
    delete orgValues["Holding_Charges"];
    delete orgValues["Hamali_Charges"];
    delete orgValues["Extra_Running"];
    delete orgValues["Challan_Charges"];
    delete orgValues["Other_Charges"];
    delete orgValues["Other_Charges_Reason"];
    delete orgValues["CommittedVehicleId"];

    await editCommittedVehiclesData({
      variables: {
        editCommittedVehiclesWithoutVerifyUserWithBalanceId: CommittedVehicleId,
        userInput: {
          ...orgValues,
          Sp_Claim_Raised: true,
          Unloading_Completed: true,
          Current_Stage: "Unloading Completed",
          Unloading_Completed_Time: new Date(),
        },
      },
    })
      .then(async (res) => {
        await createPODManagement({
          variables: {
            createPodManagementInput: {
              POD_Copy_Front: orgValues.POD_Front_Copy,
              POD_Copy_Back: orgValues.POD_Back_Copy,
              Final_Invoice_Copy: orgValues.Final_Invoice_Copy,
              Booking_Id: res.data.editCommittedVehicles_Without_VerifyUserWithBalance.Unique_Id,
              Vehicle_Number: res.data.editCommittedVehicles_Without_VerifyUserWithBalance.Vehicle_Number._id,
              UserId: res.data.editCommittedVehicles_Without_VerifyUserWithBalance.UserId,
              LoadId: res.data.editCommittedVehicles_Without_VerifyUserWithBalance.LoadId,
              Driver_Name: res.data.editCommittedVehicles_Without_VerifyUserWithBalance.Driver_Name,
              Driver_Number: res.data.editCommittedVehicles_Without_VerifyUserWithBalance.Driver_Number,
            },
          },
        }).catch((err) => toast.error(err.message));
      })
      .catch((err) => toast.error(err.message));
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  const handleformikdata = (Value, id) => {
    let data = Value;

    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);
  };

  useEffect(() => {
    let sum = 0;

    sum =
      (formik.values.Holding_Charges && !isNaN(formik.values.Holding_Charges)
        ? parseFloat(formik.values.Holding_Charges)
        : 0) +
      (formik.values.Hamali_Charges && !isNaN(formik.values.Hamali_Charges)
        ? parseFloat(formik.values.Hamali_Charges)
        : 0) +
      (formik.values.Extra_Running && !isNaN(formik.values.Extra_Running)
        ? parseFloat(formik.values.Extra_Running)
        : 0) +
      (formik.values.Challan_Charges && !isNaN(formik.values.Challan_Charges)
        ? parseFloat(formik.values.Challan_Charges)
        : 0) +
      (formik.values.Other_Charges && !isNaN(formik.values.Other_Charges)
        ? parseFloat(formik.values.Other_Charges)
        : 0);

    setTotal(sum);
  }, [formik.values]);

  useEffect(() => {
    if (editCommittedVehiclesDataLoading || createPODManagementLoading || createClaimsLoading)
      setIsEditCommittedVehiclesDataLoading(
        editCommittedVehiclesDataLoading || createPODManagementLoading || createClaimsLoading
      );
  }, [editCommittedVehiclesDataLoading, createPODManagementLoading, createClaimsLoading]);

  return (
    <div className="unloadingCompleteModal">
      <form onSubmit={formik.handleSubmit}>
        <div className="eachContentDiv">
          <p className="contentMainHeading">File Upload -</p>
        </div>
        <FileUploadBox
          UploadFields={unloadingCompleteFileUpload}
          format={true}
          err={formik.errors.POD_Front_Copy || formik.errors.POD_Back_Copy || formik.errors.Final_Invoice_Copy}
          sendFilesFun={(e) => updateFilesStore(e)}
          pageName={"OngoingPage"}

        />
        <div className="eachContentDiv">
          <p className="contentHeading">Do you want to Raise a Claim?*</p>
          <select
            onChange={(e) => {
              setSelectedValue(e.target.value);
              formik.setFieldValue("spClaim", e.target.value);
            }}
            value={formik.values.spClaim}
          >
            <option value="">Select</option>
            <option value={"Yes"}>Yes</option>
            <option value={"No"}>No</option>
          </select>
        </div>
        {<p className="errText">{formik.errors.spClaim}</p>}
        {spClaimRaised ? (
          <>
            <div className="eachContentDiv">
              <p className="contentMainHeading">Claim Charges -</p>
            </div>

            {unloadingCompletedModalData?.map((el) =>
              el.by === "lp" ? (
                <div key={el.name} className="eachContentDiv">
                  <p className="contentHeading2">{el.contentHeading} :</p>
                  <InputBox
                    className={"cnfQtyInput no-spinners"}
                    type={el.type}
                    name={el.name}
                    handleInputChange={formik.handleChange}
                    handleformikdata={handleformikdata}
                    autoComplete={el.autoComplete}
                    placeholder={el.placeholder}
                    err={formik.errors[el.name]}
                    touched={formik.touched[el.name] ? formik.touched[el.name] : false}
                  />
                </div>
              ) : (
                ""
              )
            )}
            <div className="eachContentDiv totaldiv">
              <p className="contentHeading">Total (₹) :</p>
              <p className="contentText">{total}</p>
            </div>
          </>
        ) : (
          <div></div>
        )}

        <PortalFooter>
          {isEditCommittedVehiclesDataLoading ? (
            <Button text={<Spinner />} className="loadingAcceptBtn" type="button" />
          ) : (
            <Button text={"Confirm"} className="acceptBtn" type="Submit" />
          )}

          <Button text={"Cancel"} className="rejectBtn" handleClick={onClose} />
        </PortalFooter>
      </form>
    </div>
  );
}

export default UnloadingCompleteModal;
