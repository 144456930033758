import React from "react";
import Button from "../../../Common/Button/Button";
import "./TypeOfLoad.scss";

const Typeofload = ({ Load_Post_Type, Rate_Per }) => {
  return (
    <div className="typeofload boxcenter">
      {Load_Post_Type === "Auction Load" && (
        <Button text="30 min Auction" className="auctionLoad" />
      )}

      {Load_Post_Type === "Open Load" && (
        <Button text="Open" className="openLoad" />
      )}
      {Load_Post_Type === "Fixed Load" && (
        <Button text="Fixed Price" className="fixedLoad" />
      )}

      {Rate_Per === "Per MT" ? (
        <Button text="Per Metric Ton" className="pmt_text" />
      ) : (
        <Button text="Per Vehicle" className="pv_text" />
      )}
    </div>
  );
};

export default Typeofload;
