import React, { useEffect, useState } from "react";
import { cnfVehicleData } from "../../../../db/cnfModalData.config";
import Reactportal from "../../../LpComponents/Reactportal/Reactportal";
import PortalFooter from "../../../SpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import socket from "../../../../CommonFunction/Socket/socketClient";
import "./VehicleCommitedModal.scss";
import AddVehicleModal from "../AddVehicleModal/AddVehicleModal";
import { GET_VEHICLES_DATA } from "../../../../gql/getVehicles";
import { CREATE_BID_DETAILS } from "../../../../gql/createBid";
import { CREATE_COMMITTED_VEHICLE } from "../../../../gql/createCommitedVehicles";
import { EDIT_BID_DETAILS } from "../../../../gql/editBid";
import "./VehicleCommitedModal.scss";
import BidConfirmationModal from "./BidConfirmationModal";

function VehicleCommitedModal({
  bidId,
  Total_Weight,
  bidPrice,
  price,
  loadId,
  Rate_Per,
  onClose,
  No_Of_Vehicels_Required,
}) {
  const [cnfModal, setCnfModal] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState();
  const [isEditBidDetailsLoading, setIsEditBidDetailsLoading] = useState(false);
  const [committedVehicleIds, setCommittedVehicleIds] = useState([]);
  const [formData, setFormData] = useState();
  const dispatch = useDispatch();
  const [vehiclesArr, setVehiclesArr] = useState([]);
  const [vehicleNumbersData, setVehicleNumbersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [inputPrice, setInputPrice] = useState(price);
  const [CapacityData, setCapacityData] = useState([]);
  const [sum, setSum] = useState(0);
  const [addVehicleModal, setAddVehicleModal] = useState(false);

  const [createBidDetails, { loading: createBidDetailsLoading }] = useMutation(CREATE_BID_DETAILS);
  const { data: getVehiclesDataD, refetch } = useQuery(GET_VEHICLES_DATA);

  const [editBidDetails, { loading: editBidDetailsLoading }] = useMutation(EDIT_BID_DETAILS);
  const [createCommittedVehicles, { loading: createCommittedVehiclesLoading }] = useMutation(CREATE_COMMITTED_VEHICLE);
  const [vehiclesDropDown, setVehiclesDropDown] = useState([]);

  const isLoadData = useSelector((store) => store.loadData.loadData);

  useEffect(() => {
    if (isLoadData) {
      refetch();
      dispatch({ type: "set_loadData", payload: false });
    }
  }, [isLoadData, refetch]);

  useEffect(() => {
    if (getVehiclesDataD && getVehiclesDataD.GetVehicleManagementDetailsByUserId) {
      setVehiclesDropDown(getVehiclesDataD.GetVehicleManagementDetailsByUserId);
    }
  }, [getVehiclesDataD]);

  useEffect(() => {
    if (vehiclesDropDown) {
      const data = vehiclesDropDown.map(({ Vehicle_No, _id }) => ({
        value: Vehicle_No,
        label: Vehicle_No,
        id: _id,
      }));
      setVehicleNumbersData(data);
    }
  }, [vehiclesDropDown, selectedNumber]);

  useEffect(() => {
    setFilteredData(vehicleNumbersData);
  }, [vehicleNumbersData, vehiclesArr, selectedNumber]);

  function handleSelectChange(selectedOptions, i) {
    setVehiclesArr((prevState) => {
      const updatedArr = [...prevState];
      updatedArr[i] = selectedOptions.id; // Update with the selected id
      return updatedArr;
    });
  }

  function handlePerMTChange(value, i) {
    setCapacityData((prevState) => {
      const updatedArr = [...prevState];
      updatedArr[i] = Number(value);
      return updatedArr;
    });
  }

  useEffect(() => {
    setSum(CapacityData.reduce((acc, curr) => acc + curr, 0));
  }, [CapacityData, selectedNumber]);

  function createArray(n) {
    const myArray = [];
    for (let i = 1; i <= n; i++) {
      myArray.push(i);
    }
    return myArray;
  }

  const noOfVehiclesData = createArray(No_Of_Vehicels_Required);

  useEffect(() => {
    if (selectedNumber) {
      formik.setValues({
        LoadId: loadId,
        No_Of_Vehicle: selectedNumber,
        Committed_Vehicles_0: "",
        Committed_Vehicles_1: "",
        Committed_Vehicles_2: "",
        Vehicle_Quantity_0: "",
        Vehicle_Quantity_1: "",
        Vehicle_Quantity_2: "",
        Bid_Note: "",
        Price: bidPrice,
      });

      setSum(0);
      setCapacityData([]);
    }
  }, [selectedNumber]);

  const initialValues = {
    LoadId: loadId,
    No_Of_Vehicle: "",
    Committed_Vehicles_0: "",
    Committed_Vehicles_1: "",
    Committed_Vehicles_2: "",
    Vehicle_Quantity_0: "",
    Vehicle_Quantity_1: "",
    Vehicle_Quantity_2: "",
    Bid_Note: "",
    Price: bidPrice,
  };

  const validate = (values) => {
    setFormData(values);

    // console.log("validate", values);

    let errors = {};

    if (!values.No_Of_Vehicle) {
      errors.No_Of_Vehicle = "Number of vehicles is required";
    }

    for (let i = 0; i < values.No_Of_Vehicle; i++) {
      const committedFieldName = `Committed_Vehicles_${i}`;
      if (!values[committedFieldName]) {
        errors[committedFieldName] = "Please select a vehicle";
      }

      const quantityFieldName = `Vehicle_Quantity_${i}`;
      if (!values[quantityFieldName]) {
        errors[quantityFieldName] = "Quantity is required";
      }

      if (sum > Total_Weight) {
        errors.Vehicle_Quantity_0 = "Please enter lessthen or equal Quantity";
      }

      if (selectedNumber >= 1) {
        if (sum > Total_Weight) {
          errors.Vehicle_Quantity_1 = "Please enter lessthen or equal Quantity";
        }
      }

      if (selectedNumber >= 2) {
        if (sum > Total_Weight) {
          errors.Vehicle_Quantity_2 = "Please enter lessthen or equal Quantity";
        }
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: newBidDetails, // Use the newBidDetails function as the submit handler
  });

  async function postCommittedVehicle(VehicleArr, CapacityData, _id) {
    const arr = VehicleArr.map(async (e, i) => {
      try {
        const res = await createCommittedVehicles({
          variables: {
            createInput: {
              BidId: _id,
              LoadId: loadId,
              Vehicle_Number: e,
              Vehicle_Capacity: CapacityData[i],
              Freight_Amount:
                Rate_Per === "Per MT"
                  ? Math.round(Number(bidPrice) * Number(CapacityData[i]))
                  : Math.round(Number(bidPrice)),
              Platform_Charges:
                Rate_Per === "Per MT"
                  ? Math.round((2 / 100) * (Number(bidPrice) * Number(CapacityData[i])))
                  : Math.round((2 / 100) * Number(bidPrice)),
            },
          },
        });
        dispatch({ type: "set_loadData", payload: true });
        return res.data.createCommittedVehicles._id;
      } catch (err) {
        toast.error(err.message);
        return null;
      }
    });

    Promise.all(arr)
      .then((ids) => {
        const filteredIds = ids.filter((_id) => _id !== null);
        setCommittedVehicleIds(filteredIds);
        const PostObj = { ...formData, Committed_Vehicles: filteredIds };

        delete PostObj.Committed_Vehicles_0;
        delete PostObj.Committed_Vehicles_1;
        delete PostObj.Committed_Vehicles_2;
        delete PostObj.Vehicle_Quantity_0;
        delete PostObj.Vehicle_Quantity_1;
        delete PostObj.Vehicle_Quantity_2;

        editBidDetails({
          variables: {
            editBidDetailsId: _id,
            userInput: PostObj,
          },
        });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  async function newBidDetails() {
    const PostObj = { ...formData, Committed_Vehicles: vehiclesArr };
    setCnfModal(false);

    delete PostObj.Committed_Vehicles_0;
    delete PostObj.Committed_Vehicles_1;
    delete PostObj.Committed_Vehicles_2;
    delete PostObj.Vehicle_Quantity_0;
    delete PostObj.Vehicle_Quantity_1;
    delete PostObj.Vehicle_Quantity_2;

    if (inputPrice) {
      try {
        const res = await editBidDetails({
          variables: {
            editBidDetailsId: bidId,
            userInput: PostObj,
          },
        });
        setCommittedVehicleIds([]);
        postCommittedVehicle(vehiclesArr, CapacityData, res.data.editBidDetails._id);
        setCnfModal(false);
        onClose();
        toast.success("Bid Placed Success!");
      } catch (err) {
        toast.error(err.message);
      }
    } else {
      try {
        const res = await createBidDetails({
          variables: {
            createInput: PostObj,
          },
        });
        postCommittedVehicle(vehiclesArr, CapacityData, res.data.createBidDetails._id);
        setCnfModal(false);
        onClose();
        toast.success("Bid Placed Success!");
      } catch (err) {
        toast.error(err.message);
      }
    }
    socket.emit("EditConfirmVechileswithId", loadId);
    socket.emit("Updatebid", loadId);
  }

  const handleValidation = React.useCallback(() => {
    formik.validateForm();
    if (formik.isValid && formik.dirty) {
      setCnfModal(true);
    } else {
      formik.setTouched({
        ...formik.touched,
        No_Of_Vehicle: true,
        Confirm_Qty: true,
        Committed_Vehicles_0: true,
        Committed_Vehicles_1: selectedNumber >= 1 ? true : false,
        Committed_Vehicles_2: selectedNumber >= 2 ? true : false,
        Vehicle_Quantity_0: true,
        Vehicle_Quantity_1: selectedNumber >= 1 ? true : false,
        Vehicle_Quantity_2: selectedNumber >= 2 ? true : false,
      });
    }
  }, [formik.isValid, formik.setTouched, formik.validateForm, setCnfModal, selectedNumber]);

  const handleBlur = (event) => {
    formik.handleBlur(event);
  };

  const handleChange = React.useCallback(
    (event) => {
      formik.handleChange(event);
      formik.setFieldTouched(event.target.name, true);
    },
    [formik.handleChange, formik.setFieldTouched]
  );

  async function handleSubmit(event) {
    event.preventDefault();
    formik.handleSubmit(); // Call formik's handleSubmit to validate the form
  }

  useEffect(() => {
    setIsEditBidDetailsLoading(createBidDetailsLoading || editBidDetailsLoading || createCommittedVehiclesLoading);
  }, [createBidDetailsLoading, editBidDetailsLoading, createCommittedVehiclesLoading]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="noOfVehiclesModal">
          <div>
            <div className="firstDiv">
              <div className="eachModalContentDiv">
                <p className="modalText">No of Vehicle's* :</p>
                <div className="selectDiv">
                  <select
                    value={selectedNumber}
                    name="No_Of_Vehicle"
                    onChange={(event) => {
                      setSelectedNumber(parseInt(event.target.value));
                      handleChange(event);
                    }}
                  >
                    <option value="">Select</option>
                    {noOfVehiclesData?.map((el, i) => (
                      <option key={el} value={Number(el)}>
                        {el}
                      </option>
                    ))}
                  </select>
                  {formik.errors.No_Of_Vehicle && <p className="errmessage">{formik.errors.No_Of_Vehicle}</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="eachModalContentDiv">
            <p className="modalText">Confirm Quantity(MT) :</p>
            <div className="cnfQtyDiv">
              <p>
                {sum} MT / {Total_Weight} MT
              </p>
            </div>
          </div>
          <div className="eachModalContentDiv">
            <p className="modalText">Select Vehicle's* :</p>
            <div className="selectDivTwo">
              {formik.values.No_Of_Vehicle &&
                Array.from({ length: formik.values.No_Of_Vehicle }, (_, i) => {
                  const committedFieldName = `Committed_Vehicles_${i}`;
                  const quantityFieldName = `Vehicle_Quantity_${i}`;
                  const committedFieldError = formik.errors[committedFieldName];
                  const committedFieldTouched = formik.touched[committedFieldName];
                  const quantityFieldError = formik.errors[quantityFieldName];
                  const quantityFieldTouched = formik.touched[quantityFieldName];
                  return (
                    <div className="eachSelectBoxDiv customSelectBox" key={i}>
                      <div className="selectVehicleDiv">
                        <div className="selectDiv">
                          <Select
                            className="react-select vehicleSelect"
                            options={filteredData}
                            name={committedFieldName}
                            id={committedFieldName}
                            isSearchable={false}
                            // value={filteredData.find(
                            //   (option) =>
                            //     option.value === formik.values[committedFieldName]
                            // )}
                            onChange={(selectedOptions) => {
                              formik.setFieldValue(committedFieldName, selectedOptions.value);
                              handleSelectChange(selectedOptions, i);
                            }}
                            onBlur={() => {
                              formik.setFieldTouched(committedFieldName, true);
                            }}
                          />
                          {committedFieldError && committedFieldTouched && (
                            <p className="errmessage">{committedFieldError}</p>
                          )}
                        </div>
                        <div className="selectDiv">
                          <input
                            type="number"
                            name={quantityFieldName}
                            id={quantityFieldName}
                            placeholder="Enter Quantity"
                            className="permtInput no-spinners"
                            onChange={(e) => {
                              handlePerMTChange(e.target.value, i);
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={formik.values[quantityFieldName]}
                          />
                          {quantityFieldError && quantityFieldTouched && (
                            <p className="errmessage">{quantityFieldError}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="eachModalContentDiv">
            <p className="modalText"></p>
            {filteredData.length < 3 && (
              <div className="noVehicleDiv">
                <Button
                  className={"acceptBtn"}
                  text="+ Add Vehicle"
                  handleClick={() => setAddVehicleModal(true)}
                  type="button"
                />
                <Reactportal
                  open={addVehicleModal}
                  modalClassName="addUserModal"
                  contentClassName="addUserModal-content"
                  modalHeading={"Add Vehicle"}
                  onClose={() => setAddVehicleModal(false)}
                >
                  <AddVehicleModal onClose={() => setAddVehicleModal(false)} />
                </Reactportal>
              </div>
            )}
          </div>
          <div className="eachModalContentDiv">
            <p className="modalText">Bid Note :</p>
            <div className="selectDivThree">
              <textarea
                className="textArea"
                placeholder="Add Bid Note Here..."
                name="Bid_Note"
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
        </div>
        <PortalFooter>
          <Button className={"acceptBtn"} text="Submit" type="button" handleClick={handleValidation} />
          <Button className={"rejectBtn"} text="Cancel" type="button" handleClick={onClose} />
        </PortalFooter>

        <Reactportal
          open={cnfModal}
          modalHeading="Bid Confirmation"
          onClose={() => setCnfModal(false)}
          modalClassName="cnfModal"
          contentClassName="cnfModal-content"
        >
          <BidConfirmationModal
            RatePer={Rate_Per}
            data={formik.values}
            listArr={cnfVehicleData}
            newBidDetails={formik.handleSubmit}
            onClose={() => setCnfModal(false)}
          />
          {/* <CnfModal listArr={cnfVehicleData} newBidDetails={formik.handleSubmit} onClose={() => setCnfModal(false)} /> */}
        </Reactportal>
      </form>
    </div>
  );
}

export default VehicleCommitedModal;
