import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { NumberFormatter } from "../../../../CommonFunction/NumberFormatter/NumberFormatter";
import Button from "../../../Common/Button/Button";
import Reactportal from "../../Reactportal/Reactportal";
import VehicleCommitedModal from "../../../Common/AllModals/VehicleCommitedModal/VehicleCommitedModal";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import ProfileStatusModal from "../../../Common/AllModals/ProfileStatusModal/ProfileStatusModal";
import { GET_USER_ACCOUNT_BALANCE } from "../../../../gql/getUserAccountBalance";
import { get_coustomer } from "../../../../gql/getCoustomer";
import "./AcceptBids.scss";

function AcceptBids({
  ceilingprice,
  No_Of_Vehicels_Required,
  Total_Weight,
  loadId,
  Load_Post_Type,
  Rate_Per,
  BidDetails,
}) {
  const [openAcceptOfferModal, setAcceptOfferModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [accBalance, setAccBalance] = useState(0);
  const [customerData, setCustomerData] = useState({});
  const { data: userData } = useQuery(get_coustomer);
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");

  const navigate = useNavigate();
  let accDataId = Cookies.get("accDataId");
  const { data: accountData } = useQuery(GET_USER_ACCOUNT_BALANCE, {
    variables: {
      accountTypeId: accDataId,
    },
  });

  useEffect(() => {
    if (accountData && accountData.GetAccountBalanceWithAccountType_ID) {
      setAccBalance(accountData.GetAccountBalanceWithAccountType_ID[0].Account_Balance);
    }
  }, [accountData]);

  function closeModalWithGoToProfile() {
    setOpenProfileModal(false);
    navigate("/profile-settings");
  }

  useEffect(() => {
    if (userData) {
      setCustomerData(userData.AccountSearchFromCookies);
    }
  }, [userData]);

  return (
    <div className="acceptBidsDiv">
      <p className="targetPrice">Fixed Price : {NumberFormatter(ceilingprice)}/-</p>

      <div className="acceptBtnDiv">
        <Button
          className={BidDetails.length === 0 ? "acceptBtn" : "acceptBtndisabled"}
          text={BidDetails.length === 0 ? "Accept Offer" : "Offer Accepted"}
          disabled={BidDetails.length === 0 ? false : true}
          handleClick={() => {
            if (customerData.Is_Verified) {
              setAcceptOfferModal(true);
            } else {
              setOpenProfileModal(true);
            }
          }}
        />
      </div>

      <Reactportal
        open={openProfileModal}
        modalHeading={"Your Profile is Inactive"}
        modalClassName={"confirmationModal"}
        contentClassName={"confirmationModal-content"}
        onClose={() => setOpenProfileModal(false)}
      >
        <ProfileStatusModal
          ProfoleData={customerData}
          accInfo={accInfo}
          accBalance={accBalance}
          onClose={() => setOpenProfileModal(false)}
          closeModalWithGoToProfile={closeModalWithGoToProfile}
        />
      </Reactportal>

      <Reactportal
        open={openAcceptOfferModal}
        modalHeading="Vehicles Commited"
        onClose={() => setAcceptOfferModal(false)}
        modalClassName={"vehicleCommitedModal"}
        contentClassName={"vehicleCommitedModal-content"}
      >
        <VehicleCommitedModal
          No_Of_Vehicels_Required={No_Of_Vehicels_Required}
          Total_Weight={Total_Weight}
          loadId={loadId}
          bidPrice={ceilingprice}
          Load_Post_Type={Load_Post_Type}
          Rate_Per={Rate_Per}
          onClose={() => {
            setAcceptOfferModal(false);
          }}
        />
      </Reactportal>
    </div>
  );
}

export default AcceptBids;
