import ColumnFilter from "../../Components/Common/ReactTable/ReactTableComponents/ColumnFilter";

export const vehicleColumns = [
  {
    Header: "Vehicle Number",
    accessor: "Vehicle_No",
    Filter: ColumnFilter,
  },
  {
    Header: "Edit",
    accessor: "edit",
    Filter: "",
  },
];
