import ColumnFilter from "../../../../../../Components/Common/ReactTable/ReactTableComponents/ColumnFilter";

export const COLOUMNS = [
  {
    Header: "Name",
    accessor: "firstName" || "FullName",
    Filter: ColumnFilter,
  },
  {
    Header: "Email",
    accessor: "email",
    Filter: ColumnFilter,
  },
  {
    Header: "Phone",
    accessor: "mobileNumber",
    Filter: ColumnFilter,
  },
  {
    Header: "Is Active?",
    accessor: "Is_Active",
    Filter: "",
  },
  {
    Header: "Market Load Notification?",
    accessor: "marketLoadNotification",
    Filter: "",
  },
  {
    Header: "Edit",
    accessor: "edit",
    Filter: "",
  },
];
