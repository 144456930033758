import React, { useEffect, useState } from "react";
import { NumberFormatter } from "../../../../../../CommonFunction/NumberFormatter/NumberFormatter";
import "./AmountBox.scss";

function AmountBox({ paymentType, freightAmount }) {
  const [advance, setAdvance] = useState(0);

  function calculate() {
    if (paymentType === "To Pay") {
      setAdvance(0);
    } else if (paymentType === "Advance 100%") {
      setAdvance(freightAmount);
    } else {
      setAdvance((90 / 100) * freightAmount);
    }
  }

  useEffect(() => {
    calculate();
  }, [paymentType, freightAmount]);

  return (
    <div className="amountBoxEachBox">
      <div className="heading">
        <p className="headingText">Amount</p>
      </div>
      <div className="belowbox">
        <div className="eachContentDiv firstDiv">
          <p className="headingText freightAmount">Freight :</p>
          <p className="contentText freightAmount">
            {NumberFormatter(freightAmount)}/-
          </p>
        </div>
        {/* <div className="eachContentDiv firstDiv">
          <p className="headingText">2% Platform Charges :</p>
          <p className="contentText">
            {NumberFormatter(Math.round((2 / 100) * freightAmount))}/-
          </p>
        </div> */}
        <div className="eachContentDiv">
          <p className="headingText">Advance :</p>
          <p className="contentText">
            {NumberFormatter(Math.round(advance))}/-{" "}
          </p>
        </div>
        <div className="eachContentDiv">
          <p className="headingText">Balance :</p>
          <p className="contentText">
            {NumberFormatter(Math.round(freightAmount - advance))}/-
          </p>
        </div>
      </div>
    </div>
  );
}

export default AmountBox;
