import React, { useEffect, useState } from "react";
import Button from "../../../../../../Components/Common/Button/Button";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import Reactportal from "../../../../../../Components/LpComponents/Reactportal/Reactportal";
import ProcessAdvanceModal from "../../../../../../Components/Common/AllModals/CurrentTaskModal/ProcessAdvanceModal/ProcessAdvanceModal";
import CurrentTaskTable from "../../../../../../Components/Common/CurrentTaskTable/CurrentTaskTable";
import ViewClaimModal from "../../../../../../Components/Common/AllModals/CurrentTaskModal/ViewClaimModal/ViewClaimModal";
import CnfModal from "../../../../../../Components/Common/AllModals/CnfModal/CnfModal";
import RaiseClaimModal from "../../../../../../Components/Common/AllModals/RaiseClaimModal/RaiseClaimModal";
import socket from "../../../../../../CommonFunction/Socket/socketClient";
import { AUTO_TRANSACTIONS } from "../../../../../../gql/autoTransaction";
import { EDIT_COMMITTED_VEHICLES_DATA } from "../../../../../../gql/editCommitedVehicle";
import { EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE } from "../../../../../../gql/editCommitedVehiclesWithoutVerifyBalance";
import "./CurrentTask.scss";

function CurrentTask({
  SpId,
  loadId,
  CommittedVehicleId,
  CommittedVehicleUniqueId,
  VehicleNo,
  Current_Stage,
  Sp_Claim_Raised,
  Lp_Claim_Raised,
  Sp_Claim_Cleared,
  Lp_Claim_Cleared,
  paymentType,
  freightAmount,
}) {
  const [editCommittedVehiclesData, { loading: editCommittedVehiclesDataLoading }] =
    useMutation(EDIT_COMMITTED_VEHICLES_DATA);

  const [
    editCommittedVehiclesDataWithoutVerifyBalance,
    { loading: editCommittedVehiclesDataWithoutVerifyBalanceLoading },
  ] = useMutation(EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE);

  const [noClaimCnfModal, setNoClaimCnfModal] = useState(false);
  const [lpRaiseClaimModal, setLpRaiseClaimModal] = useState(false);
  const [processAdvanceModal, setProcessAdvanceModal] = useState(false);
  const [viewClaimModal, setViewClaimModal] = useState(false);
  const [autoTransactions, { loading: autoTransactionsLoading }] = useMutation(AUTO_TRANSACTIONS);
  const [advance, setAdvance] = useState(0);

  function calculate() {
    if (paymentType === "To Pay") {
      setAdvance(0);
    } else if (paymentType === "Advance 100%") {
      setAdvance(freightAmount);
    } else {
      setAdvance((90 / 100) * freightAmount);
    }
  }

  useEffect(() => {
    calculate();
  }, [paymentType, freightAmount]);

  function handleCommittedVehiclesChange({ updateObj, msg }) {
    if (msg === "Advance Processed") {
      autoTransactions({
        variables: {
          chargeInput: {
            Event_Code: "65155423e6491068727881ed",
            BookingID: CommittedVehicleId,
          },
        },
      })
        .then(() => {
          editCommittedVehiclesData({
            variables: {
              editCommittedVehiclesId: CommittedVehicleId,
              userInput: updateObj,
            },
          })
            .then(() => {
              toast.success(msg);
              socket.emit("Update_CurrentTask", SpId, CommittedVehicleId);
            })
            .catch((err) => toast.error(err.message));
        })
        .catch((err) => toast.error(err.message));
    } else {
      editCommittedVehiclesData({
        variables: {
          editCommittedVehiclesId: CommittedVehicleId,
          userInput: updateObj,
        },
      })
        .then((res) => {
          toast.success(msg);

          socket.emit("Update_CurrentTask", SpId, CommittedVehicleId);
        })
        .catch((err) => toast.error(err.message));
    }
  }

  async function handleCommittedVehiclesChangeWithoutVerifyBalance({ updateObj, msg }) {
    await editCommittedVehiclesDataWithoutVerifyBalance({
      variables: {
        editCommittedVehiclesWithoutVerifyUserWithBalanceId: CommittedVehicleId,
        userInput: updateObj,
      },
    })
      .then((res) => {
        socket.emit("Update_CurrentTask", SpId, CommittedVehicleId);
        toast.success(msg);
      })
      .catch((err) => toast.error(err.message));
  }

  return (
    <div className="currentTaskDiv">
      <p className="currentTaskHeader">Current Task</p>

      {Current_Stage === "Booking Confirmed" ? (
        <>
          <p className="taskMessage">Awaiting for the vehicle to reach at loading point</p>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "Vehicle Reached At Loading Point" ? (
        <>
          <p className="taskMessage">Awaiting for the vehicle to be loaded</p>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "Loading Completed" ? (
        <>
          <p className="taskMessage">Please process the advance</p>
          <Button text="Process Advance" className={"acceptBtn"} handleClick={() => setProcessAdvanceModal(true)} />
          <Reactportal
            open={processAdvanceModal}
            modalHeading="Confirmation"
            onClose={() => setProcessAdvanceModal(false)}
            modalClassName={"cnfModal"}
            contentClassName={"cnfModal-content"}
          >
            <ProcessAdvanceModal
              editCommittedVehiclesData={() => {
                handleCommittedVehiclesChange({
                  updateObj: {
                    Advance_Processed: true,
                    Current_Stage: "Advance Processed",
                    Advance_Processed_Time: new Date(),
                  },
                  msg: "Advance Processed",
                });
              }}
              onClose={() => setProcessAdvanceModal(false)}
              advance={advance}
              paymentType={paymentType}
              loading={editCommittedVehiclesDataLoading || autoTransactionsLoading}
            />
          </Reactportal>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "Advance Processed" ? (
        <>
          <p className="taskMessage">Awaiting for the vehicle to reach at unloading point</p>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "Vehicle Reach At Unloading Point" ? (
        <>
          <p className="taskMessage">Awaiting for the vehicle to be unloaded</p>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "Unloading Completed" ? (
        <>
          <div className="currentTaskTableDiv">
            <CurrentTaskTable CommittedVehicleId={CommittedVehicleId} />
          </div>

          <div className="actionBtnDiv">
            {Sp_Claim_Raised && !Sp_Claim_Cleared ? (
              <div className="spClaimViewBtn">
                <Button text="View Claim" className={"acceptBtn"} handleClick={() => setViewClaimModal(true)} />
                <Reactportal
                  open={viewClaimModal}
                  modalClassName="cnfModal"
                  contentClassName="cnfModal-content"
                  modalHeading="Claim Details"
                  onClose={() => setViewClaimModal(false)}
                >
                  <ViewClaimModal
                    SpId={SpId}
                    loadId={loadId}
                    CommittedVehicleId={CommittedVehicleId}
                    CommittedVehicleUniqueId={CommittedVehicleUniqueId}
                    VehicleNo={VehicleNo}
                    LpClaim={true}
                    onClose={() => setViewClaimModal(false)}
                    Sp_Claim_Cleared={Sp_Claim_Cleared}
                    Lp_Claim_Cleared={Lp_Claim_Cleared}
                  />
                </Reactportal>
              </div>
            ) : (
              ""
            )}

            {!Lp_Claim_Raised && !Lp_Claim_Cleared ? (
              <div className="lpClaimBtnDiv">
                <Button text="Raise A Claim" className={"acceptBtn"} handleClick={() => setLpRaiseClaimModal(true)} />

                <Reactportal
                  open={lpRaiseClaimModal}
                  modalClassName="cnfModal"
                  contentClassName="cnfModal-content"
                  modalHeading="Raise Claim"
                  onClose={() => setLpRaiseClaimModal(false)}
                >
                  <RaiseClaimModal
                    SpId={SpId}
                    loadId={loadId}
                    LpRaise={true}
                    CommittedVehicleId={CommittedVehicleId}
                    onClose={() => setLpRaiseClaimModal(false)}
                  />
                </Reactportal>

                <Button text="No Claim" className={"rejectBtn"} handleClick={() => setNoClaimCnfModal(true)} />
                <Reactportal
                  open={noClaimCnfModal}
                  modalHeading={"Confirmation"}
                  onClose={() => setNoClaimCnfModal(false)}
                  modalClassName={"cnfModal"}
                  contentClassName={"cnfModal-content"}
                >
                  <CnfModal
                    cnfMessage={
                      "Hereafter you cannot raise a claim, Are you sure that you don't want to raise a claim ?"
                    }
                    editCommittedVehiclesData={() => {
                      let obj;

                      if (Sp_Claim_Cleared === true) {
                        obj = {
                          Lp_Claim_Cleared: true,
                          // Lp_Claim_Raised: false,
                          Clear_Delevery_Confirmation: true,
                          Current_Stage: "Clear Delevery Confirmation",
                          Clear_Delevery_Confirmation_Time: new Date(),
                        };
                      } else {
                        obj = {
                          Lp_Claim_Cleared: true,
                          // Lp_Claim_Raised: false,
                        };
                      }
                      handleCommittedVehiclesChangeWithoutVerifyBalance({
                        updateObj: obj,
                        msg: "No Claim Raised",
                      });
                      setNoClaimCnfModal(false);
                    }}
                    onClose={() => setNoClaimCnfModal(false)}
                    loading={editCommittedVehiclesDataWithoutVerifyBalanceLoading}
                  />
                </Reactportal>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "Clear Delevery Confirmation" ? (
        <>
          <p className="taskMessage">Awaiting for the physical POD (Proof of Delivery) collected form SP.</p>
        </>
      ) : (
        ""
      )}
      {Current_Stage === "POD Collected From SP" ? (
        <>
          <p className="taskMessage">Awaiting for the physical POD (Proof of Delivery) submitted to LP.</p>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default CurrentTask;
