import { gql } from "@apollo/client";

export const GET_BID_DETAILS = gql`
  query GetBidDetailsWithCommittedVehicles($getBidDetailsWithCommittedVehiclesId: ID!) {
    GetBidDetailsWithCommittedVehicles(id: $getBidDetailsWithCommittedVehiclesId) {
      Committed_Vehicles {
        _id
        Advance_Processed
        BidId
        Booking_Confirmed
        Clear_Delevery_Confirmation
        Driver_Name
        Driver_Number
        Loading_Completed
        Reporting_Time
        Unique_Id
        Unloading_Completed
        UserId
        Vechicle_Reached_At_Loading_Point
        Vehicle_Number {
          _id
          Vehicle_No
        }
        Vehicle_Reach_At_Unloading_Point
        Is_Accepted
        Is_Rejected
        Is_Confirmed
      }
    }
  }
`;
