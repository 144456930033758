import * as Yup from "yup";

export const replaceUnderscore = (name) => name.replaceAll("_", " ");

export const formikinitialvalues = (data) => {
  let keyvaluepairs = {};
  for (let a = 0; a < data.length; a++) {
    if (data.name === "Sidebyside") {
      for (let b = 0; b < data.Sidebyside.length; b++) {
        keyvaluepairs = {
          ...keyvaluepairs,
          [data[a].Sidebyside[b].name]: data[a].Sidebyside[b].value,
        };
      }
    }
    keyvaluepairs = {
      ...keyvaluepairs,

      [data[a].name]: "",
    };
  }
  return keyvaluepairs;
};

export const formikinitialvaluesforuser = (data) => {
  let keyvaluepairs = {};
  for (let a = 0; a < data.length; a++) {
    keyvaluepairs = {
      ...keyvaluepairs,

      [data[a].name]: "",
    };
  }
  return keyvaluepairs;
};

export const formikValidation = (data) => {
  let formSchema = {};

  for (let a = 0; a < data.length; a++) {
    if (data[a] && data[a].require === false) {
      formSchema = {
        ...formSchema,
        [data[a].name]: Yup.string().nullable().default(""),
      };
    } else if (data[a] && data[a].type === "email") {
      formSchema = {
        ...formSchema,
        [data[a].name]: Yup.string()
      };
    } else if (data[a] && data[a].type === "number") {
      if (data[a].validate === true) {
        return;
      }
      if (data[a].formtype === "userProfile") {
        formSchema = {
          ...formSchema,
          [data[a].name]: Yup.number()
            .test("len", `Must be exactly ${data[a].min} digit`, (val) => {
              if (val === undefined || val === null) {
                return false;
              }
              return val.toString().length === data[a].min;
            })
            .required(`Required`),
        };
      } else {
        formSchema = {
          ...formSchema,
          [data[a].name]: Yup.number().required(`Required`),
        };
      }
    } else if (data[a] && data[a].name === "Weight_Category") {
      formSchema = {
        ...formSchema,
        [data[a].name]: Yup.array().required(`Required`),
      };
    } else {
      formSchema = {
        ...formSchema,
        [data[a].name]: Yup.string()
          .required(`Required`)
          .min(data[a].min ? data[a].min : 0)
          .max(data[a].max ? data[a].max : Infinity),
      };
    }
  }
  // console.log(Yup.object(formSchema));
  return Yup.object(formSchema);
};

export const formikValidationAddLoad = (data) => {
  let formSchema = {};

  for (let a = 0; a < data.length; a++) {
    if (data[a].require === false) {
      formSchema = {
        ...formSchema,
        [data[a].name]: Yup.string().nullable().default(""),
      };
    } else if (data[a].type === "email") {
      formSchema = {
        ...formSchema,
        [data[a].name]: Yup.string()
          .email("Please enter a valid email")
          .required("Required"),
      };
    } else if (data[a].type === "number") {
      if (data[a].formtype === "userProfile") {
        formSchema = {
          ...formSchema,
          [data[a].name]: Yup.number()
            .test(
              "len",
              `Must be exactly ${data[a].min} digit`,
              (val) => val && val.toString().length === data[a].min
            )
            .required("Required"),
        };
      } else {
        formSchema = {
          ...formSchema,
          [data[a].name]: Yup.number().required("Required"),
        };
      }
    } else if (data[a].name === "Weight_Category") {
      formSchema = {
        ...formSchema,
        [data[a].name]: Yup.array().required("Required"),
      };
    } else {
      formSchema = {
        ...formSchema,
        [data[a].name]: Yup.string()
          .required("Required")
          .min(data[a].min || 0)
          .max(data[a].max || Infinity),
      };
    }
  }

  return Yup.object(formSchema);
};

export const handleKeyDown = (evt, type) => {
  // console.log(evt.key);
  if (
    type === "number" &&
    evt.key !== "Backspace" &&
    evt.key !== "ArrowLeft" &&
    evt.key !== "ArrowRight" &&
    evt.key !== "Delete" &&
    isNaN(evt.key) &&
    evt.key !== "Tab"
  ) {
    evt.preventDefault();
  }
};
