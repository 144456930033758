import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { formatDateDDMMYYYY } from "../../../CommonFunction/formatDate";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.scss";

function CustomDatePicker({
  label,
  name,
  handleformikdata,
  handleCustomDatePickerChange,
  value,
  err,
  handleBlur,
  touched,
  require,
  reset,
  mindate,
  autoComplete,
}) {
  const [selectedDate, setSelectedDate] = useState(null);
  const today = new Date();

  const formikdatamanage = (date) => {
    const formatDate = date.toISOString();

    handleCustomDatePickerChange({
      target: {
        name: name,
        value: formatDate,
      },
    });

    handleformikdata(formatDate, name);
  };

  const handleChange = (date) => {
    setSelectedDate(date);
    formikdatamanage(date);
  };
  useEffect(() => {
    if (reset) setSelectedDate(null);
  }, [reset]);

  return (
    <div>
      <div className="dpLabel">
        {label && label}
        {require && " *"}
      </div>
      <div className={selectedDate ? "date-picker-container" : "date-picker-container1"}>
        <ReactDatePicker
          name={name}
          className={touched && err && "red-border"}
          value={value && formatDateDDMMYYYY(value)}
          selected={selectedDate}
          onChange={handleChange}
          closeOnScroll={true}
          dateFormat="dd/MM/yyyy"
          onBlur={(e) => {
            handleBlur(e);
          }}
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={10}
          minDate={mindate ? today : null}
          autoComplete={autoComplete}
        />
      </div>
      {/* <p className="errmessage">{err}</p> */}
    </div>
  );
}

export default CustomDatePicker;
