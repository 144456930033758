import React, { useEffect, useState } from "react";
import Reactportal from "../../../SpComponents/Reactportal/Reactportal";
import Button from "../../../Common/Button/Button";
import InputBox from "../../../Common/InputBox/InputBox";
import VehicleCommitedModal from "../../../Common/AllModals/VehicleCommitedModal/VehicleCommitedModal";
import { useQuery } from "@apollo/client";
import GetRank from "../../../Common/GetRank/GetRank";
import { useDispatch, useSelector } from "react-redux";
import { NumberFormatter } from "../../../../CommonFunction/NumberFormatter/NumberFormatter";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ProfileStatusModal from "../../../Common/AllModals/ProfileStatusModal/ProfileStatusModal";
import { BID_DETAILS_BY_LOAD_ID_QUERY } from "../../../../gql/getBidDetails";
import { GET_USER_ACCOUNT_BALANCE } from "../../../../gql/getUserAccountBalance";
import { get_coustomer } from "../../../../gql/getCoustomer";
import "./Quote.scss";

const Quote = ({
  ceilingprice,
  Total_Weight,
  Rate_Per,
  loadId,
  No_Of_Vehicels_Required,
  Is_Auction_Started,
  Is_Live,
}) => {
  const [isBidModalOpen, setIsBidModalOpen] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [accBalance, setAccBalance] = useState(0);
  const [customerData, setCustomerData] = useState({});
  const [bidPrice, setBidPrice] = useState(0);
  const [bidHostory, setBidHistory] = useState([]);
  const [bidPlacedPrice, setBidPlacedPrice] = useState(0);
  const [comparePrice, setComparePrice] = useState(0);
  const [bidDiff, setBidDiff] = useState(false);
  const isLoadData = useSelector((store) => store.loadData.loadData);
  const dispatch = useDispatch();
  const { data: userData } = useQuery(get_coustomer);
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");
  let accBal = useSelector((store) => store.accBalance.accBalance);

  const navigate = useNavigate();
  let accDataId = Cookies.get("accDataId");
  const { data: accountData } = useQuery(GET_USER_ACCOUNT_BALANCE, {
    variables: {
      accountTypeId: accDataId,
    },
  });

  useEffect(() => {
    if (accountData && accountData.GetAccountBalanceWithAccountType_ID) {
      setAccBalance(accountData.GetAccountBalanceWithAccountType_ID[0].Account_Balance);
    }
  }, [accountData]);

  function closeModalWithGoToProfile() {
    setOpenProfileModal(false);
    navigate("/profile-settings");
  }

  useEffect(() => {
    if (userData) {
      setCustomerData(userData.AccountSearchFromCookies);
    }
  }, [userData]);

  const { data, refetch } = useQuery(BID_DETAILS_BY_LOAD_ID_QUERY, {
    variables: {
      createInput: {
        id: loadId,
        sort: -1,
        sortBy: "createdAt",
        limit: 1,
      },
    },
  });

  function handleBidChange() {
    if (customerData.Is_Verified) {
      setIsBidModalOpen(true);
    } else {
      setOpenProfileModal(true);
    }
  }

  useEffect(() => {
    if (data && data.BidDetailsByLoadIdForUser[0]) {
      setBidPlacedPrice(data.BidDetailsByLoadIdForUser[0].Price);
      setBidHistory(data.BidDetailsByLoadIdForUser[0].Bid_History);
    }
  }, [data]);

  useEffect(() => {
    if (bidPlacedPrice) {
      if (Rate_Per === "Per MT") {
        setComparePrice(bidPlacedPrice);
        if (bidPrice && bidPrice % 10 === 0) {
          setBidDiff(true);
        } else {
          setBidDiff(false);
        }
      } else {
        setComparePrice(bidPlacedPrice);
        if (bidPrice && bidPrice % 100 === 0) {
          setBidDiff(true);
        } else {
          setBidDiff(false);
        }
      }
    } else {
      setComparePrice(ceilingprice + 0.25 * ceilingprice + 1);
      if (Rate_Per === "Per MT") {
        if (bidPrice && bidPrice % 10 === 0) {
          setBidDiff(true);
        } else {
          setBidDiff(false);
        }
      } else {
        if (bidPrice && bidPrice % 100 === 0) {
          setBidDiff(true);
        } else {
          setBidDiff(false);
        }
      }
    }
  }, [ceilingprice, bidPlacedPrice, bidPrice]);

  useEffect(() => {
    if (isLoadData) {
      refetch();
      dispatch({ type: "set_loadData", payload: false });
    }
  }, [isLoadData, refetch]);

  console.log(bidPrice, Number(bidPrice), "bidprice");
  return (
    <div className="SpQuote">
      <div className="quotePriceBox">
        <div className="quoteprice boxcenter">
          <span className="l1">₹</span>
          <InputBox
            type={"number"}
            className="quoteInputBox no-spinners"
            name="Bid_Price"
            handleInputChange={(e) => setBidPrice(e.target.value)}
            placeholder={bidPlacedPrice ? bidPlacedPrice : ""}
            min={0}
            max={1000000}
            outOfFocus={isLoadData}
          />
          {Is_Auction_Started || !Is_Live ? (
            <GetRank
              id={data && data.BidDetailsByLoadIdForUser[0] ? data.BidDetailsByLoadIdForUser[0]._id : ""}
              loadId={loadId}
            />
          ) : (
            ""
          )}
        </div>
        <p className="ceilingprice">
          Ceiling Price : {NumberFormatter(ceilingprice + 0.25 * ceilingprice)}
          /-
        </p>
        <p className="ceilingprice">Bid Differnce : {Rate_Per === "Per MT" ? "₹10/-" : "₹100/-"}</p>
        {bidHostory.length >= 40 && <p className="ceilingprice">Bid Limit Reached</p>}
      </div>
      <div className="bidBtnBox boxcenter">
        <Button
          text={`Bid per ${Rate_Per === "Per MT" ? "MT" : "Vehicle"}`}
          className={
            bidPrice &&
            Number(bidPrice) > 0 &&
            bidDiff &&
            bidPrice &&
            bidPrice < (comparePrice ?? 0) &&
            bidHostory.length <= 40
              ? "bidBtnActive"
              : "btnDisabled"
          }
          disabled={
            bidPrice &&
            Number(bidPrice) > 0 &&
            bidDiff &&
            bidPrice &&
            bidPrice < (comparePrice ?? 0) &&
            bidHostory.length <= 40
              ? false
              : true
          }
          handleClick={handleBidChange}
        />

        <Reactportal
          open={openProfileModal}
          modalHeading={"Your Profile is Inactive"}
          modalClassName={"confirmationModal"}
          contentClassName={"confirmationModal-content"}
          onClose={() => setOpenProfileModal(false)}
        >
          <ProfileStatusModal
            ProfoleData={customerData}
            accInfo={accInfo}
            accBalance={accBalance}
            onClose={() => setOpenProfileModal(false)}
            closeModalWithGoToProfile={closeModalWithGoToProfile}
          />
        </Reactportal>

        <Reactportal
          open={isBidModalOpen}
          modalHeading="Vehicles Commited"
          onClose={() => setIsBidModalOpen(false)}
          modalClassName={"vehicleCommitedModal"}
          contentClassName={"vehicleCommitedModal-content"}
        >
          <VehicleCommitedModal
            No_Of_Vehicels_Required={No_Of_Vehicels_Required}
            Total_Weight={Total_Weight}
            loadId={loadId}
            bidPrice={bidPrice}
            bidId={data && data.BidDetailsByLoadIdForUser[0] ? data.BidDetailsByLoadIdForUser[0]._id : ""}
            price={data && data.BidDetailsByLoadIdForUser[0] ? data.BidDetailsByLoadIdForUser[0].Price : ""}
            Rate_Per={Rate_Per}
            onClose={() => {
              setIsBidModalOpen(false);
              refetch();
            }}
          />
        </Reactportal>
      </div>
    </div>
  );
};

export default Quote;
