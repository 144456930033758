import React from "react";
import "./FormattedTime.scss";

function FormattedTime({ time }) {
  if (time === null) {
    return <></>;
  }
  const timeArr = time.split(":");
  const hours = timeArr[0];
  const minutes = timeArr[1];

  const ampm = hours >= 12 ? "PM" : "AM";
  const displayHours = hours % 12 || 12; // convert to 12-hour format

  const formattedTime = displayHours + ":" + minutes + " " + ampm;
  return <p className="formatted_Time">{formattedTime}</p>;
}

export default FormattedTime;
