import { useQuery } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./BookingDetailsModal.scss";
import TruckLoadingIndicator from "../../TruckLoadingIndicator/TruckLoadingIndicator";
import ShowNoDataFound from "../../ShowNoDataFound/ShowNoDataFound";
import { NumberFormatter } from "../../../../CommonFunction/NumberFormatter/NumberFormatter";
import Reactportal from "../../../LpComponents/Reactportal/Reactportal";
import LoadDetails from "../LoadDetails/LoadDetails";
import { GET_BOOKING_SCHEMA } from "../../../../gql/getBookingDetailsByUniqueID";

function BookingDetailsModal({ bookingId }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [freightAmount, setfreightAmount] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [loadDetailsModal, setLoadDetailsModal] = useState(false);

  const { loading: bookingLoading, data: bookingData } = useQuery(GET_BOOKING_SCHEMA, {
    variables: { uniqueId: bookingId },
  });

  useEffect(() => {
    if (bookingData) {
      setData(bookingData && bookingData.getallBookingsByUnique_ID[0]);
      setLoading(bookingLoading);
    }
  }, [bookingData]);

  // console.log(data.LoadId.Rate_per,bookingId,"----------")

  useEffect(() => {
    if (data && data.LoadId) {
      setfreightAmount(
        data.LoadId.Rate_per === "Per Vehicle" ? data.BidId.Price : data.BidId.Price * data.Vehicle_Capacity
      );
    }
  }, [data]);

  function calculate() {
    if (data.LoadId.Payment_Type === "To Pay") {
      setAdvance(0);
    } else if (data.LoadId.paymentType === "Advance 100%") {
      setAdvance(freightAmount);
    } else {
      setAdvance((90 / 100) * freightAmount);
    }
  }

  useEffect(() => {
    if (data && data.LoadId) {
      calculate();
    }
  }, [data, freightAmount]);

  if (data === undefined) {
    return <ShowNoDataFound msg={"No Data Found"} />;
  }
  return (
    <>
      {loading ? (
        <TruckLoadingIndicator />
      ) : (
        <>
          <div className="bookingDeatailsModal">
            <div className="bookingflex">
              <div className="bookingDetailsFlex">
                <p className="bookingmodalHeading">Bid Details</p>
                <div className="eachBookingDetailsDiv boxcenter">
                  <p className="bookingcontentText boxcenter">From-To :</p>
                  <p className="bookingDetailsData">
                    {data.LoadId.From_City} - {data.LoadId.To_City}
                  </p>
                </div>
                <div className="eachBookingDetailsDiv boxcenter">
                  <p className="bookingcontentText boxcenter">Vehicle Number :</p>
                  <p className="bookingDetailsData">{data.Vehicle_Number.Vehicle_No}</p>
                </div>
                <div className="eachBookingDetailsDiv boxcenter">
                  <p className="bookingcontentText boxcenter">Driver Name :</p>
                  <p className="bookingDetailsData">{data.Driver_Name}</p>
                </div>
                <div className="eachBookingDetailsDiv boxcenter">
                  <p className="bookingcontentText boxcenter">Driver Number :</p>
                  <p className="bookingDetailsData">{data.Driver_Number}</p>
                </div>
                <div className="eachBookingDetailsDiv boxcenter">
                  <p className="bookingcontentText boxcenter">Payment Type :</p>
                  <p className="bookingDetailsData">{data.LoadId.Payment_Type}</p>
                </div>
                <div className="eachBookingDetailsDiv boxcenter">
                  <p className="bookingcontentText boxcenter">Load Id :</p>
                  <p className="bookingDetailsData loadIdLink">
                    <span className="loadIdNumber" onClick={() => setLoadDetailsModal(!loadDetailsModal)}>
                      {data.LoadId.Unique_Id}{" "}
                    </span>
                  </p>
                  <Reactportal
                    open={loadDetailsModal}
                    modalClassName="loadDetailModal"
                    contentClassName="loadDetailModal-content"
                    modalHeading={`Load Id : ${data.LoadId.Unique_Id}`}
                    onClose={() => {
                      setLoadDetailsModal(false);
                    }}
                  >
                    <LoadDetails loadId={data.LoadId.Unique_Id} key={data.LoadId.Unique_Id} ACmodal={true} />
                  </Reactportal>
                </div>
              </div>
              <div className="amountDetailsFlex">
                <p className="bookingmodalHeading">Amount</p>
                <div className="eachBookingDetailsDiv boxcenter">
                  <p className="bookingcontentText boxcenter">Freight :</p>
                  <p className="bookingDetailsData">{NumberFormatter(freightAmount)}/-</p>
                </div>
                <div className="eachBookingDetailsDiv boxcenter">
                  <p className="bookingcontentText boxcenter">Advance :</p>
                  <p className="bookingDetailsData">{NumberFormatter(Math.round(advance))}/-</p>
                </div>
                <div className="eachBookingDetailsDiv boxcenter">
                  <p className="bookingcontentText boxcenter">Balance :</p>
                  <p className="bookingDetailsData">{NumberFormatter(Math.round(freightAmount - advance))}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BookingDetailsModal;
