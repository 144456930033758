import React from 'react'
import "./TD.scss"

function TD({children, className}) {
  return (
   <td className={className}>
    {children}
   </td>
  )
}

export default TD
