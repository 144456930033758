import React, { useState, useEffect } from "react";
import { CgProfile } from "react-icons/cg";
import Notification from "../../../LpComponents/Notification/Notification";
import Reactportal from "../../../LpComponents/Reactportal/Reactportal";
import Profile from "../../Profile/Profile";
import OutsideClick from "../../OutSideClick/OutsideClick";
import Greeting from "../../Greeting/Greeting";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { BsFillBellFill } from "react-icons/bs";
import socket from "../../../../CommonFunction/Socket/socketClient";
import Cookies from "js-cookie";
import ProfileStatusModal from "../../AllModals/ProfileStatusModal/ProfileStatusModal";
import "./Infosection.scss";
import { GET_USER_ACCOUNT_BALANCE } from "../../../../gql/getUserAccountBalance";
import { get_coustomer } from "../../../../gql/getCoustomer";

const Infosection = () => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [alldata, setalldata] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [accBalance, setAccBalance] = useState(0);
  let accDataId = Cookies.get("accDataId");
  const { data, error } = useQuery(get_coustomer);
  const { data: accountData } = useQuery(GET_USER_ACCOUNT_BALANCE, {
    variables: {
      accountTypeId: accDataId,
    },
  });

  const navigate = useNavigate();

  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");
  const userID = accInfo.id;

  useEffect(() => {
    socket.emit("storeClientInfo", userID);
    return () => {
      // socket.disconnect();
    };
  });

  function closeModalWithGoToProfile() {
    setIsOpen(false);
    navigate("/profile-settings");
  }

  useEffect(() => {
    if (data) {
      setalldata(data.AccountSearchFromCookies);
      if (
        data.AccountSearchFromCookies &&
        data.AccountSearchFromCookies.Is_Verified &&
        data.AccountSearchFromCookies.Is_Verified === false
      ) {
        setIsOpen(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      if (error.message === "Invalid or expired token") navigate("/login");
    }
  }, [error]);

  useEffect(() => {
    if (accountData && accountData.GetAccountBalanceWithAccountType_ID) {
      setAccBalance(accountData.GetAccountBalanceWithAccountType_ID[0]?.Account_Balance);
    }
  }, [accountData]);

  return (
    <div>
      <div className="headericonsection boxcenter">
        <div className="notificationIconBox boxcenter">
          <BsFillBellFill className="bellicon" onClick={() => setNotificationOpen(!notificationOpen)} />
          {notificationOpen && (
            <Reactportal
              open={notificationOpen}
              modalClassName="notificationModal"
              contentClassName={"notification-content"}
              onClose={() => setNotificationOpen(false)}
              modalHeading="All Notifications"
            >
              <Notification />
            </Reactportal>
          )}
        </div>
        <div className="profileIconBox boxcenter">
          <CgProfile className="profileicon" onClick={() => setProfileOpen(!profileOpen)} />
          {profileOpen && (
            <OutsideClick setToggled={setProfileOpen}>
              <Profile setProfileOpen={setProfileOpen} />
            </OutsideClick>
          )}
          <div className="greetingDiv">
            <Greeting />
            {accInfo.Type_Of_User === "Root_User" && (
              <>
                {alldata.Is_Verified ? (
                  <div className="boxcenter notActiveDiv">Active</div>
                ) : (
                  <>
                    <div
                      className="boxcenter activeDiv"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      Inactive
                    </div>
                    <Reactportal
                      open={isOpen}
                      modalHeading={"Your Profile is Inactive"}
                      modalClassName={"confirmationModal"}
                      contentClassName={"confirmationModal-content"}
                      onClose={() => setIsOpen(false)}
                    >
                      <ProfileStatusModal
                        ProfoleData={alldata}
                        accInfo={accInfo}
                        accBalance={accBalance}
                        onClose={() => setIsOpen(false)}
                        closeModalWithGoToProfile={closeModalWithGoToProfile}
                      />
                    </Reactportal>
                  </>
                )}
              </>
            )}
            {accInfo.Type_Of_User === "Sub_User" && (
              <>
                {accInfo.Is_Active ? (
                  <div className="boxcenter notActiveDiv">Active</div>
                ) : (
                  <div className="boxcenter activeDiv">Inactive</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Infosection;
